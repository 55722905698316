// ChartComponent.js
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import './Example.css'

const ChartComponent = () => {
  // Example data
  const series = [{
    name: 'Revenue',
    data: [30, 40, 35, 50, 49, 60, 70, 91, 125]
  }, 
  {
    name: 'Expenses',
    data: [10, 10, 15, 10, 19, 20, 20, 11, 25]
  }];


  const categories = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep']


  const options = {
    chart: {
      background: '#fff',
      foreColor: '#666',
      toolbar: {
        show: false
      },
      dropShadow: {
        enabled: false,
        top: 1,
        left: 1,
        blur: 3,
        opacity: 0.2
      },
      sparkline: {
        enabled: false
      },
      zoom: {
        enabled: false // This disables the zoom feature
      },
      selection: {
        enabled: false // This disables the selection feature
      }
    },
    colors: ['#00a76f', '#ffab00'],
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth',
      width: 3, // Adjust the line width if needed
    },
    xaxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
      labels: {
        style: {
          colors: '#999',
          fontSize: '12px',
          fontFamily: 'Helvetica, Arial, sans-serif'
        }
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: '#999',
          fontSize: '12px',
          fontFamily: 'Helvetica, Arial, sans-serif'
        }
      },
      tickAmount: 5,
    },
    grid: {
      borderColor: '#f1f1f1',
      strokeDashArray: 3,
      row: {
        colors: ['transparent', 'transparent'],
        opacity: 0.5
      },
      column: {
        colors: ['transparent', 'transparent'],
        opacity: 0.5
      }
    },
    tooltip: {
        theme: 'light',
        style: {
          fontSize: '14px',
          fontFamily: 'Helvetica, Arial, sans-serif',
        },
        color: '#666',
        onDatasetHover: {
          highlightDataSeries: true
        },
        x: {
          show: true,
          format: 'dd MMM',
          formatter: function (val) {
            return `${categories[val - 1]}`;
          },
          style: {
            color: '#999' // This sets the color for the x-axis tooltip text
          },
          textAlignment: 'center'
        },
        y: {
          formatter: function (val) {
            return `${val}`;
          },
          title: {
            formatter: function (seriesName) {
              return `${seriesName}:`;
            },
            style: {
              color: '#999' // This sets the color for the y-axis title in the tooltip
            }
          },
          style: {
            color: '#999' // This sets the color for the y-axis tooltip text
          }
        },
        marker: {
          show: true,
          fillColors: ['#00a76f'] // This sets the color for the markers in the tooltip
        },
        items: {
          display: 'flex'
        },
        fixed: {
          enabled: false,
          position: 'topRight',
          offsetX: 0,
          offsetY: 0,
        },
       
      },
      
    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'right', 
      floating: false,
      fontSize: '14px',
      fontFamily: 'Helvetica, Arial, sans-serif',
      offsetY: 0,
      markers: {
        width: 12,
        height: 12,
        strokeWidth: 0,
        strokeColor: '#fff',
        radius: 12,
        offsetX: -5,
        offsetY: 1,
      },
      itemMargin: {
        horizontal: 10,
        vertical: 5,
      },
      onItemClick: {
        toggleDataSeries: true
      },
      onItemHover: {
        highlightDataSeries: true
      },
      labels: {
        useSeriesColors: false // Set to true to use the series colors for legend labels
      },
    }
  };

  
  
  

  return (
    <div className="chart">
        <span className='chartTitle'>Chart</span>
      <ReactApexChart options={options} series={series} type="line" height={250} />
    </div>
  );
};

export default ChartComponent;
