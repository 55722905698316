import * as React from 'react';
import Box from '@mui/material/Box';
import { useState, useEffect, useMemo } from 'react'; // Import useMemo
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { FiberManualRecord, Edit } from '@mui/icons-material';
import Chip from '@mui/material/Chip';
import TableRowsLoader from '../../../../components/skeleton/TableSkeleton';
import Switch from "react-switch";
import Checkbox from '@mui/material/Checkbox';

// Create custom theme with overrides
const styles = {
  root: {
    padding: "0px !important"
  }
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f8f9fa',
    color: '#333',
    fontWeight: 'bold',
    fontSize: '1rem',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '0.9rem',
    borderBottom: '1px dashed rgba(224, 224, 224, 1)',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:last-child': {
    backgroundColor: 'white',
  },
}));

const StyledTableContainer = styled(TableContainer)({
  boxShadow: 'none',
});



const defaultColumns = [
  { field: 'select', headerName: '', width: 50 },
  { field: 'enabled', headerName: 'Enabled', width: 100 },
  { field: 'name', headerName: 'Name', width: 50 },
  { field: 'status', headerName: 'Status', width: 150 },
  { field: 'account_id', headerName: 'Account ID', width: 150 },
  { field: 'daily_budget', headerName: 'Daily Budget', width: 150 },
  { field: 'targeting_age_max', headerName: 'Targeting Age Max', width: 150 },
  { field: 'targeting_age_min', headerName: 'Targeting Age Min', width: 150 },
];

const getStatusChipColor = (status) => {
  switch (status) {
    case 'ACTIVE':
      return { color: '#22c55e', backgroundColor: '#22c55e29', fontWeight: 'bold', padding: "0px 6px" }; // Green
    default:
      return { color: '#d70000', backgroundColor: '#ffdddd', fontWeight: 'bold', padding: "0px 6px" }; // Default grey
  }
};

const AdSetsGrid = ({ filterStatus, items, visibleColumns, handleAdSetsCheckboxChange, openDrawer }) => {
  //const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const getFilteredItems = () => {
    if (filterStatus === 'ANY' || filterStatus === 'active') {
      return items;
    }

    return items.filter(item => item.status === filterStatus);
  };

  const [switchStates, setSwitchStates] = useState({});

  const handleToggleChange = (adset_id) => (checked) => {
    if (checked) {
      console.log(`Adset ID ${adset_id} notifications enabled:`, checked);
    }
    setSwitchStates({
      ...switchStates,
      [adset_id]: checked,
    });
  };

  // const handleAdSetsCheckboxChange = (adset_id) => (event) => {
  //   const isChecked = event.target.checked;

  //   // Update the state of the checkbox directly based on isChecked
  //   const updatedRows = items.map((item) =>
  //     item.adset_id === adset_id ? { ...item, isSelect: isChecked } : item
  //   );

  //   // Update the state with the new rows data
  //   setRows(updatedRows);

  //   // Notify the parent component about the change if needed
  //   if (isChecked) {
  //     onSelectedAdSetIdChange(adset_id);
  //   } else {
  //     onRemoveAdSetId(adset_id);
  //   }

  //   console.log(`AdSet ID ${adset_id} selected:`, isChecked);
  // };

  // Memoize rows to prevent unnecessary re-renders
  // const memoizedRows = useMemo(() => items, [items]);

  return (
    <Box sx={{ padding: 0, backgroundColor: 'white', borderRadius: 5, border: '1px solid #f3f3f3', overflow: 'hidden' }}>
      <StyledTableContainer component={Paper}>
        <Table className='table_edit' sx={{ minWidth: 1920 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {[...defaultColumns, ...visibleColumns].map((column, index) => (
                <StyledTableCell key={column.field + '_' + index} align={column.field === 'name' ? 'left' : 'center'}>
                  {column.headerName}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {items?.length === 0 ?
              <TableRowsLoader rowsNum={10} /> :
              getFilteredItems().map((row, index) => (
                <StyledTableRow key={row.campaign_id + '_' + index}>
                  {[...defaultColumns, ...visibleColumns].map(item =>
                    <StyledTableCell key={item.field} align={item.field === 'name' ? 'left' : 'center'}>
                      {item.field === 'status' ? (
                        <Chip
                          icon={<FiberManualRecord sx={{ border: 0, fontSize: '12px' }} style={{ color: getStatusChipColor(row[item.field]).color }} />}
                          label={row[item.field]}
                          style={getStatusChipColor(row[item.field])}
                        />
                      ) : item.field === 'enabled' ? (
                        <Switch
                          onChange={handleToggleChange(row.adset_id)}
                          checked={!!switchStates[row.adset_id]}
                          offColor="#d9d9d9"
                          onColor="#26cf5c"
                          uncheckedIcon={false}
                          checkedIcon={false}
                        />
                      ) : item.field === 'select' ? (
                        <Checkbox
                          checked={row.isSelect}
                          onChange={handleAdSetsCheckboxChange(row.adset_id)}
                          sx={{
                            color: '#D3D3D3', // Change the color of the checkbox
                            borderRadius: 3,
                            '&.Mui-checked': {
                              color: '#00a76f', // Change color when checked
                            },
                          }}
                        />
                      ) : item.field === 'name' ? (
                        <div onClick={() => openDrawer(row)} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>

                            <div>
                              <div>{row[item.field]}</div>
                              {/* <div style={{ fontSize: '0.8rem', color: '#555' }}>{row.email}</div> */}
                            </div>
                          </div>
                          <Edit sx={{ cursor: 'pointer', color: '#248fec', fontSize: '18px' }} />
                        </div>
                      ) : (
                        row[item.field])}
                    </StyledTableCell>)}
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </Box>
  );
};

export default AdSetsGrid;
