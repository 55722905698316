import './Files.css';
import { getAuth, signOut } from "firebase/auth";
import React, { useState, useEffect } from 'react';
import { collection, doc, getDoc } from 'firebase/firestore';
import { db } from '../FirebaseInit';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import NavbarAndDrawer from '../components/NavbarAndDrawer';
import StoreOverview from '../components/StoreOverview';
import FolderIcon from '../assets/Icons/Folder';
import PhotoIcon from '../assets/Icons/PhotoIcon';
import VideoIcon from '../assets/Icons/VideoIcon';
import PageHeader from '../components/PageHeader';
import { AddRounded, ChevronRightRounded, CloseRounded, FolderRounded, KeyboardArrowDownRounded, MoreVertRounded, Save, StarRounded, StarBorderRounded, UploadFileRounded, LinkRounded } from '@mui/icons-material';
import TextInput from '../components/TextInput';
import ProductSearchModal from '../components/ProductSearchModal';
import CampaignIcon from '../assets/Icons/Campaign';
import { ClickAwayListener } from '@mui/material';

function Files() {
    const navigate = useNavigate();

    const [showUploadContainer, setShowUploadContainer] = useState(false);
    const [filesInView, setFilesInView] = useState([]);
    const [directory, setDirectory] = useState('Omni Cloud');
    const [parentFolderID, setParentFolderID] = useState('/');
    const [showFileMenu, setShowFileMenu] = useState(false);
    const [draggedFile, setDraggedFile] = useState(null);
    const [showRenameContainer, setShowRenameContainer] = useState(false);
    const [renameValue, setRenameValue] = useState('');
    const [selectedFileID, setSelectedFileID] = useState('');
    const [parentIDsArray, setParentIDsArray] = useState(['/']);
    const [showSelectProductModal, setShowSelectProductModal] = useState(false);
    const [searchProductText, setSearchProductText] = useState('');
    const [selectedProduct, setSelectedProduct] = useState('');
    const [showFileDetailsPane, setShowFileDetailsPane] = useState(false);
    const [selectedFileForDetails, setSelectedFileForDetails] = useState({});
    const [expandTagsSection, setExpandTagsSection] = useState(true);
    const [expandPropertiesSection, setExpandPropertiesSection] = useState(true);
    const [linkedShopifyProductDetails, setLinkedShopifyProductDetails] = useState({});
    const [uploads, setUploads] = useState([]);
    const [showSortByDropdown, setShowSortByDropdown] = useState(false);
    const [sortBy, setSortBy] = useState('modified');

    const uid = localStorage.getItem('uid');
    const bid = localStorage.getItem('bid');


    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const startDate = `${year}-${month}-${day}`;
    const endDate = `${year}-${month}-${day}`;

    function formatDate(dateInput) {
        const date = new Date(dateInput);
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const year = date.getFullYear();
        const month = months[date.getMonth()];
        const day = date.getDate();
        const hour = date.getHours();
        const minute = date.getMinutes();
        const ampm = hour >= 12 ? 'PM' : 'AM';

        const formattedHour = hour % 12 || 12;
        const formattedDay = String(day).padStart(2, '0');
        const formattedMinute = String(minute).padStart(2, '0');

        return `${month} ${formattedDay} ${year} ${formattedHour}:${formattedMinute} ${ampm}`;
    }


    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        if (files.length > 0) {
          files.forEach(file => {
            const uploadKey = `${Date.now()}_${file.name}`;
            setUploads(prevUploads => [
              ...prevUploads,
              { key: uploadKey, name: file.name, size: file.size, progress: 0 },
            ]);
            uploadFile(file, uploadKey);
          });
        }
    };
      
      
      
    const uploadFile = async (file, uploadKey) => {
        if (!file) return;
      
        try {
          // Step 1: Get a presigned URL from the server
          const response = await axios.post(`http://${process.env.REACT_APP_API_BASE_URL}/getPresignedUrl`, {
            fileName: file.name,
            fileType: file.type,
          });
      
          const { url, key } = response.data;
      
          // Step 2: Upload the file to S3 using the presigned URL
          await axios.put(url, file, {
            headers: {
              'Content-Type': file.type,
            },
            onUploadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent;
              const percentage = Math.floor((loaded * 100) / total);
      
              // Update upload progress
              setUploads(prevUploads =>
                prevUploads.map(upload =>
                  upload.key === uploadKey
                    ? { ...upload, progress: percentage }
                    : upload
                )
              );
            },
          });
      
          // Step 3: Update your database with the file details
          await axios.post(`http://${process.env.REACT_APP_API_BASE_URL}/saveFileDetails`, {
            key,
            fileName: file.name,
            fileType: file.type,
            fileSize: file.size,
            omniBusinessId: 'b_zfPwbkxKMDfeO1s9fn5TejRILh34hd',
            directory: 'Omni Cloud',
            favorite: false,
            tags: null,
            parentId: '/',
            containsNumOfFiles: null,
          });
      
          // Step 4: Remove the upload from the progress list after completion
          setUploads(prevUploads => prevUploads.filter(upload => upload.key !== uploadKey));
          if (uploads.length === 1) {
          }
        } catch (error) {
          console.error('Error uploading file:', error);
          // Handle the error case as well, for example, by removing the failed upload
          setUploads(prevUploads => prevUploads.filter(upload => upload.key !== uploadKey));
        }
    };
      

      
      
      
    

    async function getFiles() {
        try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/getFileStructure?omni_business_id=${bid}&parent_id=${parentFolderID}&order_by=${sortBy}&order_direction=desc`);
        console.log(response.data);
        setFilesInView(response.data);
        } catch (error) {
        console.log("ERROR getting files Files.js", error);
        }
    }

    useEffect(() => {
        getFiles();
    }, [parentFolderID, sortBy]);

    function formatFileSize(bytes) {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    }

    // Function to handle drag start
    const handleDragStart = (e, file) => {
        setDraggedFile(file);
        e.target.classList.add('dragging');
    };

    // Function to handle drag end
    const handleDragEnd = (e) => {
        e.target.classList.remove('dragging');
    };

    // Function to handle drop action
    const handleDrop = (e, folder) => {
        e.preventDefault(); // Prevent default behavior
        // Run your function here
        console.log('File dropped onto folder:', folder);
        console.log('Dragged file:', draggedFile);
        updateFileParent(folder.id, directory + folder.name, draggedFile.id);
        // Reset dragged file state
        setDraggedFile(null);

        // Add dropped animation class
        e.target.classList.add('dropped');
        setTimeout(() => {
            e.target.classList.remove('dropped');
        }, 200); // Duration should match the animation duration
    };


    async function updateFileParent(new_parent_id, new_directory, current_id) {
        try {
        const response = await axios.get(`http://${process.env.REACT_APP_API_BASE_URL}/updateFileParent?new_parent_id=${new_parent_id}&new_directory=${new_directory}&current_id=${current_id}`);
        console.log(response.data);
        await getFiles();
        } catch (error) {
        console.log("ERROR updating file parent Files.js", error);
        }
    }

    async function updateFileName() {
        try {
        const response = await axios.get(`http://${process.env.REACT_APP_API_BASE_URL}/updateFileName?new_name=${renameValue}&id=${selectedFileID}`);
        console.log(response.data);
        await getFiles();
        } catch (error) {
        console.log("ERROR updating file parent Files.js", error);
        }
    }

    async function downloadFile(s3Url, fileName) {
        try {
        // Make a GET request to the server endpoint to initiate the file download
        const response = await axios.get(`http://${process.env.REACT_APP_API_BASE_URL}/downloadFileFromS3?s3Url=${encodeURIComponent(s3Url)}&fileName=${encodeURIComponent(fileName)}`, {
            responseType: 'blob', // Set response type to 'blob' for binary data
        });

        // Create a temporary URL for the file blob
        const url = window.URL.createObjectURL(new Blob([response.data]));

        // Create a link element to trigger the download
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName); // Set the desired file name
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Clean up
        URL.revokeObjectURL(url);
        document.body.removeChild(link);
        } catch (error) {
        console.error('Error downloading file:', error);
        }
    };

    function copyToClipboard(text) {
        // Create a temporary textarea element
        const textarea = document.createElement('textarea');
        textarea.value = text;

        // Set the textarea to be invisible
        textarea.style.position = 'fixed';
        textarea.style.top = '-9999px';
        textarea.style.left = '-9999px';

        // Append the textarea to the document body
        document.body.appendChild(textarea);

        // Select the text within the textarea
        textarea.select();

        // Copy the selected text to the clipboard
        document.execCommand('copy');

        // Remove the textarea from the document body
        document.body.removeChild(textarea);
    }

    async function updateFileProductID() {
        try {
        const response = await axios.get(`http://${process.env.REACT_APP_API_BASE_URL}/updateFileProductID?file_id=${selectedFileForDetails.id}&new_product_id=${selectedProduct.product_id}`);
        console.log(response.data);
        await getFiles();
        setShowSelectProductModal(false);
        setSelectedProduct('');
        } catch (error) {
        console.log("ERROR updating file parent Files.js", error);
        }
    }

    //Handle drag and drop upload to upload popup
    const [isDragOver, setIsDragOver] = useState(false);

    const handleDragEnter = (e) => {
        e.preventDefault();
        setIsDragOver(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setIsDragOver(false);
    };

    const handleDropPopup = (e) => {
        e.preventDefault();
        setIsDragOver(false);
        const files = e.dataTransfer.files;
        if (files && files.length > 0) {
        handleFileChangePopup(files);
        }
    };

    const handleFileChangePopup = async (files) => {
        // Handle the file upload here
        console.log(files);
        if (files.length > 0) {
        const uploadedFile = files[0]; // Assuming single file upload
        console.log(uploadedFile);
        await uploadFile(uploadedFile);
        }
    };

    async function insertNewFolder() {
        try {
        const response = await axios.post(`http://${process.env.REACT_APP_API_BASE_URL}/insertNewFolder?omni_business_id=${bid}`);
        console.log(response.data);
        await getFiles();
        setShowUploadContainer(false);
        } catch (error) {
        console.log("ERROR updating file parent Files.js", error);
        }
    }

    async function updateFileFavorite(favorite) {
        try {
        const response = await axios.get(`http://${process.env.REACT_APP_API_BASE_URL}/updateFileFavorite?id=${selectedFileForDetails.id}&favorite=${favorite}`);
        console.log(response.data);
        await getFiles();
        } catch (error) {
        console.log("ERROR updating file parent Files.js", error);
        }
    }

    async function getShopifyProduct(product_id) {
        try {
        const response = await axios.get(`http://${process.env.REACT_APP_API_BASE_URL}/getShopifyProduct?product_id=${product_id}`);
        console.log(response.data);
        setLinkedShopifyProductDetails(response.data[0]);
        } catch (error) {
        console.log("ERROR updating file parent Files.js", error);
        }
    }


    return (
        <div className='FilesMainContainer'>
        <div className='FilesHeaderContainer'>
            <PageHeader title="File Manager" />

            <div className='FileHeaderRightRow'>

                <ClickAwayListener onClickAway={() => setShowSortByDropdown(false)}>
                    <div className='FileHeaderSortByDropdown'>
                        <div className='FileContainerSortByButton' onClick={() => { setShowSortByDropdown(true) }}>
                            <span>Sort By</span>
                            <KeyboardArrowDownRounded />
                        </div>
                        <div className={showSortByDropdown ? 'FileHeaderSortByDropdownContainer' : 'FileHeaderSortByDropdownContainerHidden'}>
                            <span className={sortBy == 'name' ? 'FileHeaderSortByDropdownRowSelected' : 'FileHeaderSortByDropdownRow'} onClick={() => { setShowSortByDropdown(false); setSortBy('name')}}>Name</span>
                            <span className={sortBy == 'created' ? 'FileHeaderSortByDropdownRowSelected' : 'FileHeaderSortByDropdownRow'} onClick={() => { setShowSortByDropdown(false); setSortBy('created')}}>Created</span>
                            <span className={sortBy == 'modified' ? 'FileHeaderSortByDropdownRowSelected' : 'FileHeaderSortByDropdownRow'} onClick={() => { setShowSortByDropdown(false); setSortBy('modified')}}>Modified</span>
                        </div>
                    </div>
                </ClickAwayListener>

                <div className='FileHeaderUploadButton' onClick={() => { setShowUploadContainer(!showUploadContainer) }}>
                    <AddRounded fontSize='small' />
                    <span className='FileHeaderUploadButtonText'>New</span>
                </div>
            </div>
        </div>

        <div className='FilesDirectoryStringRow'>
            {Array.from({ length: directory.split('/').length }).map((_, index) => (
            <div className='FilesDirectoryStringRow' key={index}>
                <span className='FilesDirectoryString' onClick={() => {
                    setParentFolderID(parentIDsArray[index]);
                    setDirectory(directory.split('/').slice(0, index + 1).join('/'));
                }}>
                {directory.split('/')[index]}
                </span>
                {index !== directory.split('/').length - 1 && (
                <span className='FilesDirectoryString'>&#x2022;</span>
                )}
            </div>
            ))}
        </div>


        {filesInView.filter(item => item.type === 'folder').length > 0 && (
            <span className='FilesContainerTitle'>Folders ({filesInView.filter(item => item.type === 'folder').length})</span>
        )}

        <div className='FilesContainer'>
            {filesInView.map((x) => (
                <React.Fragment>
                    {x.type === 'folder' && (
                        <div
                            key={x.id}
                            className='FolderContainer'
                            onClick={() => {
                                setDirectory(directory + '/' + x.name);
                                setParentFolderID(x.id);
                                const previousDir = parentIDsArray;
                                previousDir.push(x.id);
                                setParentIDsArray(previousDir);
                            }}
                            onDragOver={(e) => e.preventDefault()} // Prevent default behavior
                            onDrop={(e) => handleDrop(e, x)}
                            draggable={true} // Allow draggable attribute for files
                            onDragStart={(e) => handleDragStart(e, x)} // Set dragged file information
                            onDragEnd={handleDragEnd} // Handle drag end
                        >
                            <div
                            className='FileContainerMoreIcon'
                            onClick={(e) => {
                                e.stopPropagation(); // Stop propagation to prevent onClick of parent div
                                setShowFileMenu(showFileMenu === x.id ? false : x.id);
                            }}
                            >
                            <MoreVertRounded />
                           <ClickAwayListener onClickAway={() => setShowFileMenu(false)}>
                                <div className={showFileMenu === x.id ? 'FileContainerMoreDropdownContainer' : 'FileContainerMoreDropdownContainerHidden'}>
                                    <span className='FileContainerMoreDropdownRow' onClick={() => { setShowSelectProductModal(true); setShowFileMenu(false); setSelectedFileForDetails(x) }}>Link To Shopify Product</span>
                                    <span className='FileContainerMoreDropdownRow' onClick={() => { getShopifyProduct(x.product_id); setSelectedFileForDetails(x); setShowFileDetailsPane(true); setShowFileMenu(false) }}>Details</span>
                                    <span className='FileContainerMoreDropdownRow' onClick={() => { copyToClipboard(x.s3_link); setShowFileMenu(false) }}>Copy Link</span>
                                    <span className='FileContainerMoreDropdownRow' onClick={() => { downloadFile(x.s3_link, x.name); setShowFileMenu(false) }}>Download</span>
                                    <span className='FileContainerMoreDropdownRow' onClick={() => { setRenameValue(x.name); setShowRenameContainer(true); setSelectedFileID(x.id); setShowFileMenu(false) }}>Rename</span>
                                    <span className='FileContainerMoreDropdownRowBottom' onClick={() => setShowFileMenu(false)}>Delete</span>
                                </div>
                            </ClickAwayListener>
                            </div>
                            <div
                            className=''
                            draggable={true} // Allow draggable attribute for files
                            onDragStart={(e) => handleDragStart(e, x)} // Set dragged file information
                            >
                            {x.type === 'folder' ? <FolderIcon /> : ''}
                            </div>
                            <span className='FileContainerName'>{x.name}</span>
                            <span className='FileContainerDescription'>{formatFileSize(x.size)} &#x2022; {formatDate(x.created)}</span>
                        </div>
                    )}
                </React.Fragment>
            ))}
        </div>

        
        {filesInView.filter(item => item.type !== 'folder').length > 0 && (
            <span className='FilesContainerTitle'>Files ({filesInView.filter(item => item.type !== 'folder').length})</span>
        )}

        <div className='FilesContainer'>
            {filesInView.map((x) => (
                <React.Fragment>
                    {x.type !== 'folder' && (
                        <div key={x.id} className='FileContainer' onDrop={(e) => handleDrop(e, x)}  onClick={() => { getShopifyProduct(x.product_id); setSelectedFileForDetails(x); setShowFileDetailsPane(true);}}>
                            <div className='FileContainerMoreIcon' onClick={(e) => { e.stopPropagation(); setShowFileMenu(showFileMenu === x.id ? false : x.id);}}>
                                <MoreVertRounded />
                                <ClickAwayListener onClickAway={() => setShowFileMenu(false)}>
                                    <div className={showFileMenu === x.id ? 'FileContainerMoreDropdownContainer' : 'FileContainerMoreDropdownContainerHidden'}>
                                        <span className='FileContainerMoreDropdownRow' onClick={() => { setShowSelectProductModal(true); setShowFileMenu(false); setSelectedFileForDetails(x) }}>Link To Shopify Product</span>
                                        <span className='FileContainerMoreDropdownRow' onClick={() => { getShopifyProduct(x.product_id); setSelectedFileForDetails(x); setShowFileDetailsPane(true); setShowFileMenu(false) }}>Details</span>
                                        <span className='FileContainerMoreDropdownRow' onClick={() => { copyToClipboard(x.s3_link); setShowFileMenu(false) }}>Copy Link</span>
                                        <span className='FileContainerMoreDropdownRow' onClick={() => { downloadFile(x.s3_link, x.name); setShowFileMenu(false) }}>Download</span>
                                        <span className='FileContainerMoreDropdownRow' onClick={() => { setRenameValue(x.name); setShowRenameContainer(true); setSelectedFileID(x.id); setShowFileMenu(false) }}>Rename</span>
                                        <span className='FileContainerMoreDropdownRowBottom' onClick={() => setShowFileMenu(false)}>Delete</span>
                                    </div>
                                </ClickAwayListener>
                            </div>
                            <div draggable={true} onDragStart={(e) => handleDragStart(e, x)} className='FileMediaContainer'>
                                {x.type === 'image' ? <img className='FileContainerImage' src={x.s3_link} /> : ''}
                                {x.type === 'video' ? <video className='FileContainerImage' src={x.s3_link} /> : ''}
                            </div>
                            <div className='FileContainerBottom'>
                                <span className='FileContainerName'>{x.name.length > 28 ? x.name.slice(0,28) + "..." : x.name}</span>
                                <span className='FileContainerDescription'>{formatFileSize(x.size)} &#x2022; {formatDate(x.created)}</span>
                                {x.product_id !== null ? <div className='FileContainerProductTitle'><LinkRounded style={{paddingRight: 10}}/>{x.product_title}</div> : <br/>}
                            </div>
                        </div>
                    )}
                </React.Fragment>
            ))}
        </div>



        {showUploadContainer && (
            <div className="FilesUploadContainerBackground">
                <div className="FilesUploadContainer">
                <span className='FilesPopupCloseIcon' onClick={() => { setShowUploadContainer(false) }}>
                    <CloseRounded fontSize='medium' />
                </span>
                <div className="FilesUploadTopContainer">
                    <div className={`drop-area ${isDragOver ? 'hover' : ''}`} 
                        onDragEnter={handleDragEnter} 
                        onDragOver={handleDragEnter} 
                        onDragLeave={handleDragLeave} 
                        onDrop={handleDropPopup}>
                    <p>Drag & Drop files here or click to browse</p>
                    <label htmlFor="fileInput" className="FilesChooseFileToUploadButton">
                        <div>Choose File</div>
                        <input 
                        id="fileInput" 
                        className="FileInput" 
                        type="file" 
                        multiple
                        onChange={handleFileChange} 
                        style={{ opacity: 0, maxWidth: 0, maxHeight: 0, display: 'none' }} 
                        />
                    </label>
                    </div>
                </div>
                <div className='FileUploadPopupButtonRow'>
                    <div className='FileUploadPopupButton' onClick={() => { insertNewFolder() }}>
                    <FolderRounded fontSize='small' />
                    <span className='FileHeaderUploadPopupButtonText'>New Folder</span>
                    </div>
                    <div className='FileUploadPopupButton' onClick={() => { document.getElementById('fileInput').click(); }}>
                    <UploadFileRounded fontSize='small' />
                    <span className='FileHeaderUploadPopupButtonText'>Upload</span>
                    </div>
                </div>
                </div>
            </div>
        )}


        {showRenameContainer && (
            <div className="FilesUploadContainerBackground">
            <div className="FilesUploadContainer">
                <span>Rename File</span>
                <TextInput placeholder='New Name' type='text' value={renameValue} onChange={setRenameValue} widthPercent={100} />
                <div className='FileUploadPopupButton' onClick={() => { updateFileName(); setShowRenameContainer(false) }}>
                <Save fontSize='small' />
                <span className='FileHeaderUploadPopupButtonText'>Save</span>
                </div>
            </div>
            </div>
        )}

        <ProductSearchModal
            showSelectProductModal={showSelectProductModal} setShowSelectProductModal={setShowSelectProductModal}
            selectedProduct={selectedProduct} setSelectedProduct={setSelectedProduct}
            searchProductText={searchProductText} setSearchProductText={setSearchProductText}
            titleText='Link File to Shopify Product' submitFunction={updateFileProductID}
        />

        <div className={`FileDetailsRightPane ${showFileDetailsPane ? 'open' : ''}`}>
            <div className='FileDetailsRightPaneHeaderContainer'>
                <span className='FileDetailsRightPaneHeaderCollapseIcon' onClick={() => { setShowFileDetailsPane(false) }}>
                    <ChevronRightRounded />
                </span>
                <span className='FileDetailsRightPaneHeaderTitle'>Details</span>
                <span className='FileDetailsRightPaneHeaderCollapseIcon' style={{ opacity: 0 }}>
                    <ChevronRightRounded />
                </span>
            </div>
            <div className='FileDetailsRightPaneContent'>
            <div className='FileDetailsRightPaneTopSection'>
                <div className='FileDetailsRightPaneTopSectionFavoriteButton' onClick={() => { updateFileFavorite(!Boolean(selectedFileForDetails.favorite)) }}>{Boolean(selectedFileForDetails.favorite) ? <StarRounded /> : <StarBorderRounded />}</div>
                    {selectedFileForDetails.type === 'folder' ? <FolderIcon /> : null}
                    {selectedFileForDetails.type === 'image' ? <img className='FileDetailsRightPaneTopSectionMedia' src={selectedFileForDetails.s3_link}/> : null}
                    {selectedFileForDetails.type === 'video' ? <video className='FileDetailsRightPaneTopSectionMedia' controls src={selectedFileForDetails.s3_link}/> : null}




                <span className='FileDetailsRightPaneTopSectionName'>{selectedFileForDetails.name}</span>
                <span className='FileDetailsRightPaneTopSectionStatus'>{selectedFileForDetails?.file_type?.split('/')[1]}</span>

                <span className='FileDetailsRightPaneTopSectionDivider'></span>

                <div className='FileDetailsRightPaneTopSectionExpandButtonContainer' onClick={() => { setExpandTagsSection(!expandTagsSection) }}>
                    <span className='FileDetailsRightPaneTopSectionExpandText'>Tags</span>
                    <span className='FileDetailsRightPaneTopSectionExpandArrow' style={{ transform: !expandTagsSection ? 'rotate(180deg)' : '' }}><KeyboardArrowDownRounded /></span>
                </div>

                <div className={expandTagsSection ? 'FileDetailsRightPaneTopSectionTagsContainer' : 'FileDetailsRightPaneTopSectionTagsContainerHidden'}>
                {selectedFileForDetails.tags?.map((x) => (
                    <span className='FileDetailsRightPaneTopSectionTag'>{x}</span>
                ))}
                </div>

                <div className='FileDetailsRightPaneTopSectionExpandButtonContainer' onClick={() => { setExpandPropertiesSection(!expandPropertiesSection) }}>
                <span className='FileDetailsRightPaneTopSectionExpandText'>Properties</span>
                <span className='FileDetailsRightPaneTopSectionExpandArrow' style={{ transform: !expandPropertiesSection ? 'rotate(180deg)' : '' }}><KeyboardArrowDownRounded /></span>
                </div>

                <div className={expandPropertiesSection ? 'FileDetailsRightPaneTopSectionPropertiesContainer' : 'FileDetailsRightPaneTopSectionPropertiesContainerHidden'}>
                <table style={{ width: '100%' }}>
                    <tbody>
                    <tr>
                        <td className='FileDetailsRightPaneTopSectionPropertiesTableLeft'>Size</td>
                        <td className='FileDetailsRightPaneTopSectionPropertiesTableRight'>{formatFileSize(selectedFileForDetails.size)}</td>
                    </tr>
                    <tr>
                        <td className='FileDetailsRightPaneTopSectionPropertiesTableLeft'>Created</td>
                        <td className='FileDetailsRightPaneTopSectionPropertiesTableRight'>{formatDate(selectedFileForDetails.created)}</td>
                    </tr>
                    <tr>
                        <td className='FileDetailsRightPaneTopSectionPropertiesTableLeft'>Modified</td>
                        <td className='FileDetailsRightPaneTopSectionPropertiesTableRight'>{formatDate(selectedFileForDetails.modified)}</td>
                    </tr>
                    <tr>
                        <td className='FileDetailsRightPaneTopSectionPropertiesTableLeft'>Type</td>
                        <td className='FileDetailsRightPaneTopSectionPropertiesTableRight'>{selectedFileForDetails.type}</td>
                    </tr>
                    </tbody>
                </table>
                </div>
            </div>

            <div className='FileDetailsRightPaneBottomSection'>
                <span className='FileDetailsRightPaneBottomSectionTitle'>Connected Product</span>
                <div className='FileDetailsRightPaneBottomSectionProductContainer'>
                    <div className='FileDetailsRightPaneBottomSectionProductImageContainer'>
                        <img className='FileDetailsRightPaneBottomSectionProductImage' src={linkedShopifyProductDetails?.img_src} />
                    </div>
                    <span className='FileDetailsRightPaneBottomSectionProductName'>{linkedShopifyProductDetails?.product_title}</span>
                    <div className={linkedShopifyProductDetails?.status == "ACTIVE" ? 'FileDetailsRightPaneBottomSectionProductStatusActive' : 'FileDetailsRightPaneBottomSectionProductStatusInactive'}>{linkedShopifyProductDetails?.status}</div>

                </div>
                {linkedShopifyProductDetails?.status == "ACTIVE" && (<span className='FileDetailsRightPaneBottomSectionViewInShopify' onClick={() => {window.open(linkedShopifyProductDetails.online_store_url, '_blank')}}>View in Shopify</span>)}

                <span className='FileDetailsRightPaneBottomSectionTitle'>Media Analytics</span>

                <span className='FileDetailsRightPaneBottomSectionTitle'>Active Ads</span>

            </div>
            </div>
        </div>

        {uploads.length > 0 && (
            <div className="FilesUploadProgress">
                {uploads.map((upload) => (
                    <div key={upload.key} className="FilesUploadProgressRow">
                        <span className='FilesUploadProgressName'>{upload.name}</span>
                        <span className='FilesUploadProgressSize'>{(upload.size / 1024 / 1024).toFixed(2)} MB</span>
                        <div className='FilesUploadProgressBarParent'>
                        <div className='FilesUploadProgressBarChild' style={{ width: `${upload.progress}%` }}></div>
                        </div>
                        <span>{upload.progress}%</span>
                    </div>
                ))}
            </div>
        )}




        </div>
    );
}

export default Files;

// File tags 
// Connected product Image JOIN IN Database query
// Media analytics 
// Active Ads connection  
