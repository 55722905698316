import './Analytics.css';
import { getAuth, signOut } from "firebase/auth";
import react, { useState, useEffect } from 'react';
import {  doc, getDoc } from 'firebase/firestore';
import { collection, query, where, getDocs } from 'firebase/firestore';
import {db} from '../FirebaseInit';
import { Line } from 'react-chartjs-2';
import LineChart from '../components/LineChart';
import { useNavigate } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import NavbarAndDrawer from '../components/NavbarAndDrawer';


function Analytics() {
  const navigate = useNavigate();
  const [isLoadingCharts, setIsLoadingCharts] = useState(true);
  const [showCharts, setShowCharts] = useState({
    revenue: true,
    profit: true,
    aov: true,
    cogs: true,
    googleAdSpend: true,
    facebookAdSpend: true,
    totalOrders: true
  });
  const [showDropdown, setShowDropdown] = useState(false);
  const [totalRevenue, setTotalRevenue] = useState(0)
  const [totalProfit, setTotalProfit] = useState(0)
  const [totalAOV, setTotalAOV] = useState(0)
  const [totalCOGS, setTotalCOGS] = useState(0)
  const [totalGoogleAdSpend, setTotalGoogleAdSpend] = useState(0)
  const [totalTotalOrder, settotalTotalOrder]= useState(0)
  const uid = localStorage.getItem('uid');

  
  const [startDate, setStartDate] = useState(() => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1); // Subtract one day
    return yesterday;
  });
  //FOR TESTING ONLY:
  useEffect(() => {
    setStartDate(new Date('2024-03-01'))
  }, [])



  const [endDate, setEndDate] = useState(new Date());
  
  const [prevStartDate, setPrevStartDate] = useState(() => {
    const prevYesterday = new Date();
    prevYesterday.setDate(prevYesterday.getDate() - 2); // Subtract two days
    return prevYesterday;
  });
  const [prevEndDate, setPrevEndDate] = useState(() => {
    const prevToday = new Date();
    prevToday.setDate(prevToday.getDate() - 1); // Subtract one day
    return prevToday;
  });

  //charts
  const [totalRevenueData, setTotalRevenueData] = useState({
    labels: [],
    datasets: [
      {
        label: '',
        data: [],
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
      },
      {
        label: '',
        data: [],
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        borderDash: [ 2,2],
      }
    ],
  });

  const [totalProfitData, setTotalProfitData] = useState({
    labels: [],
    datasets: [
      {
        label: '',
        data: [],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
      },
      {
        label: '',
        data: [],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        borderDash: [ 2,2],
      }
    ],
  });

  const [averageOrderValueData, setAverageOrderValueData] = useState({
    labels: [],
    datasets: [
      {
        label: '',
        data: [],
        borderColor: 'rgb(54, 162, 235)',
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        
      },
      {
        label: '',
        data: [],
        borderColor: 'rgb(54, 162, 235)',
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        borderDash: [ 2,2],
      }
    ],
  });
  const [totalCOGSData, settotalCOGSData] = useState({
    labels: [],
    datasets: [
      {
        label: '',
        data: [],
        borderColor: 'rgb(54, 162, 235)',
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
      },
      {
        label: ' ',
        data: [],
        borderColor: 'rgb(54, 162, 235)',
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        borderDash: [ 2,2],
      }
    ],
  });
  const [googleAdSpend, setgoogleAdSpend] = useState({
    labels: [],
    datasets: [
      {
        label: '',
        data: [],
        borderColor: 'rgb(54, 162, 235)',
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
      },
      {
      label: ' ',
      data: [],
      borderColor: 'rgb(153, 102, 255)',
      backgroundColor: 'rgba(153, 102, 255, 0.2)',
      borderDash: [ 2,2],
    }
    ],
  });

  const [totalOrders, settotalOrders] = useState({
    labels: [],
    datasets: [
      {
        label: '',
        data: [],
        borderColor: 'rgb(54, 162, 235)',
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
      },
      {
      label: '',
      data: [],
      borderColor: 'rgb(153, 102, 255)',
      backgroundColor: 'rgba(153, 102, 255, 0.2)',
      borderDash: [ 2,2],
    }
    ],
  });
  


  useEffect(() => {
    const fetchData = async () => {
      const startDateString = startDate.toISOString().split('T')[0];
      const endDateString = endDate.toISOString().split('T')[0];
      
      
      // Calculate the difference in days between start and end dates
      const diffDays = (endDate - startDate) / (1000 * 60 * 60 * 24);
      
      // Calculate the previous period's start and end dates
      const prevStartDate = new Date(startDate);
      prevStartDate.setDate(prevStartDate.getDate() - diffDays);
      const prevEndDate = new Date(startDate);
      prevEndDate.setDate(prevEndDate.getDate());
      
      const prevStartDateString = prevStartDate.toISOString().split('T')[0];
      const prevEndDateString = prevEndDate.toISOString().split('T')[0];
      
      console.log(`Querying from ${startDateString} to ${endDateString}`);
      
      console.log(`prev Querying from ${prevStartDateString} to ${prevEndDateString}`);

      const colRef = collection(db, "ShopifyStoreStats", "instant-viral.myshopify.com", "dates");
      
      setIsLoadingCharts(true);
      // Query for the selected date range only
      const selectedRangeQuery = query(colRef, where("date", ">=", startDateString.toString()), where("date", "<=", endDateString.toString()));
      
      //prev query range 
      const previousRangeQuery = query(colRef, where("date", ">=", prevStartDateString), where("date", "<=", prevEndDateString));
    
      // Execute the query
      const [selectedRangeSnapshot, previousRangeSnapshot] = await Promise.all([
        getDocs(selectedRangeQuery),
        getDocs(previousRangeQuery)
    ]);
      

        if (selectedRangeSnapshot.empty) {
            console.log('No matching documents for the selected period.');
            return;
        }
        
        if (previousRangeSnapshot.empty) {
            console.log('No matching documents for the previous period.');
            // Consider how you want to handle this case
        }
  
      // Arrays to hold data for the selected range
      let labels = [];
      let revenueData = [];
      let profitData = [];
      let aovData = [];
      let cogsData = [];
      let gasData = [];
      let totordData =[];

      let hrLabels = [];
      let hrRevenueData = [];
      let hrProfitData = [];
      let hrAovData = [];
      let hrCogsData = [];
      let hrGasData = [];
      let hrTotOrdData =[];


      let prevlabels = [];
      let prevrevenueData = [];
      let prevprofitData = [];
      let prevaovData = [];
      let prevcogsData = [];
      let prevgasData = [];
      let prevtotordData =[];

  
      let totalRevenueVar = 0;
      let totalProfitVar = 0;
      let totalAOVVar = 0;
      let totalCOGSVar = 0;
      let totalGoogleAdSpendVar = 0;
      let totalTotalOrder = 0;


      setTotalRevenueData({ labels: [], datasets: [] });
      setTotalProfitData({ labels: [], datasets: [] });
      setAverageOrderValueData({ labels: [], datasets: [] });
      settotalCOGSData({ labels: [], datasets: [] });
      setgoogleAdSpend({ labels: [], datasets: [] });
      settotalOrders({ labels: [], datasets: [] });
      // Process selected range data
      
      selectedRangeSnapshot.forEach(doc => {
        const docData = doc.data();
        

          if(diffDays > 2)  {
                labels.push(doc.id);
                revenueData.push(docData.totalRevenue);
                profitData.push(docData.totalProfit);
                aovData.push(docData.averageOrderValue);
                cogsData.push(docData.totalCOGS);
                gasData.push(docData.googleAdSpend);
                totordData.push(docData.totalOrders);
          
               
          } else {
            // Hourly data processing
            for (let i = 0; i < 24; i++) {
                const dateTimeLabel = new Date(startDate);
                dateTimeLabel.setHours(i, 0, 0, 0);
                hrLabels.push(dateTimeLabel);  // Store the Date object for the chart
        
                hrRevenueData[i] = (hrRevenueData[i] || 0) + (docData.hourlyRevenue && docData.hourlyRevenue.length > i ? docData.hourlyRevenue[i] : 0);
                hrProfitData[i] = (hrProfitData[i] || 0) + (docData.hourlyProfit && docData.hourlyProfit.length > i ? docData.hourlyProfit[i] : 0);
                hrCogsData[i] = (hrCogsData[i] || 0) + (docData.hourlyCOGS && docData.hourlyCOGS.length > i ? docData.hourlyCOGS[i] : 0);
                hrTotOrdData[i] = (hrTotOrdData[i] || 0) + (docData.hourlyOrders && docData.hourlyOrders.length > i ? docData.hourlyOrders[i] : 0);
            }
      }
      console.log("hourlylabel",hrLabels);
      console.log("hourlCOGS",hrCogsData);

      totalRevenueVar += docData.totalRevenue;
      totalProfitVar += docData.totalProfit;
    
      totalCOGSVar += docData.totalCOGS;
      totalGoogleAdSpendVar += docData.googleAdSpend;
      totalTotalOrder += docData.totalOrders;

      });

      totalAOVVar += totalRevenueVar / totalTotalOrder;
      setTotalRevenue(totalRevenueVar);
      setTotalProfit(totalProfitVar);
      setTotalAOV(totalAOVVar);
      setTotalCOGS(totalCOGSVar);
      setTotalGoogleAdSpend(totalGoogleAdSpendVar);
      settotalTotalOrder(totalTotalOrder)
 
      previousRangeSnapshot.forEach(doc => {
        const docData = doc.data();
        console.log(`Previous Doc ${doc.id}: `, docData);
    
        prevlabels.push(doc.id);
        prevrevenueData.push(docData.totalRevenue);
        prevprofitData.push(docData.totalProfit);
        prevaovData.push(docData.averageOrderValue);
        prevcogsData.push(docData.totalCOGS);
        prevgasData.push(docData.googleAdSpend);
        prevtotordData.push(docData.totalOrders);
    });

    setIsLoadingCharts(false);
  
    const dateRangeLabel = formatRange(startDate, endDate);
    const prevdateRangeLabel = formatRange(prevStartDate, prevEndDate);
    const isShortPeriod = diffDays <= 2;
    const currentPeriodLabel = isShortPeriod ? `${startDate.toDateString()}` : `${dateRangeLabel}`;
    const previousPeriodLabel = `${prevdateRangeLabel}`;
  
   
      setTotalRevenueData({
        labels: isShortPeriod ? hrLabels : labels,
    datasets: [
        {
            label: currentPeriodLabel,
            data: isShortPeriod ? hrRevenueData : revenueData,
            borderColor: 'rgb(75, 192, 192)',
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
        },
        {
            label: previousPeriodLabel,
            data: prevrevenueData,
            borderColor: 'rgb(153, 102, 255)',
            backgroundColor: 'rgba(153, 102, 255, 0.2)',
            borderDash: [2, 2],
        }
    ]
  });
  
    
  
    setTotalProfitData ({
      labels: labels,
      labels: isShortPeriod ? hrLabels : labels,
    datasets: [
        {
            label: currentPeriodLabel,
            data: isShortPeriod ? hrProfitData : profitData,
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
        },
        {
            label: previousPeriodLabel,
            data: prevprofitData,
            borderColor: 'rgb(153, 102, 255)',
            backgroundColor: 'rgba(153, 102, 255, 0.2)',
            borderDash: [2, 2],
        }
    ]
  });
  setAverageOrderValueData({
    labels: isShortPeriod ? hrLabels : labels,
    datasets: [
      {
        label: currentPeriodLabel,
        data: isShortPeriod ? hrAovData : aovData,
        borderColor: 'rgb(54, 162, 235)',
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
      },
      {
        label: previousPeriodLabel,
        data: prevaovData,
        borderColor: 'rgb(54, 162, 235)',
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        borderDash: [ 2,2],
      },
    ],
  });
  
  settotalCOGSData({
    labels: isShortPeriod ? hrLabels : labels,
    datasets: [
      {
        label: currentPeriodLabel,
        data: isShortPeriod ? hrCogsData : cogsData,
        borderColor: 'rgb(54, 162, 235)',
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
      },
      {
        label: previousPeriodLabel,
        data: prevcogsData,
        borderColor: 'rgb(54, 162, 235)',
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        borderDash: [ 2,2],
      },
    ],
  });
  
  setgoogleAdSpend({
    labels: isShortPeriod ? hrLabels : labels,
    datasets: [
      {
        label: currentPeriodLabel,
        data: isShortPeriod ? hrGasData : gasData,
        borderColor: 'rgb(54, 162, 235)',
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
      },
      {
        label: previousPeriodLabel,
        data: prevgasData,
        borderColor: 'rgb(153, 102, 255)',
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        borderDash: [ 2,2],
      },
    ],
  });
  
  settotalOrders({
    labels: isShortPeriod ? hrLabels : labels,
    datasets: [
      {
        label: currentPeriodLabel,
        data: isShortPeriod ? hrTotOrdData : totordData,
        borderColor: 'rgb(54, 162, 235)',
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
      },
      {
        label: previousPeriodLabel,
        data: prevtotordData,
        borderColor: 'rgb(153, 102, 255)',
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        borderDash: [ 2,2],
      },
    ],
  });
  
  
};

fetchData();
}, [startDate, endDate]);


const formatRange = (startDate, endDate) => {
  const options = { month: 'short', day: 'numeric' };
  const startFormat = new Intl.DateTimeFormat('en-US', options).format(startDate);
  const endFormat = new Intl.DateTimeFormat('en-US', options).format(endDate);

  // For the year, we add it at the end. We assume endDate to represent the range's year.
  const year = endDate.getFullYear();

  return `${startFormat}–${endFormat}, ${year}`;
};

    const handleStartDateChange = date => {
      setStartDate(date);
    };
  
    const handleEndDateChange = date => {
      setEndDate(date);
    };


    const handleprevStartDateChange = date => {
      setStartDate(date);
    };
  
    const handleprevEndDateChange = date => {
      setEndDate(date);
    };
    
  async function logOut() {
    const auth = getAuth();
    signOut(auth).then(() => {
      // Sign-out successful.
    }).catch((error) => {
      // An error happened.
    });
  }





  // Preset date range selection
    const [selectedPreset, setSelectedPreset] = useState('');
    // Function to handle preset date range selection
   const handlePresetDateRange = (preset) => {
      const today = new Date();
      today.setHours(23, 59, 59, 999); // Set to end of the day for 'today'
      const startOfToday = new Date();
      startOfToday.setHours(0, 0, 0, 0); // Set to start of the day for 'today'
    
      switch (preset) {
        case 'today':
          setStartDate(startOfToday);
          setEndDate(today);
          break;
        case 'yesterday':
          const yesterday = new Date(startOfToday);
          yesterday.setDate(yesterday.getDate() - 1);
          setStartDate(yesterday);
          setEndDate(today);
          break;
        case 'last7Days':
          const last7Days = new Date(startOfToday);
          last7Days.setDate(last7Days.getDate() - 6);
          setStartDate(last7Days);
          setEndDate(today);
          break;
        case 'last30Days':
          const last30Days = new Date(startOfToday);
          last30Days.setDate(last30Days.getDate() - 29);
          setStartDate(last30Days);
          setEndDate(today);
          break;
        default:
          break;
      }
      setSelectedPreset(preset)
    };
    // Function to handle dropdown selection
const handlePresetDateSelect = (event) => {
  const preset = event.target.value;
  handlePresetDateRange(preset); // This function should already be defined in your component
};

const toggleChart = (chart) => {
  setShowCharts(prevState => ({ ...prevState, [chart]: !prevState[chart] }));
};



    return (
      <div className="analyticsContainer">


        <div className='analyticsRightContainer'>
          <div className='analyticsNavbarContainer'>
            <snap className ='analyticsNavbarLeftContainer'></snap>
            <div className='analyticsNavbarLeftContainer'> 
            
              {/* This can be used for additional navigation or information */}
            </div>
            <div className='analyticsNavbarRightContainer'>
            
            {/* dropdown for custom charts */}
            <div className="chart-selection">
            <button className="button-style" onClick={() => setShowDropdown(!showDropdown)}> Customize </button>
 

          {showDropdown && (
            <div className="chart-selection-dropdown">
              {Object.keys(showCharts).map((chart) => (
                <div key={chart}>
                  <input
                    type="checkbox"
                    id={chart}
                    checked={showCharts[chart]}
                    onChange={() => toggleChart(chart)}
                  />
                  <label htmlFor={chart}>{chart}</label>
                </div>
              ))}
            </div>
          )}
        </div>
          
                        <div className='analyticsDatePickers'>
                      <div className='analyticsDatePickers'></div>
                      
              
                  <DatePicker
                    selected={startDate}
                    onChange={handleStartDateChange}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                  />
                  <DatePicker
                    selected={endDate}
                    onChange={handleEndDateChange}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                  />
{/* 
                  <DatePicker
                    selected={prevStartDate}
                    onChange={handleprevStartDateChange}
                    selectsStart
                    startDate={prevStartDate}
                    endDate={prevEndDate}
                  />
                  <DatePicker
                    selected={prevEndDate}
                    onChange={handleprevEndDateChange}
                    selectsEnd
                    startDate={prevStartDate}
                    endDate={prevEndDate}
                  /> */}
            
                          {/* Include your apply and cancel buttons here */}
                        </div>
                      </div>
          </div>
          {/* Main content area for charts */ }
          
          <div className="metricsContainer">
          {isLoadingCharts ? (
            <div>Loading charts...</div>  // Display a loading message or spinner
          ) : (
            <>

                  {showCharts.revenue && (
                      <div className="metricCard">
                          <span className='metricCardTitle'>Revenue</span>
                          <span className='metricCardTotal'>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalRevenue)}</span>
                          <LineChart chartData={totalRevenueData} dataType="currency" />
                      </div>
                  )}
                  {showCharts.profit && (
                      <div className="metricCard">
                          <span className='metricCardTitle'>Profit</span>
                          <span className='metricCardTotal'>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalProfit)}</span>
                          <LineChart chartData={totalProfitData} dataType="currency" />
                      </div>
                  )}
                  {showCharts.aov && (
                      <div className="metricCard">
                          <span className='metricCardTitle'>AOV</span>
                          <span className='metricCardTotal'>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalAOV)}</span>
                          <LineChart chartData={averageOrderValueData} dataType="currency" />
                      </div>
                  )}
                  {showCharts.cogs && (
                      <div className="metricCard">
                          <span className='metricCardTitle'>COGS</span>
                          <span className='metricCardTotal'>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalCOGS)}</span>
                          <LineChart chartData={totalCOGSData} dataType="currency" />
                      </div>
                  )}
                  {showCharts.googleAdSpend && (
                      <div className="metricCard">
                          <span className='metricCardTitle'>Google Ad Spend</span>
                          <span className='metricCardTotal'>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalGoogleAdSpend)}</span>
                          <LineChart chartData={googleAdSpend} dataType="currency" />
                      </div>
                  )}
                  {showCharts.googleAdSpend && (
                      <div className="metricCard">
                          <span className='metricCardTitle'>Facebook Ad Spend</span>
                          <span className='metricCardTotal'>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalGoogleAdSpend)}</span>
                          <LineChart chartData={googleAdSpend} dataType="currency" />
                      </div>
                  )}
                  {showCharts.totalOrders && (
                      <div className="metricCard">
                          <span className='metricCardTitle'>Total Order</span>
                          <span className='metricCardTotal'>{new Intl.NumberFormat('en-US', { }).format(totalTotalOrder)}</span>
                          <LineChart chartData={totalOrders} dataType="count" />
                      </div>
                  )}
                  
            </>
          )}

              </div>

        </div>
      </div>
    );
}

export default Analytics;