

import React, { useEffect, useState } from 'react';
import './CreatePreview.css';
import axios from 'axios';
import TextInput from '../../components/TextInput';
import { AddRounded, CloseRounded, DesktopMacRounded, PhoneAndroidRounded, SearchRounded } from '@mui/icons-material';
import FB_Logo_Ext from '../../assets/FB_Logo_Ext.png';


function CreatePreview ({setShowPreview, showPreview}) {

    const [devicePreview, setDevicePreview] = useState('phone')

    return (
        <div className={showPreview ? 'previewContainer' : 'previewContainerHidden'}>
            <div className='previewCloseButton' onClick={() => {setShowPreview(false)}}>
                <CloseRounded/>
            </div>

            <div className='previewContentContainer'>
                <div className='previewDeviceTypeRow'>
                    <div className={devicePreview == 'phone' ? 'previewDeviceTypeButtonSelected' : 'previewDeviceTypeButton'} onClick={() => {setDevicePreview('phone')}}>
                        <PhoneAndroidRounded/>
                    </div>
                    <div className={devicePreview == 'desktop' ? 'previewDeviceTypeButtonSelected' : 'previewDeviceTypeButton'} onClick={() => {setDevicePreview('desktop')}}>
                        <DesktopMacRounded/>
                    </div>
                </div>


                <div className={devicePreview == 'phone' ? 'previewPhoneContainer' : 'previewPhoneContainerHidden'}>
                    <div className='previewFacebookPhoneHeaderContainer'>
                        <div className='previewFacebookPhoneHeaderIOSRow'>
                            <span className='previewFacebookPhoneHeaderIOSTime'>12:00</span>
                            <div className='previewFacebookPhoneHeaderIOSBlackOval'></div>
                            <span className='previewFacebookPhoneHeaderIOSCarrier'></span>
                        </div>
                        <div className='previewFacebookPhoneHeaderRow'>
                            <img src={FB_Logo_Ext} className='previewPhoneFacebookExtendedHeaderLogo'/>
                            <div className='previewFacebookPhoneHeaderRowIconRow'>
                                <div className='previewFacebookPhoneHeaderRowIconButton'><AddRounded/></div> 
                                <div className='previewFacebookPhoneHeaderRowIconButton'><SearchRounded/></div> 
                            </div>
                        </div>
                    </div>
                </div>   

                <div className=''>

                </div>
            
                <div className={devicePreview == 'desktop' ? 'previewDesktopContainer' : 'previewDesktopContainerHidden'}>
                    <div className='previewFacebookDesktopHeaderContainer'>
                        <img src={FB_Logo_Ext} className='previewDesktopFacebookExtLogo'/>
                    </div>
                </div>   
                
            </div>

        
        </div>
    )
}



export default CreatePreview;