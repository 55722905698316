import React from 'react'

function FacebookIcon({complete}) {
    return ( 

        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.58" d="M24 0H0V24H24V0Z" fill="white"/>
            <path opacity="0.4" d="M16.19 2H7.82001C4.18001 2 2.01001 4.17 2.01001 7.81V16.18C2.01001 19.82 4.18001 21.99 7.82001 21.99H16.19C19.83 21.99 22 19.82 22 16.18V7.81C22 4.17 19.83 2 16.19 2Z" fill={complete ? '#05DD74' : '#925CE5'}/>
            <path d="M13.92 9.27995L13.97 12.23L16.6 12.19C16.79 12.19 16.93 12.36 16.9 12.54L16.55 14.45C16.52 14.59 16.4 14.69 16.26 14.7L14.01 14.74L14.13 21.99L11.13 22.04L11.01 14.79L9.30997 14.82C9.13997 14.82 9.00998 14.69 9.00998 14.52L8.97998 12.62C8.97998 12.45 9.10997 12.32 9.27997 12.32L10.98 12.29L10.93 9.03996C10.9 7.37996 12.22 6.01995 13.88 5.98995L16.58 5.94995C16.75 5.94995 16.88 6.07995 16.88 6.24995L16.92 8.64996C16.92 8.81996 16.79 8.94995 16.62 8.94995L14.22 8.98995C14.05 8.97995 13.92 9.11995 13.92 9.27995Z" fill={complete ? '#05DD74' : '#925CE5'}/>
        </svg>

     );
}

export default FacebookIcon;