import './Products.css';
import { getAuth, signOut } from "firebase/auth";
import react, { useState, useEffect } from 'react';
import { collection, doc, getDoc } from 'firebase/firestore';
import {db} from '../FirebaseInit';
import { useNavigate } from 'react-router-dom';
import { LinearProgress } from '@mui/material';
import axios from 'axios';
import NavbarAndDrawer from '../components/NavbarAndDrawer';
import StoreOverview from '../components/StoreOverview';
import { AddRounded, CreateRounded } from '@mui/icons-material';


function Products() {
  const navigate = useNavigate()
  const uid = localStorage.getItem('uid');
  const bid = localStorage.getItem('bid');


  const [allProducts, setAllProducts] = useState([])
  const [lastOrderRefreshTime, setLastOrderRefreshTime] = useState('12:00AM')









    async function loadProducts () {
      try {
        const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/getShopifyProducts?omni_business_id=${bid}` ;
        const response = await axios.get(apiUrl);
        console.log(response.data)
        setAllProducts(response.data)
        setLastOrderRefreshTime(getTime(new Date()))
      } catch (error) {
        console.log('ERROR loading products Products.js', error)
      }
    }

  
      function getTime (date) {
        let hours = date.getHours(); 
        const minutes = date.getMinutes();
        let meridiem = 'AM';
        if (hours === 0) {
          hours = 12; // Convert 0 to 12 for 12 AM
        } else if (hours === 12) {
          meridiem = 'PM'; // Set meridiem to 'PM' for 12 PM
        } else if (hours > 12) {
          hours -= 12; // Convert 24-hour time to 12-hour time for PM
          meridiem = 'PM';
        }
        const paddedMinutes = minutes < 10 ? '0' + minutes : minutes;
        return `${hours}:${paddedMinutes} ${meridiem}`
      }



      useEffect(() => {
        async function start () {
          console.log('running')
          if (uid !== '') {
            loadProducts()
          }
        }
          start()
        const intervalId = setInterval(start, 60000);
        return () => clearInterval(intervalId);
      }, [uid]);

    


  
  
  
    return (

        
    
        <div className='productsRightContainer'>
         


          <StoreOverview long={true} widthPercent={80}/>
        




          <div className='productsOrdersContainer'>
            <table className='productsOrdersTable'>
              <thead>
                <tr>
                  <td></td>
                  <td>Product</td>
                  <td>Ads</td>
                  <td>Inventory</td>
                  <td>Status</td>
                  <td>Variants</td>
                  <td>Price</td>
                  <td>COGS</td>

                </tr>
              </thead>
              <tbody>
                {allProducts.map((x) => { return ( 
                  <tr className='productsOrderRow' key={x.product_id}>
                    <td><img className='productsOrderRowImage' src={x?.img_src}/></td>
                    <td>{x.product_title}</td>
                    <td>2</td>
                    <td>
                      {/*
                      <div style={{width: 60}} className='productsOrderRowInventoryBar'>
                        {
                        x.node.totalInventory > 0 && x.node.totalInventory < 100 ?
                        <div style={{width: ((x.node.totalInventory) / 100) * 60}} className='productsOrderRowInventoryBarRemaining'></div>
                        :
                        <></>
                        }
                        {
                        x.node.totalInventory <= 0  ?
                        <div style={{width: 0}} className='productsOrderRowInventoryBarRemaining'></div>
                        :
                        <></>
                        }
                        {
                        x.node.totalInventory >= 100  ?
                        <div style={{width: 60}} className='productsOrderRowInventoryBarRemaining'></div>
                        :
                        <></>
                        } 

                      </div>
                      <span className='productsOrderRowInventoryCount'>{x.node.totalInventory} in stock</span>
                      */}
                    </td>
                    <td><span className={x.status == 'ACTIVE' ? 'productsOrderRowActive': 'productsOrderRowInactive'}>{x.status}</span></td>
                    {/* <td>{((x.variants).length)}</td>
                    <td>{(x.variants[0].price * 1 || 0)?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td> */}
                    <td className='productsOrderRowRightColumn'>
                      <div className='productsOrderRowRightColumnContainer'>
                      {/* {(x.variants.cogs * 1 || 0)?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} */}
                      </div>
                      <span className='productsOrderRowRightColumnCreateAdButton' onClick={() => {navigate('/Create')}}><AddRounded/></span>
                    </td>
                  </tr>
                )})}
                  <tr className='productsOrderRowBottom'> 
                  <td colSpan={2}>
                      <div className='productsOrderRowBottomLeftButton'>
                        {allProducts.length} Products
                      </div>
                    </td>
                    <td colSpan={5}>
                      <div className='productsOrderRowBottomRight'>
                        Refreshed at {lastOrderRefreshTime}
                      </div>
                    </td>
                  </tr>
              </tbody>
            </table>
          </div>





        </div>
    );
}

export default Products;