import './CreateAd.css';
import { getAuth, signOut } from "firebase/auth";
import react, { useState, useEffect } from 'react';
import { collection, doc, getDoc, onSnapshot, updateDoc } from 'firebase/firestore';
import {db} from '../../FirebaseInit';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import ContentPasteRoundedIcon from '@mui/icons-material/ContentPasteRounded';
import CloseIcon from '@mui/icons-material/Close';
import ProductSearchModal from '../../components/ProductSearchModal'
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import CreateAdSelectProduct from '../../components/Ads/CreateAdStep1';
import CreateAdMakeCreative from '../../components/Ads/CreateAdStep2';
import CreateAdTargeting from '../../components/Ads/CreateAdStep3';
import NavbarAndDrawer from '../../components/NavbarAndDrawer';
import PageHeader from '../../components/PageHeader';
import axios from 'axios';

function CreateAd() {
  const navigate = useNavigate()

  const [selectedProduct, setSelectedProduct] = useState('');
  const [draftID, setDraftID] = useState('')
  const [loadingDraft, setLoadingDraft] = useState(true);
  const [advantagePlusCreative, setAdvantagePlusCreative] = useState(false)
  const [fb_PrimaryTextOptions, setfb_PrimaryTextOptions] = useState('')
  const [fb_HeadlineTextOptions, setfb_HeadlineTextOptions] = useState('')
  const [fb_DescriptionTextOptions, setfb_DescriptionTextOptions] = useState('')
  const [fb_PrimaryTextSelected, setfb_PrimaryTextSelected] = useState([])
  const [fb_HeadlineTextSelected, setfb_HeadlineTextSelected] = useState([])
  const [fb_DescriptionTextSelected, setfb_DescriptionTextSelected] = useState([])
  const [fb_CTA, setfb_CTA] = useState('')

  const [fb_InterestSelected, setfb_InterestSelected] = useState('')
  const [fb_VerifiedInterests, setfb_VerifiedInterests] = useState([])
  const [fb_LocationSelected, setfb_LocationSelected] = useState([])
  const [fb_LocationSuggestions, setfb_LocationSuggestions] = useState([])
  const [fb_Budget, setfb_Budget] = useState('')
  const [fb_Age, setfb_Age] = useState('')
  const [fb_Gender, setfb_Gender] = useState('')
  const [fb_StartAtDate, setfb_StartAtDate] = useState('')
  const [fb_StartAtTime, setfb_StartAtTime] = useState('')
  const [fb_AdAccount, setfb_AdAccount] = useState('')
  const [fb_Pixel, setfb_Pixel] = useState('')
  const [fb_Page, setfb_Page] = useState('')

  const [shopifyMedia, setShopifyMedia] = useState([]);
  const [omniCloudMedia, setOmniCloudMedia] = useState([])
  const [facebookMedia, setFacebookMedia] = useState([])
  const [generateMoreTextOptionsLoading, setGenerateMoreTextOptionsLoading] = useState(false)
  const [selectedMedia, setSelectedMedia] = useState([])

  const [selectedInterest, setSelectedInterest] = useState('')
  const [selectedLocation, setSelectedLocation] = useState('')
  const [selectedBudget, setSelectedBudget] = useState('');
  const [selectedMinAge, setSelectedMinAge] = useState('')
  const [selectedMaxAge, setSelectedMaxAge] = useState('')
  const [selectedGender, setSelectedGender] = useState('')
  const [selectedStartAtDate, setSelectedStartAtDate] = useState('')
  const [selectedStartAtTime, setSelectedStartAtTime] = useState('')
  const [selectedFBAdAccount, setSelectedFBAdAccount] = useState('')
  const [selectedFBPixel, setSelectedFBPixel] = useState('')
  const [selectedFBPage, setSelectedFBPage] = useState('')


  const uid = localStorage.getItem('uid');
  const bid = localStorage.getItem('bid');


  const startDate = new Date().toISOString().split('T')[0];;
  const endDate = new Date().toISOString().split('T')[0];;

  console.log(fb_PrimaryTextOptions)

  console.log(selectedProduct)
  useEffect(() => {
    if (selectedProduct != '') {
      createDraft();
      getFilesByProductID();
    }
  }, [selectedProduct])


  async function getFilesByProductID () {
    try {
      const apiUrl = `http://${process.env.REACT_APP_API_BASE_URL}/getFilesByProductID?product_id=${selectedProduct.product_id}&omni_business_id=${bid}` ;
      const response = await axios.get(apiUrl);
      console.log(response.data)
      setOmniCloudMedia(response.data)
    } catch (error) {
      console.log('ERROR loading products Products.js', error)
    }
  }

  async function generateMoreTextOptions(type) {
    try {
      setGenerateMoreTextOptionsLoading(true)
      const apiUrl = `http://${process.env.REACT_APP_API_BASE_URL}/generateMoreTextOptions`;
      const data = {
        options: fb_PrimaryTextOptions,
        type: type,
        draftID: draftID
      }
      const response = await axios.post(apiUrl, data);
      console.log(response.data)
      setGenerateMoreTextOptionsLoading(false)
      // setfb_PrimaryTextOptions(response.data.primaryTextOptions)
      // setfb_HeadlineTextOptions(response.data.headlineTextOptions)
      // setfb_DescriptionTextOptions(response.data.descriptionTextOptions)
    }
    catch (error) {
      console.log('ERROR loading products Products.js', error)
      setGenerateMoreTextOptionsLoading(false)
    }
  }


  async function createDraft() {
    try {
      const data = {
        product: selectedProduct,
      }
      const apiUrl = `http://${process.env.REACT_APP_API_BASE_URL}/createDraft?uid=${uid}&product_id=${selectedProduct.id}&scrape=${false}`;
      console.log(apiUrl);
      const startTime = performance.now();
      const response = await axios.post(apiUrl, data)

      const endTime = performance.now();
      const timeTaken = endTime - startTime;
      console.log('Time taken to get response:', timeTaken, 'milliseconds');
      setDraftID(response.data.draftID);

    } catch (error) {
      console.error('createAd.js: ERROR create draft', error);
    }

  }
  


  useEffect(() => {
    if (draftID != '') {
      getDraft()
    } else {
      setShopifyMedia([])
      setfb_PrimaryTextOptions([]);
      setfb_HeadlineTextOptions([]);
      setfb_DescriptionTextOptions([]);
      setfb_CTA('');
      setfb_InterestSelected('');
      setfb_VerifiedInterests([]);
      setfb_LocationSelected([]);
      setfb_LocationSuggestions([]);
      setfb_Budget('');
      setfb_Age([])
      setfb_Gender('')
      setfb_StartAtDate('')
      setfb_StartAtTime('')
      setfb_AdAccount('')
      setfb_Pixel('')
      setfb_Page('')

    }
  }, [draftID])

    

  async function getDraft () {
    const docRef = doc(db, 'drafts', draftID);
    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        //console.log('Document data:', docSnap.data());
        setShopifyMedia(docSnap.data().media);
        setfb_PrimaryTextOptions(docSnap.data().fb_PrimaryTextOptions);
        setfb_HeadlineTextOptions(docSnap.data().fb_HeadlineTextOptions);
        setfb_DescriptionTextOptions(docSnap.data().fb_DescriptionTextOptions);
        setfb_CTA(docSnap.data().fb_CTA);

        setfb_InterestSelected(docSnap.data().fb_InterestSelected);
        setfb_VerifiedInterests(docSnap.data().fb_VerifiedInterests || []);
        setfb_LocationSelected(docSnap.data().fb_LocationSelected || []);
        setfb_LocationSuggestions(docSnap.data().fb_LocationSuggestions || []);
        setfb_Budget(docSnap.data().fb_Budget || [])
        setfb_Age(docSnap.data().fb_Age || [])
        setfb_Gender(docSnap.data().fb_Gender || [])
        setfb_StartAtDate(docSnap.data().fb_StartAtDate || [])
        setfb_StartAtTime(docSnap.data().fb_StartAtTime || [])
        setfb_AdAccount(docSnap.data().fb_AdAccount || [])
        setfb_Pixel(docSnap.data().fb_Pixel || [])
        setfb_Page(docSnap.data().fb_Page || [])

        if (loadingDraft) {
          setfb_PrimaryTextSelected(docSnap.data().fb_PrimaryTextOptions)
          setfb_HeadlineTextSelected(docSnap.data().fb_HeadlineTextOptions)
          setfb_DescriptionTextSelected(docSnap.data().fb_DescriptionTextOptions)
        }

        setLoadingDraft(false)
      } else {
        console.log('No such document!');
        setLoadingDraft(false)
      }
    })
    
  }

  console.log(shopifyMedia)


  



  async function createFacebookAd () {
    try {
      //await updateDraft();
      const apiUrl = `http://${process.env.REACT_APP_API_BASE_URL}/createFacebookAd`;
      const data = {
        draftID: draftID,
        uid: uid,
      }
      const response = await axios.post(apiUrl, data);
      console.log(response.data)
    } catch (error) {
      console.error('createAd.js: ERROR create draft', error);
    }
  }




  async function updatePrimaryTextSelection () {
    const draftRef = doc(db, "drafts", draftID);
    await updateDoc(draftRef, {
      fb_PrimaryTextSelected: fb_PrimaryTextSelected,
    });
  }
  useEffect(() => {
    if (fb_PrimaryTextSelected != '' && draftID != '') {
      updatePrimaryTextSelection();
      console.log('updating primary text selection')
    }
  }, [fb_PrimaryTextSelected])

  async function updateHeadlineTextSelection () {
    const draftRef = doc(db, "drafts", draftID);
    await updateDoc(draftRef, {
      fb_HeadlineTextSelected: fb_HeadlineTextSelected,
    });
  }
  useEffect(() => {
    if (fb_HeadlineTextSelected != '' && draftID != '') {
      updateHeadlineTextSelection();
      console.log('updating headline text selection')
    }
  }, [fb_HeadlineTextSelected])

  async function updateDescriptionTextSelection () {
    const draftRef = doc(db, "drafts", draftID);
    await updateDoc(draftRef, {
      fb_DescriptionTextSelected: fb_DescriptionTextSelected,
    });
  }
  useEffect(() => {
    if (fb_DescriptionTextSelected != '' && draftID != '') {
      updateDescriptionTextSelection();
      console.log('updating description text selection')
    }
  }, [fb_DescriptionTextSelected])

  async function updateInterest () {
    const draftRef = doc(db, "drafts", draftID);
    await updateDoc(draftRef, {
      fb_InterestSelected: selectedInterest,
    });
  }
  useEffect(() => {
    if (selectedInterest != '' && draftID != '') {
      updateInterest();
      console.log('updating Interest')
    }
  }, [selectedInterest])

  async function updateLocations () {
    const draftRef = doc(db, "drafts", draftID);
    await updateDoc(draftRef, {
      fb_LocationSelected: selectedLocation,
    });
  }
  useEffect(() => {
    if (selectedLocation != '' && draftID != '') {
      updateLocations();
      console.log('updating Location')
    }
  }, [selectedLocation])

  async function updateBudget () {
    const draftRef = doc(db, "drafts", draftID);
    await updateDoc(draftRef, {
      fb_Budget: selectedBudget,
    });
  }
  useEffect(() => {
    if (selectedBudget != '' && draftID != '') {
      updateBudget();
      console.log('updating Budget')
    }
  }, [selectedBudget])

  async function updateAge () {
    const draftRef = doc(db, "drafts", draftID);
    await updateDoc(draftRef, {
      fb_Age: [selectedMinAge, selectedMaxAge],
    });
  }
  useEffect(() => {
    if (selectedMinAge != '' && selectedMaxAge != '' && draftID != '') {
      updateAge();
      console.log('updating Age')
    }
  }, [selectedMinAge, selectedMaxAge])

  async function updateGender () {
    const draftRef = doc(db, "drafts", draftID);
    await updateDoc(draftRef, {
      fb_Gender: selectedGender,
    });
  }
  useEffect(() => {
    if (selectedGender != '' && draftID != '') {
      updateGender();
      console.log('updating Gender')
    }
  }, [selectedGender])

  async function updateStartAtDate () {
    const draftRef = doc(db, "drafts", draftID);
    await updateDoc(draftRef, {
      fb_StartAtDate: selectedStartAtDate,
    });
  }
  useEffect(() => {
    if (selectedStartAtDate != '' && draftID != '') {
      updateStartAtDate();
      console.log('updating Start Date')
    }
  }, [selectedStartAtDate])

  async function updateStartAtTime () {
    const draftRef = doc(db, "drafts", draftID);
    await updateDoc(draftRef, {
      fb_StartAtTime: selectedStartAtTime,
    });
  }
  useEffect(() => {
    if (selectedStartAtTime != '' && draftID != '') {
      updateStartAtTime();
      console.log('updating Start Time')
    }
  }, [selectedStartAtTime])

  async function updateAdAccount () {
    const draftRef = doc(db, "drafts", draftID);
    await updateDoc(draftRef, {
      fb_AdAccount: selectedFBAdAccount,
    });
  }
  useEffect(() => {
    if (selectedFBAdAccount != '' && draftID != '') {
      updateAdAccount();
      console.log('updating ad account')
    }
  }, [selectedFBAdAccount])

  async function updatePixel () {
    const draftRef = doc(db, "drafts", draftID);
    await updateDoc(draftRef, {
      fb_Pixel: selectedFBPixel,
    });
  }
  useEffect(() => {
    if (selectedFBPixel != '' && draftID != '') {
      updatePixel();
      console.log('updating pixel')
    }
  }, [selectedFBPixel])

  async function updatePage () {
    const draftRef = doc(db, "drafts", draftID);
    await updateDoc(draftRef, {
      fb_Page: selectedFBPage,
    });
  }
  useEffect(() => {
    if (selectedFBPage != '' && draftID != '') {
      updatePage();
      console.log('updating page')
    }
  }, [selectedFBPage])






  
return (
  
  <NavbarAndDrawer collapsed={true}>
    <div className="createAdContainer">
      <div className='createAdContentContainer'>
        
        <PageHeader title='Create' grandparent='Marketing' parent='Ads Manager' child='Create'/>

            {/* SHOW AD PERCENT COMPLETED */}



        <CreateAdSelectProduct 
          selectedProduct={selectedProduct} 
          setSelectedProduct={setSelectedProduct}
          setDraftID={setDraftID}
          advantagePlusCreative={advantagePlusCreative}
          setAdvantagePlusCreative={setAdvantagePlusCreative}
        />
        <CreateAdMakeCreative 
          selectedProduct={selectedProduct} 
          setSelectedProduct={setSelectedProduct} 
          shopifyMedia={shopifyMedia} 
          setShopifyMedia={setShopifyMedia} 
          omniCloudMedia={omniCloudMedia}
          setOmniCloudMedia={setOmniCloudMedia}
          facebookMedia={facebookMedia}
          setFacebookMedia={setFacebookMedia}
          draftID={draftID}   
          fb_PrimaryTextOptions={fb_PrimaryTextOptions}
          fb_HeadlineTextOptions={fb_HeadlineTextOptions}
          fb_DescriptionTextOptions={fb_DescriptionTextOptions}
          fb_PrimaryTextSelected={fb_PrimaryTextSelected}
          setfb_PrimaryTextSelected={setfb_PrimaryTextSelected}
          fb_HeadlineTextSelected={fb_HeadlineTextSelected}
          setfb_HeadlineTextSelected={setfb_HeadlineTextSelected}
          fb_DescriptionTextSelected={fb_DescriptionTextSelected}
          setfb_DescriptionTextSelected={setfb_DescriptionTextSelected}
          fb_CTA={fb_CTA}
          generateMoreTextOptions={generateMoreTextOptions}
          generateMoreTextOptionsLoading={generateMoreTextOptionsLoading}
          setGenerateMoreTextOptionsLoading={setGenerateMoreTextOptionsLoading}
          selectedMedia={selectedMedia} 
          setSelectedMedia={setSelectedMedia}
        />
        <CreateAdTargeting 
          selectedProduct={selectedProduct} 
          setSelectedProduct={setSelectedProduct}
          draftID={draftID}
          fb_InterestSelected={fb_InterestSelected}
          fb_VerifiedInterests={fb_VerifiedInterests}
          fb_LocationSelected={fb_LocationSelected} 
          fb_LocationSuggestions={fb_LocationSuggestions}
          fb_Budget={fb_Budget}
          fb_Age={fb_Age}
          fb_Gender={fb_Gender}
          fb_StartAtDate={fb_StartAtDate}
          fb_StartAtTime={fb_StartAtTime}
          fb_AdAccount={fb_AdAccount}
          fb_Pixel={fb_Pixel}
          fb_Page={fb_Page}
          createFacebookAd={createFacebookAd}
          selectedInterest={selectedInterest}
          setSelectedInterest={setSelectedInterest}
          selectedLocation={selectedLocation}
          setSelectedLocation={setSelectedLocation}
          selectedBudget={selectedBudget}
          setSelectedBudget={setSelectedBudget}
          selectedMinAge={selectedMinAge}
          setSelectedMinAge={setSelectedMinAge}
          selectedMaxAge={selectedMaxAge}
          setSelectedMaxAge={setSelectedMaxAge}
          selectedGender={selectedGender}
          setSelectedGender={setSelectedGender}
          selectedStartAtDate={selectedStartAtDate}
          setSelectedStartAtDate={setSelectedStartAtDate}
          selectedStartAtTime={selectedStartAtTime}
          setSelectedStartAtTime={setSelectedStartAtTime}
          selectedFBAdAccount={selectedFBAdAccount}
          setSelectedFBAdAccount={setSelectedFBAdAccount}
          selectedFBPixel={selectedFBPixel}
          setSelectedFBPixel={setSelectedFBPixel}
          selectedFBPage={selectedFBPage}
          setSelectedFBPage={setSelectedFBPage}

        />
        

      </div>

      </div>
    </NavbarAndDrawer>
  );
}

export default CreateAd;