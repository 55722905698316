import React, { useState, useEffect } from 'react';
import { Drawer, Box, Typography, TextField, Button, Select, MenuItem, FormControl, InputLabel, Grid, Paper, styled } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Paid, Timer, PauseCircle, CheckCircle, EditRounded, CancelRounded, KeyboardArrowRightRounded, ChevronRightRounded, Check } from '@mui/icons-material';
import { Alert, AlertTitle, IconButton } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import ImpressionData from './ImpressionData';
import IconBox from '../../../../components/IconBox';
import './CampaignDrawer.css'
import axios from 'axios';
import TextInput from '../../../../components/TextInput';
import ChartComponent from '../../../../components/Charts/Example.js';



const CustomDrawer = ({ showDrawer, setShowDrawer, selectedCampaign, setSelectedCampaign, omniBusinessId, serverURL, selectedAdLevel }) => {

    const [isEditing, setIsEditing] = useState(false);
    const [alert, setAlert] = useState({ open: false, severity: '', message: '' });
    const [loading, setLoading] = useState(false);

    const [updatedName, setUpdatedName] = useState('');
    const [updatedStatus, setUpdatedStatus] = useState('');
    const [updatedDailyBudget, setUpdatedDailyBudget] = useState('');
    const [updatedTargetingAgeMin, setUpdatedTargetingAgeMin] = useState('');
    const [updatedTargetingAgeMax, setUpdatedTargetingAgeMax] = useState('');



    useEffect(() => {
        setUpdatedName(selectedCampaign?.name || '');
        setUpdatedStatus(selectedCampaign?.status || '');
        setUpdatedDailyBudget(selectedCampaign?.daily_budget || '');
        setUpdatedTargetingAgeMin(selectedCampaign?.targeting_age_min || '');
        setUpdatedTargetingAgeMax(selectedCampaign?.targeting_age_max || '');

    }, [selectedCampaign]);

    console.log(selectedCampaign, 'selectedCampaign')



    const handleSubmit = async (e) => {
        setLoading(true)

       
        if(!omniBusinessId){
            setAlert({
                success: false,
                message: 'No Ad Account Selected',
            });
            return;
        }

        try {

            let response, updatedCampaign, fb_response, updatedFBCampaign, updateadSetsData;


            const data = {
                id: selectedCampaign.campaign_id,
                name: updatedName,
                status: updatedStatus,
                daily_budget: updatedDailyBudget,
                targeting_age_min: updatedTargetingAgeMin,
                targeting_age_max: updatedTargetingAgeMax,
                omni_business_id: omniBusinessId,
            };

            console.log(data)
            


            if (selectedAdLevel === 0) {
                try {
                    response = await axios.post(`${serverURL}/updateCampaignData`, data);
                    console.log(response, 'response')
            
                    updatedCampaign = response.data;
                    //setSelectedCampaign(updatedCampaign);
                    setAlert({
                        success: true,
                        message: 'Campaign updated successfully!',
                    });
            
                    // Facebook update code
                    fb_response = await axios.post(`${serverURL}/UpdateFacebookCampaignsData`, data);
                    console.log(response, 'response')

                    updatedFBCampaign = fb_response.data;
                    //setSelectedCampaign(updatedFBCampaign);
                    setAlert({
                        success: true,
                        message: 'Campaign updated successfully on Facebook!',
                    });
                } catch (error) {
                    throw new Error('Network response was not ok');
                }
            } else if (selectedAdLevel === 1) {
                try {
                    response = await axios.post(`${serverURL}/updateadSetsData`, data );
            
                    updateadSetsData = response.data;
                    //setSelectedCampaign(updateadSetsData);
                    setAlert({
                        success: true,
                        message: 'Ad Sets updated successfully!',
                    });
            
                    // Facebook
                    fb_response = await axios.post(`${serverURL}/UpdateFacebookAdsetsData`, data);
            
                    updatedFBCampaign = fb_response.data;
                    //setSelectedCampaign(updatedFBCampaign);
                    setAlert({
                        success: true,
                        message: 'Campaign updated successfully on Facebook!',
                    });
                } catch (error) {
                    throw new Error('Network response was not ok');
                }
            } else if (selectedAdLevel === 2) {
                try {
                    response = await axios.post(`${serverURL}/updateAdsData`, data);
            
                    updateadSetsData = response.data;
                    //setSelectedCampaign(updateadSetsData);
                    setAlert({
                        success: true,
                        message: 'Ad Sets updated successfully!',
                    });
            
                    // Facebook
                    fb_response = await axios.post(`${serverURL}/UpdateFacebookAdsData`, data);
            
                    updatedFBCampaign = fb_response.data;
                    //setSelectedCampaign(updatedFBCampaign);
                    setAlert({
                        success: true,
                        message: 'Campaign updated successfully on Facebook!',
                    });
                } catch (error) {
                    throw new Error('Network response was not ok');
                }
            }
            


        } catch (error) {
            console.error('Error updating campaign:', error);
            setAlert({
                success: false,
                message: 'Error updating data',
            });
        } finally {
            setLoading(false);
        }
    };

    const getCurrentDateTime = () => {
        const currentDateTime = new Date();
        const year = currentDateTime.getFullYear();
        const month = String(currentDateTime.getMonth() + 1).padStart(2, '0');
        const day = String(currentDateTime.getDate()).padStart(2, '0');
        const hours = String(currentDateTime.getHours()).padStart(2, '0');
        const minutes = String(currentDateTime.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };
    const minDateTime = getCurrentDateTime();

    return (
        <div className={`CampaignDrawerRightPane ${showDrawer ? 'open' : ''}`}>

    
                {/* Header with campaign name, edit button, and close icon */}
            
    
                <div onClick={() => setIsEditing(!isEditing)}>
                    <div className='CampaignDrawerRightPaneHeaderContainer'>
                        <span className='CampaignDrawerRightPaneHeaderCollapseIcon' onClick={() => { setShowDrawer(false) }}>
                            <ChevronRightRounded />
                        </span>
                        <span className='CampaignDrawerRightPaneHeaderTitle'>{selectedCampaign?.name || 'Campaign Name'}</span>
                        <span className='CampaignDrawerRightPaneHeaderCollapseIcon'  onClick={() => {
                                if (isEditing) {
                                    handleSubmit();
                                    setIsEditing(!isEditing);
                                } else {
                                    setIsEditing(!isEditing);
                                }
                            }}>
                            {isEditing ? <Check/> : <EditRounded />}
                        </span>
                    </div>
                </div>


                <div className='CampaignDrawerTopSection'>



                    {!isEditing ? (
                        <>
                            
                            <ChartComponent />
   
                                {/* <ImpressionData campaignData={selectedCampaign} /> */}
                        </>

                    ) : (
                        <div>
                            {alert.open && (
                                <div className='CampaignDrawerAlertContainer'>
                                    <span className='CampaignDrawerAlertStatus'>{alert.status ? "Success" : "Error"}</span>
                                    <span className='CampaignDrawerAlertTitle'>{alert.message}</span>
                                </div>
                            )}

                            <TextInput label="Name" value={updatedName} onChange={setUpdatedName} />

                            <TextInput label="Status" value={updatedStatus} onChange={setUpdatedStatus} />

                            {(selectedAdLevel === 0 || selectedAdLevel === 1) && (
                                <TextInput label="Daily Budget" value={updatedDailyBudget} onChange={setUpdatedDailyBudget} />
                            )}

                            {selectedAdLevel === 1 && (
                                <>
                                    <TextInput label="Targeting Age Min" value={updatedTargetingAgeMin} onChange={setUpdatedTargetingAgeMin} />
                                    <TextInput label="Targeting Age Max" value={updatedTargetingAgeMax} onChange={setUpdatedTargetingAgeMax} />
                                </>
                            )}
                                
                            


                        </div>
                    )}
                
                </div>
        </div>
    );
};

export default CustomDrawer;