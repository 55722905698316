import react, { useState, useEffect } from 'react';
import axios from 'axios';
import './StoreOverview.css'

function StoreOverview({long, widthPercent}) {

    const uid = localStorage.getItem('uid');

    const [orders, setOrders] = useState(localStorage.getItem('shopifyOverview_orders'))
    const [revenue, setRevenue] = useState(localStorage.getItem('shopifyOverview_revenue'))
    const [facebookSpend, setFacebookSpend] = useState(localStorage.getItem('shopifyOverview_facebookSpend'))
    const [cogs, setCogs] = useState(localStorage.getItem('shopifyOverview_cogs'))
    const [aov, setAov] = useState(localStorage.getItem('shopifyOverview_aov'))
    const [profit, setprofit] = useState(localStorage.getItem('shopifyOverview_profit'))
    const [facebookClicks, setFacebookClicks] = useState(localStorage.getItem('shopifyOverview_facebookClicks'))
    const [facebookImpressions, setFacebookImpressions] = useState(localStorage.getItem('shopifyOverview_facebookImpressions'))


    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const startDate = `${year}-${month}-${day}`;
    const endDate = `${year}-${month}-${day}`;

    async function loadStats () {
        try {
          console.log('loading stats ShopifyOverview.js')
          const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/getShopifyOverview?uid=${uid}&dateStart=${startDate}&dateEnd=${endDate}` ;
          const response = await axios.get(apiUrl)
          setOrders(response.data.orders)
          setRevenue(response.data.sales)
          setFacebookSpend(response.data.facebookSpend)
          setCogs(response.data.cogs)
          setAov(response.data.aov)
          setprofit(response.data.profit)
          setFacebookClicks(response.data.facebookClicks)
          setFacebookImpressions(response.data.facebookImpressions)
          localStorage.setItem('shopifyOverview_orders', response.data.orders?.toLocaleString('en-US'))
          localStorage.setItem('shopifyOverview_revenue', response.data.sales?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }))
          localStorage.setItem('shopifyOverview_facebookSpend', response.data.facebookSpend?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }))
          localStorage.setItem('shopifyOverview_cogs', response.data.cogs?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }))
          localStorage.setItem('shopifyOverview_aov', response.data.aov?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }))
          localStorage.setItem('shopifyOverview_profit', response.data.profit?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }))
          localStorage.setItem('shopifyOverview_facebookClicks', response.data.facebookClicks?.toLocaleString('en-US'))
          localStorage.setItem('shopifyOverview_facebookImpressions', response.data.facebookImpressions?.toLocaleString('en-US'))
        } catch (error) {
          console.log('ERROR loading stats ShopifyOverview.js', error)
        }
    }

    useEffect(() => {
        async function start () {
          console.log('running')
          if (uid !== '') {
            loadStats();
          }
        }
          start()
        const intervalId = setInterval(start, 60000);
        return () => clearInterval(intervalId);
      }, [uid]);

      console.log(long)

    return ( 
        
        <div className='StoreOverviewAnalyticsContainer' style={{width: widthPercent + '%'}}>
            <div className='StoreOverviewStatisticsCardRow'>

              <div className='StoreOverviewStatisticsCard'>
                <span className='StoreOverviewStatisticsCardAmount'>{orders?.toLocaleString('en-US')}</span>
                <span className='StoreOverviewStatisticsCardTitle'>Orders</span>
              </div>

              <div className='StoreOverviewStatisticsCard'>
                <span className='StoreOverviewStatisticsCardAmount'>{revenue?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>
                <span className='StoreOverviewStatisticsCardTitle'>Sales</span>
              </div>

              <div className='StoreOverviewStatisticsCard'>
                <span className='StoreOverviewStatisticsCardAmount'>{facebookSpend?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>
                <span className='StoreOverviewStatisticsCardTitle'>Ads</span>
              </div>

              <div className='StoreOverviewStatisticsCard'>
                <span className='StoreOverviewStatisticsCardAmount'>{cogs?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>
                <span className='StoreOverviewStatisticsCardTitle'>COGS</span>
              </div>

              <div className='StoreOverviewStatisticsCard'>
                <span className='StoreOverviewStatisticsCardAmount'>{profit?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>
                <span className='StoreOverviewStatisticsCardTitle'>Profit</span>
              </div>

              {long && (
                <>
                    <div className='StoreOverviewStatisticsCard'>
                        <span className='StoreOverviewStatisticsCardAmount'>{aov?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>
                        <span className='StoreOverviewStatisticsCardTitle'>AOV</span>
                    </div>

                    <div className='StoreOverviewStatisticsCard'>
                        <span className='StoreOverviewStatisticsCardAmount'>{facebookClicks?.toLocaleString('en-US')}</span>
                        <span className='StoreOverviewStatisticsCardTitle'>Clicks</span>
                    </div>

                    <div className='StoreOverviewStatisticsCard'>
                        <span className='StoreOverviewStatisticsCardAmount'>{facebookImpressions?.toLocaleString('en-US')}</span>
                        <span className='StoreOverviewStatisticsCardTitle'>Impressions</span>
                    </div>
                </>
              )}

            </div>
        </div>
        
    );
}

export default StoreOverview;