import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

import './DateSelector.css';




function DateSelector({setShowDateSelectors, showDateSelectors, dateStart, setDateStart, dateEnd, setDateEnd,}) {

    const [selectedPreset, setSelectedPreset] = useState(null);



    function determineDates(selectedPreset) {
        let start, end;
        const today = dayjs().startOf('day');
        switch (selectedPreset) {
            case 'today':
                start = today;
                end = today;
                break;
            case 'yesterday':
                start = today.subtract(1, 'day');
                end = today.subtract(1, 'day');
                break;
            case 'last7days':
                start = today.subtract(6, 'day');
                end = today;
                break;
            case 'last30days':
                start = today.subtract(29, 'day');
                end = today;
                break;
            case 'thismonth':
                start = today.startOf('month');
                end = today.endOf('month');
                break;
            case 'lastmonth':
                start = today.subtract(1, 'month').startOf('month');
                end = today.subtract(1, 'month').endOf('month');
                break;
            case 'last90days':
                start = today.subtract(89, 'day');
                end = today;
                break;
            case 'thisyear':
                start = today.startOf('year');
                end = today.endOf('year');
                break;
            case 'lastyear':
                start = today.subtract(1, 'year').startOf('year');
                end = today.subtract(1, 'year').endOf('year');
                break;
            default:
                start = null;
                end = null;
                break;
        }
        setDateStart(start);
        setDateEnd(end);
    }
    

    console.log(showDateSelectors)





    return (

        <div className='DateSelectorBackground' >


            <div className='DateSelectorCard'>

                <div className='DateSelectorPresetContainer'>
                    <span className={selectedPreset == 'today' ? 'DateSelectorPresetSelected' : 'DateSelectorPreset'} onClick={() => {determineDates('today'); setSelectedPreset('today')}}>Today</span>
                    <span className={selectedPreset == 'yesterday' ? 'DateSelectorPresetSelected' : 'DateSelectorPreset'} onClick={() => {determineDates('yesterday'); setSelectedPreset('yesterday')}}>Yesterday</span>
                    <span className={selectedPreset == 'last7days' ? 'DateSelectorPresetSelected' : 'DateSelectorPreset'} onClick={() => {determineDates('last7days'); setSelectedPreset('last7days')}}>Last 7 Days</span>
                    <span className={selectedPreset == 'last30days' ? 'DateSelectorPresetSelected' : 'DateSelectorPreset'} onClick={() => {determineDates('last30days'); setSelectedPreset('last30days')}}>Last 30 Days</span>
                    <span className={selectedPreset == 'thismonth' ? 'DateSelectorPresetSelected' : 'DateSelectorPreset'} onClick={() => {determineDates('thismonth'); setSelectedPreset('thismonth')}}>This Month</span>
                    <span className={selectedPreset == 'lastmonth' ? 'DateSelectorPresetSelected' : 'DateSelectorPreset'} onClick={() => {determineDates('lastmonth'); setSelectedPreset('lastmonth')}}>Last Month</span>
                    <span className={selectedPreset == 'last90days' ? 'DateSelectorPresetSelected' : 'DateSelectorPreset'} onClick={() => {determineDates('last90days'); setSelectedPreset('last90days')}}>Last 90 Days</span>
                    <span className={selectedPreset == 'thisyear' ? 'DateSelectorPresetSelected' : 'DateSelectorPreset'} onClick={() => {determineDates('thisyear'); setSelectedPreset('thisyear')}}>This Year</span>
                    <span className={selectedPreset == 'lastyear' ? 'DateSelectorPresetSelected' : 'DateSelectorPreset'} onClick={() => {determineDates('lastyear'); setSelectedPreset('lastyear')}}>Last Year</span>
                </div>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div className='DateSelectorsContainer'>
                        <DateCalendar value={dateStart} onChange={(newValue) => setDateStart(newValue)} maxDate={dateEnd}/>
                        <DateCalendar value={dateEnd} onChange={(newValue) => setDateEnd(newValue)} minDate={dateStart}/>
                    </div>
                </LocalizationProvider>
                
            </div>


            <div className='DateSelectorSubmitButtonContainer'>
                <span className='DateSelectorSubmitButton' onClick={() => {setShowDateSelectors(false); console.log(dateStart)}}> Query Dates </span>
            </div>


        </div>



    );
}

export default DateSelector;
