import './Interests.css';
import { getAuth, signOut } from "firebase/auth";
import react, { useState, useEffect } from 'react';
import { collection, doc, getDoc } from 'firebase/firestore';
import {db} from '../FirebaseInit';
import { useNavigate } from 'react-router-dom';
import NavbarAndDrawer from '../components/NavbarAndDrawer';


function Interests() {
  const navigate = useNavigate()


  const [orders, setOrders] = useState('Loading')
  const [revenue, setRevenue] = useState('Loading')
  const [facebookSpend, setFacebookSpend] = useState('Loading')
  const [cogs, setCogs] = useState('Loading')
  const [aov, setAov] = useState('Loading')
  const [profit, setprofit] = useState('Loading')
  const [facebookClicks, setFacebookClicks] = useState('Loading')
  const [facebookImpressions, setFacebookImpressions] = useState('Loading')
  const [allOrders, setAllOrders] = useState([])
  const [lastOrderRefreshTime, setLastOrderRefreshTime] = useState('1:03PM')
  const uid = localStorage.getItem('uid');

  const startDate = new Date().toISOString().split('T')[0];;
  const endDate = new Date().toISOString().split('T')[0];;








    useEffect(() => {
      async function start () {
        console.log('running')
        if (uid !== '') {
        
        }
      }
        start()
      // Run the function every 60 seconds
      const intervalId = setInterval(start, 60000);

      // Clean up the interval when the component unmounts or when uid changes
      return () => clearInterval(intervalId);
    }, [uid]);



    async function logOut () {
      const auth = getAuth();
      signOut(auth).then(() => {
        // Sign-out successful.
      }).catch((error) => {
        // An error happened.
      });
    }
  
  

     function displayDate (date) {
      //const date = new Date(); 
      const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      const dayOfWeek = days[date.getDay()];
      const month = months[date.getMonth()];
      const dayOfMonth = date.getDate();
      const year = date.getFullYear();
      const formattedDate = `${dayOfWeek} ${month} ${dayOfMonth}, ${year}`;
      //console.log(formattedDate)
      return formattedDate;
    }

    function getTime (date) {
      let hours = date.getHours(); 
      const minutes = date.getMinutes();
      let meridiem = 'AM';
      if (hours === 0) {
        hours = 12; // Convert 0 to 12 for 12 AM
      } else if (hours === 12) {
        meridiem = 'PM'; // Set meridiem to 'PM' for 12 PM
      } else if (hours > 12) {
        hours -= 12; // Convert 24-hour time to 12-hour time for PM
        meridiem = 'PM';
      }
      const paddedMinutes = minutes < 10 ? '0' + minutes : minutes;
      return `${hours}:${paddedMinutes} ${meridiem}`
    }

  
  
  
    return (
        <div className="interestsContainer">
     


        
      


       
        </div>
    );
}

export default Interests;