import '../App.css';
import { getAuth, signInWithEmailAndPassword ,signInWithCustomToken} from "firebase/auth";
import react, { useState,useEffect } from 'react';
import { collection, doc, getDoc } from 'firebase/firestore';
import {db} from '../FirebaseInit';
import { get } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import OmniLoginImage from '../assets/OmniLogin.png';


function LogIn({user_token}) {
  const navigate = useNavigate()

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [userDetails, setUserDetails] = useState(null);

    useEffect(()=>{
      if(!user_token) return;
        const fetchData = async () => {
          const auth = getAuth();
          signInWithCustomToken(auth, user_token)
          .then((userCredential) => {
            // User signed in successfully
            const user = userCredential.user;
            console.log('User authenticated:', user);
            navigate('/Dashboard');
          })
          .catch((error) => {
            console.error('Error signing in with custom token:', error);
        });
      };
      fetchData();
    },[user_token, navigate])
  
  
    async function signIn () {
        const auth = getAuth();
        signInWithEmailAndPassword(auth, email, password)
          .then(async(userCredential) => {
            // Signed in 
            const user = userCredential.user;
            const userId = "u_" + user.uid;
            console.log("UID: " + userId)
            localStorage.setItem('uid', userId);

            
            const userRef = doc(db, "users", userId);
            const docSnap = await getDoc(userRef);
            console.log("login data", docSnap.data().businessID);
            localStorage.setItem('bid', docSnap.data().businessID);
            navigate('/Dashboard');
            // ...
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
          });
    }
  
  
  
  
  
  
    return (
      <div className='loginContainer'>
        <div className='loginLeft'>
          <span className='loginLeftLogo'>Omni AI</span>
          <span className='loginWelcomeText'>Hi, Welcome back</span>
          <img src={OmniLoginImage}/>
        </div>
        <div className='loginRight'>
            <div className='loginSubContainer'>
              <span className='loginRightText'>Sign in to Omni AI</span>
              <span className='loginRightCreateText'>New user? <span className='loginRightCreateLink' onClick={() => {navigate('/Signup')}}>Create an account</span></span>
              <input className='loginInput' type='email' placeholder='Email' value={email} onChange={(event) => setEmail(event.target.value)}/>
              <input className='loginInput' type='password' placeholder='Password' value={password} onChange={(event) => setPassword(event.target.value)}/>
              <button className='buttonContainer' onClick={() => {signIn()}}>Login</button>
            </div>
        
  
        </div>
      </div>
    );
}

export default LogIn;