import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-moment';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
} from 'chart.js';

// function formatNumber(number) {
//   if (Math.abs(number) >= 1.0e+9) {
//     return (Math.abs(number) / 1.0e+9).toFixed(1) + 'B'; // Billions
//   } else if (Math.abs(number) >= 1.0e+6) {
//     return (Math.abs(number) / 1.0e+6).toFixed(1) + 'M'; // Millions
//   } else if (Math.abs(number) >= 1.0e+3) {
//     return (Math.abs(number) / 1.0e+3).toFixed(1) + 'K'; // Thousands
//   } else {
    
//     // If number is smaller than 1000, use regular number formatting
//     return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(number);
//   }
// }

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale);

const LineChart = ({ chartData, dataType, title }) => {
  
  function formatNumberByType(number) {
    if (Math.abs(number) >= 1.0e+9) {
      return (Math.abs(number) / 1.0e+9).toFixed(1) + 'B'; // Billions
    } else if (Math.abs(number) >= 1.0e+6) {
      return (Math.abs(number) / 1.0e+6).toFixed(1) + 'M'; // Millions
    } else if (Math.abs(number) >= 1.0e+3) {
      return (Math.abs(number) / 1.0e+3).toFixed(1) + 'K'; // Thousands
    } else {
          if (dataType === 'currency') {
            return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(number);
          } else if (dataType === 'count') {
            return number.toLocaleString(); // For counts, just format as a plain number
          }
          // Fallback or default formatting
          return number.toString();
  }
}

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
      }
    },
    scales: {
      x: {
        type: 'time',
        
        
        
        grid: {
          display: false,
        },
        ticks: {
          padding: 10,
          color: 'rgba(0, 0, 0, 0.6)',
          maxTicksLimit: 8
        },
        border: {
          display: false
        }
      },
      y: {
        beginAtZero: true,
        grid: {
          color: 'rgba(0, 0, 0, 0.1)',
        },
        ticks: {
          padding: 10,
          color: 'rgba(0, 0, 0, 0.6)',
          callback: function (value) {
            return formatNumberByType(value);
          },
          maxTicksLimit: 6
        },
        border: {
          display: false
        }
      }
    },
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          boxWidth: 12,
          boxHeight: 0,
        },
      },
      title: {
        display: true,
        text: title,
        align: 'start',
        font: {
          size: 18,
        },
        padding: {
          bottom: 10
        }
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        callbacks: {
        usePointStyle: true,
          title: function(context) {
              // Return null or an empty array to remove the title
              return null;
          },
            label: function(context) {
            // Accessing the x-axis value directly from the data point
            
            const dateLabel = context.parsed.x;
           
            const date = new Date(dateLabel).toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
            const value = formatNumberByType(context.parsed.y);
            return `${date}: ${value}`;
          },
          labelPointStyle: function(context) {
            // Check the datasetIndex and return different styles accordingly
            if (context.datasetIndex === 0) {
              return {
                pointStyle: 'line',
                rotation: 0
              };
            } else {
              return {
                pointStyle: 'dash',
                fill: false,
                rotation: 0,
                borderDash: [1, 1]
                
              };
            }
          }
        },
        backgroundColor: 'rgba(255, 255, 255, 1)',
        titleFont: {
            family: 'Arial',
            size: 14,
        },
        bodyFont: {
            family: 'Arial',
            size: 12,
        },
        titleColor: 'rgba(0, 0, 0, 1)',
        titleAlign: 'center',
        bodyColor: 'rgba(0, 0, 0, 1)',
        borderColor: '#e7e7e7',
        borderWidth: 1,
        boxPadding: 15,
        caretPadding: 20,
        padding: 15,
        caretSize: 0,
        bodySpacing: 10,
        usePointStyle: true
    },
    
      filler: {
        propagate: true,
      }
    },
    elements: {
      line: {
        tension: 0.4,
        borderWidth: 2,
        fill: 'start',
      },
      point: {
        radius: 0,
        borderWidth: 2,
        borderColor: 'rgba(255, 99, 132, 1)',
        backgroundColor: 'rgba(255, 99, 132, 1)',
        hoverRadius: 6,
        hoverBorderWidth: 3,
        hoverBorderColor: 'rgba(255, 255, 255, 1)',
        hoverBackgroundColor: 'rgb(75, 192, 192)',
      }
    },
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false
    },
  };

    return <Line data={chartData } options={options} />;
  };
  
  export default LineChart;
