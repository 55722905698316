import './Dashboard.css';
import { getAuth, signOut } from "firebase/auth";
import react, { useState, useEffect } from 'react';
import { collection, doc, getDoc } from 'firebase/firestore';
import {db} from '../FirebaseInit';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import NavbarAndDrawer from '../components/NavbarAndDrawer';
import StoreOverview from '../components/StoreOverview';
import Example from '../components/Charts/Example';
import ChartComponent from '../components/Charts/Example';


function Dashboard() {
  const navigate = useNavigate()


  const [allOrders, setAllOrders] = useState([])
  const uid = localStorage.getItem('uid');
  const bid = localStorage.getItem('bid');

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  const startDate = `${year}-${month}-${day}`;
  const endDate = `${year}-${month}-${day}`;

  console.log(uid)
  console.log(bid)


    async function loadOrders () {
      try {
        const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/getShopifyOrders?uid=${uid}&dateStart=${startDate}&dateEnd=${endDate}&limit=${6}` ;
        const response = await axios.get(apiUrl)      
          setAllOrders(response.data.orders)

        } catch (error) {
          console.log('ERROR loading orders from dashboard.js', error)
        }
      }




    useEffect(() => {
      async function start () {
        if (uid !== '') {
          loadOrders()
        }
      }
        start()
      // Run the function every 60 seconds
      const intervalId = setInterval(start, 60000);

      // Clean up the interval when the component unmounts or when uid changes
      return () => clearInterval(intervalId);
    }, [uid]);


  

     function displayDate (date) {
      //const date = new Date(); 
      const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      const dayOfWeek = days[date.getDay()];
      const month = months[date.getMonth()];
      const dayOfMonth = date.getDate();
      const year = date.getFullYear();
      const formattedDate = `${dayOfWeek} ${month} ${dayOfMonth}, ${year}`;
      //console.log(formattedDate)
      return formattedDate;
    }

    function getTime (date) {
      let hours = date.getHours(); 
      const minutes = date.getMinutes();
      let meridiem = 'AM';
      if (hours === 0) {
        hours = 12; // Convert 0 to 12 for 12 AM
      } else if (hours === 12) {
        meridiem = 'PM'; // Set meridiem to 'PM' for 12 PM
      } else if (hours > 12) {
        hours -= 12; // Convert 24-hour time to 12-hour time for PM
        meridiem = 'PM';
      }
      const paddedMinutes = minutes < 10 ? '0' + minutes : minutes;
      return `${hours}:${paddedMinutes} ${meridiem}`
    }

  
  
  
    return (
        <div className="dashboardContainer">

          <StoreOverview long={false}/>





          <div className='dashboardOrdersContainer'>
            <table className='dashboardOrdersTable'>
              <thead>
                <tr>
                  <td>Order</td>
                  <td>Time</td>
                  <td>Customer</td>
                  <td>Product</td>
                  <td>Quantity</td>
                  <td>Payment</td>
                  <td>Total</td>
                </tr>
              </thead>
              <tbody>
                {allOrders.map((x) => { return (
                  <tr className={x.fulfillmentStatus == 'fulfilled' ? 'dashboardOrderRowFulfilled' : 'dashboardOrderRow'}>
                    <td>#{x.orderNumber}</td>
                    <td>{getTime(new Date(x.createdAt))}</td>
                    <td>{x?.customerName}</td>
                    <td>{x.productName.slice(0,30)}{x.productName.length > 30 ? "..." : ''}</td>
                    <td>{x.quantity}</td>
                    <td>{x.financialStatus}</td>
                    <td className='dashboardOrderRowPrice'>{(x.totalPrice).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                  </tr>
                )})}
                  <tr className='dashboardOrderRowBottom'> 
                  <td colSpan={3}>
                      <div className='dashboardOrderRowBottomLeftButton'>
                        See All Orders
                      </div>
                    </td>
                    <td></td>
                    <td></td>
                    <td colSpan={3}>
                      <div className='dashboardOrderRowBottomRight'>

                      </div>
                    </td>
                  </tr>
              </tbody>
            </table>
          </div>



          <br/><br/><br/><br/>

          <ChartComponent/>

        










        </div>
    );
}

export default Dashboard;