import React from 'react';
import './PageHeader.css';


function PageHeader({title, grandparent, parent, child}) {
    return ( 
        <div className='createAdHeaderContainer'>
            <span className='createAdHeaderText'>{title}</span>
            <div className='createAdHeaderPathContainer'>
                {grandparent || parent || child ? 
                    <>
                        {grandparent && ( 
                        <div className='createAdHeaderPathContainer'>
                            <span className='createAdHeaderPathPast'>{grandparent}</span>            
                            <span className='createAdHeaderPathBullet'>&#x2022;</span>
                        </div>
                        )}
                        <span className='createAdHeaderPathPast'>{parent}</span>            
                        <span className='createAdHeaderPathBullet'>&#x2022;</span>
                        <span className='createAdHeaderPathCurrent'>{child}</span>
                    </>
                : null}
            </div>
        </div>
     );
}

export default PageHeader;