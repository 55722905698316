import React from 'react'

function VideoIcon({}) {
    return ( 

        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
        <path d="M23.172 0C23.7022 0 24.2107 0.210507 24.5857 0.585255L36.4137 12.4044C36.7891 12.7795 37 13.2884 37 13.8191V35.3333C37 37.9107 34.8689 40 32.24 40H7.76C5.13112 40 3 37.9107 3 35.3333V4.66667C3 2.08934 5.13112 0 7.76 0H23.172Z" fill="#FF5630"/>
        <g filter="url(#filter0_d_1255_158044)">
        <path d="M35.1548 12.1381C35.4678 12.4537 35.2443 12.9902 34.7998 12.9902H29C26.4227 12.9902 24.0976 10.7233 24.0976 8.21031V2.20435C24.0976 1.75791 24.6382 1.53528 24.9526 1.85224L35.1548 12.1381Z" fill="white" fill-opacity="0.24" shape-rendering="crispEdges"/>
        </g>
        <path opacity="0.4" d="M20.9204 21.8914V24.6336L26.0004 27.6637V18.8694L20.9204 21.8914Z" fill="white"/>
        <path d="M21.5439 17.9902H10.936C10.424 17.9902 10 18.406 10 18.9256V27.616C10 28.1276 10.416 28.5514 10.936 28.5514H21.5519C22.0639 28.5514 22.4879 28.1356 22.4879 27.616V18.9176C22.4799 18.406 22.0639 17.9902 21.5439 17.9902ZM14.048 26.5447V19.9889L19.3919 23.2668L14.048 26.5447Z" fill="white"/>
        <defs>
        <filter id="filter0_d_1255_158044" x="22.0977" y="1.70337" width="15.2031" height="15.2869" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="2"/>
        <feGaussianBlur stdDeviation="1"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1255_158044"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1255_158044" result="shape"/>
        </filter>
        </defs>
        </svg>

     );
}

export default VideoIcon;