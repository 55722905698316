import React from 'react'

function BudgetIcon({complete}) {
    return ( 

        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.4" d="M9.99 17.98C14.4028 17.98 17.98 14.4028 17.98 9.99C17.98 5.57724 14.4028 2 9.99 2C5.57724 2 2 5.57724 2 9.99C2 14.4028 5.57724 17.98 9.99 17.98Z" fill={complete ? '#05DD74' : '#925CE5'}/>
            <path d="M21.9701 15.99C21.9701 19.29 19.2901 21.97 15.9901 21.97C13.9501 21.97 12.1601 20.95 11.0801 19.4C15.4401 18.91 18.9101 15.44 19.4001 11.08C20.9501 12.16 21.9701 13.95 21.9701 15.99Z" fill={complete ? '#05DD74' : '#925CE5'}/>
            <path d="M11.45 9.71L9.05 8.87C8.81 8.79 8.76 8.77 8.76 8.42C8.76 8.16 8.94 7.95 9.17 7.95H10.67C10.99 7.95 11.25 8.24 11.25 8.6C11.25 9.01 11.59 9.35 12 9.35C12.41 9.35 12.75 9.01 12.75 8.6C12.75 7.45 11.86 6.51 10.75 6.46V6.41C10.75 6 10.41 5.66 10 5.66C9.59 5.66 9.25 5.99 9.25 6.41V6.46H9.16C8.11 6.46 7.25 7.34 7.25 8.43C7.25 9.38 7.67 9.99 8.54 10.29L10.95 11.13C11.19 11.21 11.24 11.23 11.24 11.58C11.24 11.84 11.06 12.05 10.83 12.05H9.33C9.01 12.05 8.75 11.76 8.75 11.4C8.75 10.99 8.41 10.65 8 10.65C7.59 10.65 7.25 10.99 7.25 11.4C7.25 12.55 8.14 13.49 9.25 13.54V13.6C9.25 14.01 9.59 14.35 10 14.35C10.41 14.35 10.75 14.01 10.75 13.6V13.55H10.84C11.89 13.55 12.75 12.67 12.75 11.58C12.75 10.63 12.32 10.02 11.45 9.71Z" fill={complete ? '#05DD74' : '#925CE5'}/>
        </svg>

     );
}

export default BudgetIcon;