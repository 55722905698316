import React from 'react'

function LocationIcon({complete}) {
    return ( 

        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.79 20.76L21.07 20.04C21.44 19.48 21.66 18.8 21.66 18.08C21.66 16.1 20.06 14.5 18.08 14.5C16.1 14.5 14.5 16.1 14.5 18.08C14.5 20.06 16.1 21.66 18.08 21.66C18.81 21.66 19.48 21.44 20.04 21.07L20.76 21.79C20.9 21.93 21.09 22 21.27 22C21.46 22 21.64 21.93 21.78 21.79C22.07 21.5 22.07 21.04 21.79 20.76Z" fill={complete ? '#05DD74' : '#925CE5'}/>
            <path opacity="0.4" d="M2.03001 14.63C2.03001 14.66 2.01001 14.7 2.01001 14.73C2.93001 16.57 4.43002 18.08 6.27002 18.99C6.30002 18.99 6.34001 18.97 6.37001 18.97C6.03001 17.81 5.77002 16.62 5.58002 15.43C4.38002 15.23 3.19001 14.97 2.03001 14.63Z" fill={complete ? '#05DD74' : '#925CE5'}/>
            <path opacity="0.4" d="M19.0701 6.42999C18.1301 4.45999 16.5401 2.87 14.5801 1.94C14.9401 3.13 15.2401 4.35001 15.4401 5.57001C16.6601 5.77001 17.8801 6.05999 19.0701 6.42999Z" fill={complete ? '#05DD74' : '#925CE5'}/>
            <path opacity="0.4" d="M1.92993 6.42999C3.12993 6.06999 4.34995 5.77001 5.56995 5.57001C5.76995 4.38001 6.01994 3.20001 6.35994 2.04001C6.32994 2.04001 6.28993 2.01999 6.25993 2.01999C4.37993 2.94999 2.84993 4.51999 1.92993 6.42999Z" fill={complete ? '#05DD74' : '#925CE5'}/>
            <path opacity="0.4" d="M13.8199 5.35999C13.5799 4.05999 13.2799 2.76 12.8499 1.5C12.8299 1.43 12.8299 1.37001 12.8199 1.29001C12.0799 1.11001 11.2999 0.98999 10.4999 0.98999C9.68994 0.98999 8.91994 1.10001 8.16994 1.29001C8.15994 1.36001 8.16993 1.42 8.14993 1.5C7.72993 2.76 7.41993 4.05999 7.17993 5.35999C9.38993 5.11999 11.6099 5.11999 13.8199 5.35999Z" fill={complete ? '#05DD74' : '#925CE5'}/>
            <path opacity="0.4" d="M5.35999 7.17999C4.04999 7.41999 2.75998 7.71999 1.49998 8.14999C1.42998 8.16999 1.36998 8.16999 1.28998 8.17999C1.10998 8.91999 0.98999 9.7 0.98999 10.5C0.98999 11.31 1.09998 12.08 1.28998 12.83C1.35998 12.84 1.41998 12.83 1.49998 12.85C2.75998 13.27 4.04999 13.58 5.35999 13.82C5.11999 11.61 5.11999 9.38999 5.35999 7.17999Z" fill={complete ? '#05DD74' : '#925CE5'}/>
            <path opacity="0.4" d="M19.6999 8.17999C19.6299 8.17999 19.5699 8.16999 19.4899 8.14999C18.2299 7.72999 16.9299 7.41999 15.6299 7.17999C15.8799 9.38999 15.8799 11.61 15.6299 13.81C16.9299 13.57 18.2299 13.27 19.4899 12.84C19.5599 12.82 19.6199 12.83 19.6999 12.82C19.8799 12.07 19.9999 11.3 19.9999 10.49C19.9999 9.69999 19.8899 8.92999 19.6999 8.17999Z" fill={complete ? '#05DD74' : '#925CE5'}/>
            <path opacity="0.4" d="M7.17993 15.64C7.41993 16.95 7.71993 18.24 8.14993 19.5C8.16993 19.57 8.15994 19.63 8.16994 19.71C8.91994 19.89 9.68994 20.01 10.4999 20.01C11.2999 20.01 12.0799 19.9 12.8199 19.71C12.8299 19.64 12.8299 19.58 12.8499 19.5C13.2699 18.24 13.5799 16.95 13.8199 15.64C12.7199 15.76 11.6099 15.85 10.4999 15.85C9.38994 15.84 8.27993 15.76 7.17993 15.64Z" fill={complete ? '#05DD74' : '#925CE5'}/>
            <path opacity="0.4" d="M6.9501 6.95001C6.6501 9.31001 6.6501 11.69 6.9501 14.06C9.3101 14.36 11.6901 14.36 14.0601 14.06C14.3601 11.7 14.3601 9.32001 14.0601 6.95001C11.6901 6.65001 9.3101 6.65001 6.9501 6.95001Z" fill={complete ? '#05DD74' : '#925CE5'}/>
        </svg>

     );
}

export default LocationIcon;