import React from 'react';
import { useNavigate } from 'react-router-dom';
import react, { useState, useEffect } from 'react';
import { ArrowForwardIosRounded, ArrowForwardRounded, ArrowRightAltRounded, CheckRounded, ChevronRightRounded, Notifications, NotificationsRounded, SearchRounded, Settings, SettingsRounded, TuneRounded } from '@mui/icons-material';
import { getAuth, signOut } from "firebase/auth";
import DrawerTempIcon from '../assets/Icons/DrawerTempIcon';
import './NavbarAndDrawer.css';
import { CircularProgress, ClickAwayListener } from '@mui/material';
import avatar from '../assets/avatar.png';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { collection, doc, getDoc, setDoc } from 'firebase/firestore';
import {db} from '../FirebaseInit';
import TextInput from './TextInput';


function NavbarAndDrawer({children}) {
    const navigate = useNavigate()


    const [collapsed, setCollapsed] = useState(true)

    const [showDateSelectors, setShowDateSelectors] = useState(false)
    const [showNotificationsPane, setShowNotificationsPane] = useState(false)
    const [showSearchPopup, setShowSearchPopup] = useState(false)
    const [showProfilePictureDropdown, setShowProfilePictureDropdown] = useState(false)
    const [hideNavAndDrawer, setHideNavAndDrawer] = useState(false);
    const [searchText, setSearchText] = useState('')
    const [searchResults, setSearchResults] = useState({fb_ad_account: [], fb_campaign: [], fb_adset: [], fb_ad: [], shopify_products: []})
    const [continueSignUp, setContinueSignUp] = useState(false)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [businessName, setBusinessName] = useState('')
    const [signUpStep, setSignUpStep] = useState(1)
    const [shopifyIntegrated, setShopifyIntegrated] = useState(false)
    const [facebookIntegrated, setFacebookIntegrated] = useState(false)
    const [googleIntegrated, setGoogleIntegrated] = useState(false)
    const [signUpLoading, setSignUpLoading] = useState(false)


    const bid = localStorage.getItem('bid')
    const uid = localStorage.getItem('uid')



    async function logOut () {
        localStorage.removeItem('shopifyOverview_orders')
        localStorage.removeItem('shopifyOverview_revenue')
        localStorage.removeItem('shopifyOverview_facebookSpend')
        localStorage.removeItem('shopifyOverview_cogs')
        localStorage.removeItem('shopifyOverview_aov')
        localStorage.removeItem('shopifyOverview_profit')
        localStorage.removeItem('shopifyOverview_facebookClicks')
        localStorage.removeItem('shopifyOverview_facebookImpressions')
        localStorage.removeItem('uid')
        localStorage.removeItem('bid')

        const auth = getAuth();
        signOut(auth).then(() => {
          // Sign-out successful.
          setContinueSignUp(false)
        }).catch((error) => {
          // An error happened.
        });
    }
    
    
    function displayDate (date) {
        //const date = new Date(); 
        const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const dayOfWeek = days[date.getDay()];
        const month = months[date.getMonth()];
        const dayOfMonth = date.getDate();
        const year = date.getFullYear();
        const formattedDate = `${dayOfWeek} ${month} ${dayOfMonth}, ${year}`;
        //console.log(formattedDate)
        return formattedDate;
    }


    // Get the current URL path
    const path = window.location.pathname;
    // Split the path by '/'
    const pathSegments = path.split('/');
    // Get the last segment (excluding empty segments)
    const directory = pathSegments.filter(segment => segment !== '').pop();


    function handleKeyPress (event) {
        if (event.keyCode === 27) {
            //esc key pressed
            setShowSearchPopup(false)
        } else if (event.keyCode === 27 || (event.keyCode === 75 && event.metaKey)) {
            setShowSearchPopup(true)
        }
    }


    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);
        // Remove event listener when the component unmounts
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);



    const location = useLocation();

    useEffect(() => {
      // Get the current URL path
      const path = location.pathname;
      // Split the path by '/'
      const pathSegments = path.split('/');
      // Get the last segment (excluding empty segments)
      const directory = pathSegments.filter(segment => segment !== '').pop();
  
      if (directory === 'Login' || directory === 'LogIn' || directory === 'Signup' || directory === 'SignUp') {
        setHideNavAndDrawer(true);
      } else {
        setHideNavAndDrawer(false);
      }

      console.log(location.pathname)
    }, [location.pathname]);



    useEffect(() => {
        if (searchText !== '') {
            // Perform search
            searchDatabase()
        }
    }, [searchText])

    async function searchDatabase () {
        try {
            console.log('searching DB')
            const response = await axios.get(`http://${process.env.REACT_APP_API_BASE_URL}?omni_business_id=${bid}&searchString=${searchText}`);
            console.log(response.data)
            setSearchResults(response.data)
        } catch (error) {
            console.log('ERROR searching DB NavbarAndDrawer.js', error)
        }
    }



    //HIDE EVERYTHING IF NOT INTEGRATED WITH SHOPIFY
    useEffect(() => {
        if (uid) {
            getUser()
        }
    }, [uid])

    async function getUser () {
        const userRef = doc(db, "users", uid);
        const userSnap = await getDoc(userRef);

        const businessRef = doc(db, "businesses", bid);
        const businessSnap = await getDoc(businessRef);


        if (businessSnap.data().shopifyAdminAccessToken == '') {
            setShopifyIntegrated(false)
        } else {
            setShopifyIntegrated(true)
        }

        if (businessSnap.data().facebookAccessToken == '') {
            setFacebookIntegrated(false)
        } else {
            setFacebookIntegrated(true)
        }

        if (businessSnap.data().refreshToken == '') {
            setGoogleIntegrated(false)
        } else {
            setGoogleIntegrated(true)
        }


        if ((userSnap.data().firstName == '' || userSnap.data().lastName == '') && bid) {
            setContinueSignUp(true)
            setSignUpStep(1)
        } else if (businessSnap && businessSnap.data().shopifyAdminAccessToken == '' && bid) {
            setContinueSignUp(true)
            setSignUpStep(2)
            
        }
    }


    console.log('Shopify Integrated: ', shopifyIntegrated)



    async function updateName () {
        try {
            setSignUpLoading(true)
            const userDocRef = doc(db, 'users', uid);
            // Update the user's first name and last name
            await setDoc(userDocRef, {
              firstName: firstName,
              lastName: lastName,
            }, { merge: true }); 
  
  
            const businessDocRef = doc(db, 'businesses', bid);
            // Update the user's first name and last name
            await setDoc(businessDocRef, {
              businessName: businessName,
              ownerFirstName: firstName,
              ownerLastName: lastName,
            }, { merge: true });
  
            setSignUpLoading(false)
            setSignUpStep(2)
            console.log('User name updated successfully');
        } catch (error) {
            setSignUpLoading(false)
            setSignUpStep(2)
            console.error('Error updating user name:', error);
        }
       
      }



    return ( 
        
        <div className='navContainer' style={{
            //filter: continueSignUp && 'blur(10px)',
        }}>
            <div className={collapsed ? 'navLeftContainerCollapsed' : 'navLeftContainer'} style={{display: hideNavAndDrawer && 'none'}}>
                <div className='navExpandCollapseIconContainer' style={{transform: collapsed ? 'none' : 'rotate(180deg)'}} onClick={() => {setCollapsed(!collapsed)}}><ArrowForwardIosRounded fontSize='10'/> </div>
                <div className='navLeftContainerTop'>
                <span className='navLeftContainerTitle'>Omni</span>
                <span className='navLeftContainerSubtitle' style={{display: collapsed ? 'none' : null}}>Overview</span>
        
                <div className={directory == 'Dashboard' ? 'navLeftContainerItemSelected' : 'navLeftContainerItem'} onClick={() => navigate('/Dashboard')}>
                    <div className={collapsed ? 'navLeftContainerItemLeftCollapsed' : 'navLeftContainerItemLeft'}>
                        <DrawerTempIcon active={directory == 'Dashboard' ? true : false}/>
                        <span className={collapsed ? 'navLeftContainerItemLeftTextCollapsed' : 'navLeftContainerItemLeftText'}>Dashboard</span>
                    </div>
                </div>
        
        
                <div className={directory == 'Analytics' ? 'navLeftContainerItemSelected' : 'navLeftContainerItem'} onClick={() => navigate('/Analytics')}>
                    <div className={collapsed ? 'navLeftContainerItemLeftCollapsed' : 'navLeftContainerItemLeft'}>
                        <DrawerTempIcon active={directory == 'Analytics' ? true : false}/>
                        <span className={collapsed ? 'navLeftContainerItemLeftTextCollapsed' : 'navLeftContainerItemLeftText'}>Analytics</span>
                    </div>
                </div>
        
        
                <span className='navLeftContainerSubtitle' style={{display: collapsed ? 'none' : null}}>Management</span>
        
        
                <div className={directory == 'Orders' ? 'navLeftContainerItemSelected' : 'navLeftContainerItem'} onClick={() => navigate('/Orders')}>
                    <div className={collapsed ? 'navLeftContainerItemLeftCollapsed' : 'navLeftContainerItemLeft'}>
                        <DrawerTempIcon active={directory == 'Orders' ? true : false}/>
                        <span className={collapsed ? 'navLeftContainerItemLeftTextCollapsed' : 'navLeftContainerItemLeftText'}>Orders</span>
                    </div>
                </div>
        
                <div className={directory == 'Products' ? 'navLeftContainerItemSelected' : 'navLeftContainerItem'} onClick={() => navigate('/Products')}>
                    <div className={collapsed ? 'navLeftContainerItemLeftCollapsed' : 'navLeftContainerItemLeft'}>
                        <DrawerTempIcon active={directory == 'Products' ? true : false}/>
                        <span className={collapsed ? 'navLeftContainerItemLeftTextCollapsed' : 'navLeftContainerItemLeftText'}>Products</span>
                    </div>
                </div>
        
                <div className={directory == 'Files' ? 'navLeftContainerItemSelected' : 'navLeftContainerItem'} onClick={() => navigate('/Files')}>
                    <div className={collapsed ? 'navLeftContainerItemLeftCollapsed' : 'navLeftContainerItemLeft'}>
                        <DrawerTempIcon active={directory == 'Files' ? true : false}/>
                        <span className={collapsed ? 'navLeftContainerItemLeftTextCollapsed' : 'navLeftContainerItemLeftText'}>File Manager</span>
                    </div>
                </div>
        
                <span className='navLeftContainerSubtitle' style={{display: collapsed ? 'none' : null}}>Marketing</span>
        
        
                <div className={directory == 'Ads' ? 'navLeftContainerItemSelected' : 'navLeftContainerItem'} onClick={() => navigate('/Ads')}>
                    <div className={collapsed ? 'navLeftContainerItemLeftCollapsed' : 'navLeftContainerItemLeft'}>
                        <DrawerTempIcon active={directory == 'Ads' ? true : false}/>
                        <span className={collapsed ? 'navLeftContainerItemLeftTextCollapsed' : 'navLeftContainerItemLeftText'}>Ads Manager</span>
                    </div>
                </div>

                <div className={directory == 'Create' ? 'navLeftContainerItemSelected' : 'navLeftContainerItem'} onClick={() => navigate('/Create')}>
                    <div className={collapsed ? 'navLeftContainerItemLeftCollapsed' : 'navLeftContainerItemLeft'}>
                        <DrawerTempIcon active={directory == 'Create' ? true : false}/>
                        <span className={collapsed ? 'navLeftContainerItemLeftTextCollapsed' : 'navLeftContainerItemLeftText'}>Create Ad</span>
                    </div>
                </div>

                <div className={directory == 'Drafts' ? 'navLeftContainerItemSelected' : 'navLeftContainerItem'} onClick={() => navigate('/Drafts')}>
                    <div className={collapsed ? 'navLeftContainerItemLeftCollapsed' : 'navLeftContainerItemLeft'}>
                        <DrawerTempIcon active={directory == 'Drafts' ? true : false}/>
                        <span className={collapsed ? 'navLeftContainerItemLeftTextCollapsed' : 'navLeftContainerItemLeftText'}>Drafts</span>
                    </div>
                </div>

                <div className={directory == 'Audiences' ? 'navLeftContainerItemSelected' : 'navLeftContainerItem'} onClick={() => navigate('/Interests')}>
                    <div className={collapsed ? 'navLeftContainerItemLeftCollapsed' : 'navLeftContainerItemLeft'}>
                        <DrawerTempIcon active={directory == 'Audiences' ? true : false}/>
                        <span className={collapsed ? 'navLeftContainerItemLeftTextCollapsed' : 'navLeftContainerItemLeftText'}>Audiences</span>
                    </div>
                </div>
        
                <div className={directory == 'Interests' ? 'navLeftContainerItemSelected' : 'navLeftContainerItem'} onClick={() => navigate('/Interests')}>
                    <div className={collapsed ? 'navLeftContainerItemLeftCollapsed' : 'navLeftContainerItemLeft'}>
                        <DrawerTempIcon active={directory == 'Interests' ? true : false}/>
                        <span className={collapsed ? 'navLeftContainerItemLeftTextCollapsed' : 'navLeftContainerItemLeftText'}>Interests</span>
                    </div>
                </div>
        
        
        
        
        
        
        
        
        
        
        
        
        
        
                </div>
                <div className='navLeftContainerBottom'>

                    <div className={directory == 'Settings' ? 'navLeftContainerItemSelected' : 'navLeftContainerItem'} onClick={() => navigate('/Settings')}>
                        <div className={collapsed ? 'navLeftContainerItemLeftCollapsed' : 'navLeftContainerItemLeft'}>
                            <DrawerTempIcon active={directory == 'Settings' ? true : false}/>
                            <span className={collapsed ? 'navLeftContainerItemLeftTextCollapsed' : 'navLeftContainerItemLeftText'}>Settings</span>
                        </div>
                    </div>

                    <div className='navLeftContainerItem'  onClick={() => {logOut()}}>
                        <div className={collapsed ? 'navLeftContainerItemLeftCollapsed' : 'navLeftContainerItemLeft'}>
                            <DrawerTempIcon active={false}/>
                            <span className={collapsed ? 'navLeftContainerItemLeftTextCollapsed' : 'navLeftContainerItemLeftText'}>Logout</span>
                        </div>
                    </div>
                
                </div>
            </div>
        
            <div className={collapsed ? 'navNavbarContainerCollapsed' : 'navNavbarContainer'} style={{display: hideNavAndDrawer && 'none'}}>
                <div className='navNavbarLeftContainer'>
                    <div className='navNavbarLeftSearchContainer' onClick={() => {setShowSearchPopup(true)}}>
                    <div className='navNavbarLeftSearchIcon'>
                        <SearchRounded/>
                    </div>
                    <span className='navNavbarLeftSearchKeysText'>&#8984;K</span>
                    </div>
                </div>
                <div className='navNavbarRightContainer'>
                    
                    <div className='navNavbarRightContainerIcon' onClick={() => {setShowNotificationsPane(true)}}>
                    <NotificationsRounded/>
                    </div>
                    {/* <div className='navNavbarRightContainerIcon'>
                    <TuneRounded/>
                    </div> */}
                    <div className='navNavbarRightContainerSettingsIcon' onClick={() => {navigate('/Settings')}}>
                    <SettingsRounded/>
                    </div>
                    <div className='navNavbarRightContainerAvatarContainer' onClick={() => {setShowProfilePictureDropdown(!showProfilePictureDropdown)}}>
                        <img src={avatar} className='navNavbarRightContainerAvatar'/>
                    </div>
                    {showProfilePictureDropdown && (
                        <div className='navNavbarProfileDropdown'>
                            <div className='navNavbarProfileDropdownSection1'>
                                <span className='navNavbarProfileDropdownSection1Name'>FIRST LAST</span>
                                <span className='navNavbarProfileDropdownSection1Email'>EMAIL ADDRESS HERE</span>
                            </div>
                            <div className='navNavbarProfileDropdownSection2'>
                                <span className='navNavbarProfileDropdownSection2Link'>Home</span>
                                <span className='navNavbarProfileDropdownSection2Link'>Profile</span>
                                <span className='navNavbarProfileDropdownSection2Link'>Settings</span>
                            </div>
                            <div className='navNavbarProfileDropdownSection3' onClick={() => {logOut()}}>
                                <span className='navNavbarProfileDropdownSection3LogoutButton'>Logout</span>
                            </div>
                        </div>
                    )}



        
                    {showDateSelectors && (
                    <>
                    <div className='navNavbarDateSelector'>
                        <span className='navNavbarDateSelectorText'>{displayDate(new Date())}</span>
                    </div>
                    <div className='navNavbarDateSelector'>
                        <span className='navNavbarDateSelectorText'>{displayDate(new Date())}</span>
                    </div>
                    </>
                    )}
                </div>
                
            </div>
        
        
            <div 
                className={collapsed ? 'navRightContainerCollapsed' : 'navRightContainer'} 
                style={{
                    padding: hideNavAndDrawer && 0,
                    minHeight: hideNavAndDrawer && '100vh',
                }}
            >
                {children}


                    {/* SEARCH POPUP */}
                    <div className={showSearchPopup ? 'navSearchPopupBackground' : 'navSearchPopupBackgroundHidden'}>
                        <div className='navSearchPopup'>
                            <div className='navSearchTopContainer'>
                                <div className='navSearchTopSearchIcon'>
                                    <SearchRounded/>
                                </div>
                                <input className='navSearchTopInput' placeholder='Search...' value={searchText} onChange={(e) => {setSearchText(e.target.value)}}/>
                                <span className='navSearchTopESCButton' onClick={() => {setShowSearchPopup(false)}}>Esc</span>
                            </div>
                            <div className='navSearchResultsContainer'>
                                {searchResults.fb_ad_account.length > 0 && <div className='navSearchResultsCategory'>Facebook Ad Accounts</div>}
                                {searchResults.fb_ad_account.map((x, index) => (
                                    <div className='navSearchResult'>
                                        <span className='navSearchResultTitle'>{x.name}</span>
                                        <span className='navSearchResultDescription'>{x.status}</span>
                                    </div>
                                ))}
                                {searchResults.fb_campaign.length > 0 && <div className='navSearchResultsCategory'>Facebook Campaigns</div>}
                                {searchResults.fb_campaign.map((x, index) => (
                                    <div className='navSearchResult'>
                                        <span className='navSearchResultTitle'>{x.name}</span>
                                        <span className='navSearchResultDescription'>{x.status}</span>
                                    </div>
                                ))}
                                {searchResults.fb_adset.length > 0 && <div className='navSearchResultsCategory'>Facebook Ad Sets</div>}
                                {searchResults.fb_adset.map((x, index) => (
                                    <div className='navSearchResult'>
                                        <span className='navSearchResultTitle'>{x.name}</span>
                                        <span className='navSearchResultDescription'>{x.status}</span>
                                    </div>
                                ))}
                                {searchResults.fb_ad.length > 0 && <div className='navSearchResultsCategory'>Facebook Ads</div>}
                                {searchResults.fb_ad.map((x, index) => (
                                    <div className='navSearchResult'>
                                        <span className='navSearchResultTitle'>{x.name}</span>
                                        <span className='navSearchResultDescription'>{x.status}</span>
                                    </div>
                                ))}
                                {searchResults.shopify_products.length > 0 && <div className='navSearchResultsCategory'>Shopify Products</div>}
                                {searchResults.shopify_products.map((x, index) => (
                                    <div className='navSearchResult'>
                                        <span className='navSearchResultTitle'>{x.title}</span>
                                        <span className='navSearchResultDescription'>{x.description.slice(0,80)}</span>
                                    </div>
                                ))}
                            </div>

                        </div>
                    </div>
                    


                    {/* NOTIFICATIONS PANE */}
                    <div className={`navNotificationRightPane ${showNotificationsPane ? 'open': ''}`}>
                        <div className='navNotificationRightPaneHeaderContainer'>
                            <span className='navNotificationRightPaneHeaderCollapseIcon' onClick={() => {setShowNotificationsPane(false)}}>
                                <ChevronRightRounded/>
                            </span>
                            <span className='navNotificationRightPaneHeaderTitle'>Notifications</span>
                            <span className='navNotificationRightPaneHeaderCollapseIcon' style={{opacity: 0}}>
                                <ChevronRightRounded/>
                            </span>
                        </div>
                        <div className='navNotificationRightPaneContent'>

                        </div>
                    </div>






                        {/* Continue Sign Up Popup */}
                    <div className={`navSignUpPopupContainer ${continueSignUp ? 'open': ''}`}>
                        <div className={`navSignUpPopup`}>
                            {signUpStep == 1 && (
                                <React.Fragment>
                                    <span className='navSignUpPopupTitle'>Account Details</span>
                                    
                                    <div className='navSignUpPopupContent'>
                                        <TextInput placeholder='Business Name' value={businessName} onChange={setBusinessName} marginBottom={20} widthPercent={100}/>
                                        <TextInput placeholder='First Name' value={firstName} onChange={setFirstName} marginBottom={20} widthPercent={100}/>
                                        <TextInput placeholder='Last Name' value={lastName} onChange={setLastName} marginBottom={20} widthPercent={100}/>
                                

                                    </div>
                                    <div className='navSignUpPopupFooter'>
                                        <span className='navSignUpPopupCancelButton' onClick={() => {logOut()}}>Logout</span>
                                        <span className='navSignUpPopupContinueButton' onClick={() => {updateName()}}>
                                            {signUpLoading ? <CircularProgress size={16} style={{color: '#fff'}}/> : "Continue"}
                                        </span>
                                    </div>
                                </React.Fragment>
                            )}


                            {signUpStep == 2 && (
                                <React.Fragment>
                                    <span className='navSignUpPopupTitle'>Integrations</span>
                                    
                                    <div className='navSignUpPopupContent'>
                                       
                                    <div className='navIntegrations'>
                                        <img className='navIntegrationsPlatformImg' src='https://cdn3.iconfinder.com/data/icons/social-media-2068/64/_shopping-512.png'/>
                                        <span className='navIntegrationsPlatformText'>{shopifyIntegrated ? "Connected to Shopify" : "Connect to Shopify"}</span>
                                        </div>
                                        
                                        <div className='navIntegrations' onClick={() => {navigate('/process-facebook-redirect')}}>
                                        <img className='navIntegrationsPlatformImg' src='https://upload.wikimedia.org/wikipedia/commons/6/6c/Facebook_Logo_2023.png'/>
                                        <span className='navIntegrationsPlatformText'>{facebookIntegrated ? "Connected to Facebook" : "Connect to Facebook"}</span>
                                        </div>

                                        <div className='navIntegrations' onClick={() => {navigate('/process-google-redirect')}}>
                                        <img className='navIntegrationsPlatformImg' src='https://cdn4.iconfinder.com/data/icons/logos-brands-7/512/google_logo-google_icongoogle-512.png'/>
                                        <span className='navIntegrationsPlatformText'>{googleIntegrated ? "Connected to Google" : "Connect to Google"}</span>
                                        </div>

                                    </div>
                                    <div className='navSignUpPopupFooter'>
                                        <span className='navSignUpPopupCancelButton' onClick={() => {setSignUpStep(1)}}>Back</span>
                                        <span className='navSignUpPopupContinueButton'>Continue</span>
                                    </div>
                                </React.Fragment>
                            )}


                      


                        </div>
                    </div>



            </div>
        </div>
    );
}

export default NavbarAndDrawer;