import * as React from 'react';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { FiberManualRecord, Edit } from '@mui/icons-material';
import Chip from '@mui/material/Chip';
import TableRowsLoader from '../../../../components/skeleton/TableSkeleton';

// Create custom theme with overrides
const styles = {
    root: {
        padding: "0px !important"
    }
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#f8f9fa',
        color: '#333',
        fontWeight: 'bold',
        fontSize: '1rem',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: '0.9rem',
        borderBottom: '1px dashed rgba(224, 224, 224, 1)',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:last-child': {
        backgroundColor: 'white',
    },
}));

const StyledTableContainer = styled(TableContainer)({
    boxShadow: 'none',
});



const defaultColumns = [
    { field: 'name', headerName: 'Name', width: 50 },
    { field: 'adset_id', headerName: 'AdSet ID', width: 50 },
    { field: 'status', headerName: 'Status', width: 150 },
    { field: 'account_id', headerName: 'Account ID', width: 150 },
    { field: 'campaign_id', headerName: 'Campaign ID', width: 150 },
];

const getStatusChipColor = (status) => {
    switch (status) {
        case 'ACTIVE':
            return { color: '#22c55e', backgroundColor: '#22c55e29', fontWeight: 'bold', padding: "0px 6px" }; // Green
        default:
            return { color: '#d70000', backgroundColor: '#ffdddd', fontWeight: 'bold', padding: "0px 6px" }; // Default grey
    }
};



const AdsGrid = ({ filterStatus, items, campaignId, visibleColumns, adSetId, openDrawer }) => {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);

    const getFilteredItems = () => {
        if (filterStatus === 'ANY' || filterStatus === 'active') { 
            return items; 
        }
        return items.filter(item => item.status === filterStatus);
    };


    return (
        <Box component={'div'} sx={{ padding: 0, backgroundColor: 'white', borderRadius: 5, border: '1px solid #f3f3f3', overflow: 'hidden' }}>
            <StyledTableContainer component={Paper}>
                <Table className='table_edit' sx={{ minWidth: 1920 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            {[...defaultColumns, ...visibleColumns].map((column, index) => (
                                <StyledTableCell key={column.field + '_' + index} align={column.field === 'name' ? 'left' : 'center'}>
                                    {column.headerName}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items?.length === 0 ?
                            <>
                                <TableRowsLoader rowsNum={10} />
                            </>
                            :
                            getFilteredItems().map((row, index) => (
                                <StyledTableRow key={row.campaign_id + '_' + index}>
                                    {[...defaultColumns, ...visibleColumns].map(item =>
                                        <StyledTableCell align={item.field === 'name' ? 'left' : 'center'}>
                                            {item.field === 'status' ? (
                                                <Chip
                                                    icon={<FiberManualRecord sx={{ border: 0, fontSize: '12px' }} style={{ color: getStatusChipColor(row[item.field]).color, }} />}
                                                    label={row[item.field]}
                                                    style={getStatusChipColor(row[item.field])}
                                                />
                                            ) : item.field === 'name' ? (
                                                <div onClick={() => openDrawer(row)} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', cursor: 'pointer' }}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div>
                                                            <div>{row[item.field]}</div>
                                                            {/* <div style={{ fontSize: '0.8rem', color: '#555' }}>{row.email}</div> */}
                                                        </div>
                                                    </div>
                                                    <Edit sx={{ cursor: 'pointer', color: '#248fec', fontSize: '18px' }} />
                                                </div>
                                            ) : (
                                                row[item.field])}
                                        </StyledTableCell>)}

                                </StyledTableRow>
                            ))}
                    </TableBody>
                </Table>
            </StyledTableContainer>
        </Box>
    );
};

export default AdsGrid;