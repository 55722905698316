import React from 'react';
import { Box, Grid, Typography, LinearProgress, Paper, } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { experimentalStyled as styled } from '@mui/material/styles';
import { RequestQuote, AdsClick, TouchApp, InsertChart, GraphicEq, Insights, ShoppingCartRounded, AccountBalanceWalletRounded, ViewTimelineRounded, ImportContactsRounded } from '@mui/icons-material';
import IconBox from '../../../../components/IconBox';
import './ImpressionData.css';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
  boxWrapper: {
    width: '32%',
  },
  campaignAnalyticsWrapper: {
    padding: '1rem',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '12px',
    backgroundColor: '#fff',
    marginTop: '25px'
  },
  analyticsTitle: {
    fontSize: '24px !important'
  }
});
const DashboardComponent = (props) => {
  const { campaignData } = props;
  const classes = useStyles();
  console.log(campaignData, 'compaginData')
  return (
    <>
      <Box className={classes.campaignAnalyticsWrapper}>
        <Typography className={classes.analyticsTitle}>Campaign Analytics</Typography>
        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', justifyContent: 'flex-start', flexWrap: 'wrap', gap: '10px' }} >
            <IconBox
              icon={<RequestQuote />}
              heading={'Spend'}
              headingSize={'25px'}
              value={campaignData.spend}
              layoutClass={'layoutGrid'}
              iconSize={'40'}
              iconColor={'#ac35ff'}
              iconBackgroundColor={'#f3e3ff'}
              headingColor={'#4a4a4a'}
              subTextColor={'#4a4a4a'}
              subTextAlign={'center'}
              contentWrapperStyles={
                {
                  display: 'flex',
                  flexFlow: 'column',
                  alignItems: 'center'
                }
              }
              
            />
              <IconBox
                icon={< AdsClick />}
                heading={'Click'}
                headingSize={'25px'}
                value={campaignData.clicks}
                layoutClass={'layoutGrid'}
                iconSize={'40'}
                iconColor={'#FF5992'}
                iconBackgroundColor={'#fee5ef'}
                headingColor={'#4a4a4a'}
                subTextColor={'#4a4a4a'}
                subTextAlign={'center'}
                contentWrapperStyles={
                {
                  display: 'flex',
                  flexFlow: 'column',
                  alignItems: 'center'
                }
              }
              />
          
          
            <IconBox
              icon={< TouchApp />}
              heading={'CPC'}
              headingSize={'25px'}
              value={campaignData.cpc}
              layoutClass={'layoutGrid'}
              iconSize={'40'}
              iconColor={'#1b20a1'}
              iconBackgroundColor={'#d8e6ff'}
              headingColor={'#4a4a4a'}
              subTextColor={'#4a4a4a'}
              subTextAlign={'center'}
              contentWrapperStyles={
                {
                  display: 'flex',
                  flexFlow: 'column',
                  alignItems: 'center'
                }
              }
            />
          
            <IconBox
              icon={< InsertChart />}
              heading={'CPM'}
              value={campaignData.cpc}
              layoutClass={'layoutGrid'}
              iconSize={'40'}
              iconColor={'#da8735'}
              iconBackgroundColor={'#f9eedb'}
              headingColor={'#4a4a4a'}
              subTextColor={'#4a4a4a'}
              subTextAlign={'center'}
              contentWrapperStyles={
                {
                  display: 'flex',
                  flexFlow: 'column',
                  alignItems: 'center'
                }
              }
              headingSize={'25px'}
            />
          
            <IconBox
              icon={< GraphicEq />}
              heading={'CPP'}
              value={campaignData.cpc}
              layoutClass={'layoutGrid'}
              iconSize={'40'}
              iconColor={'#25b4b6'}
              iconBackgroundColor={'#d0f7f4'}
              headingColor={'#4a4a4a'}
              subTextColor={'#4a4a4a'}
              subTextAlign={'center'}
              contentWrapperStyles={
                {
                  display: 'flex',
                  flexFlow: 'column',
                  alignItems: 'center'
                }
              }
              headingSize={'25px'}
            />
          
            <IconBox
              icon={< Insights />}
              heading={'Impressions'}
              value={campaignData.impressions}
              layoutClass={'layoutGrid'}
              iconSize={'40'}
              iconColor={'#2a8853'}
              iconBackgroundColor={'#e1f7ea'}
              headingColor={'#4a4a4a'}
              subTextColor={'#4a4a4a'}
              subTextAlign={'center'}
              contentWrapperStyles={
                {
                  display: 'flex',
                  flexFlow: 'column',
                  alignItems: 'center'
                }
              }
              headingSize={'25px'}
            />
        </Box>
      </Box>
      <Box className={classes.campaignAnalyticsWrapper}>
      <Typography className={classes.analyticsTitle}>Campaign Insights</Typography>
        <Grid container spacing={0}>
          <Grid item xs={4}>
          <Item elevation={0}>
            <IconBox
              icon={< ShoppingCartRounded />}
              heading={'Purchase'}
              value={campaignData.purchase}
              layoutClass={'layoutFlex'}
              iconSize={'40'}
              iconColor={'#7092b6'}
              iconBackgroundColor={'#e9f3f6'}
              headingColor={'#4a4a4a'}
              subTextColor={'#4a4a4a'}
              subTextAlign={'center'}
              headingSize={'25px'}
              iconWrapperStyles={{
                marginRight: '20px'
              }}
            />
          </Item>
          </Grid>
          <Grid item xs={4}>
          <Item elevation={0}>
            <IconBox
              icon={< AccountBalanceWalletRounded />}
              heading={'Value Purchase'}
              value={campaignData.value_purchase}
              layoutClass={'layoutFlex'}
              iconSize={'40'}
              iconColor={'#0faa35'}
              iconBackgroundColor={'#dafee3'}
              headingColor={'#4a4a4a'}
              subTextColor={'#4a4a4a'}
              subTextAlign={'center'}
              headingSize={'25px'}
              iconWrapperStyles={{
                marginRight: '20px'
              }}
            />
          </Item>
          </Grid>
          <Grid item xs={4}>
          <Item elevation={0}>
            <IconBox
              icon={< ViewTimelineRounded />}
              heading={'Frequency'}
              value={campaignData.frequency}
              layoutClass={'layoutFlex'}
              iconSize={'40'}
              iconColor={'#7954fe'}
              iconBackgroundColor={'#eae8ff'}
              headingColor={'#4a4a4a'}
              subTextColor={'#4a4a4a'}
              subTextAlign={'center'}
              headingSize={'25px'}
              iconWrapperStyles={{
                marginRight: '20px'
              }}
            />
          </Item>
          </Grid>
          <Grid item xs={4}>
          <Item elevation={0}>
            <IconBox
              icon={< ImportContactsRounded />}
              heading={'Page Engagement'}
              value={campaignData.page_engagement}
              layoutClass={'layoutFlex'}
              iconSize={'40'}
              iconColor={'#248fec'}
              iconBackgroundColor={'#dbf2fe'}
              headingColor={'#4a4a4a'}
              subTextColor={'#4a4a4a'}
              subTextAlign={'center'}
              headingSize={'25px'}
              iconWrapperStyles={{
                marginRight: '20px'
              }}
            />
          </Item>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default DashboardComponent;