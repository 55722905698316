import React, { useEffect, useState } from 'react';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


function GoogleAccountsPage() {
    const navigate = useNavigate();
    const [accounts, setAccounts] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState('');
    const [loading, setLoading] = useState(true);
    // Fetch accounts when component mounts
    const businessID = localStorage.getItem('bid');


    useEffect(() => {
        
        async function fetchAccounts() {
            try {
                const url = `http://${process.env.REACT_APP_API_BASE_URL}/api/getGoogleAccounts?businessID=${businessID}`;
                const body = {
                    businessID: businessID
                }
                const response = await axios.post(url, body, {
                    headers: {
                        'Content-Type': 'application/json', 
                    },
                });
                const data = response.data;
                console.log(response.data);
                setAccounts(data);
                setLoading(false);
                console.log("Accounts: ",accounts)
            } catch (error) {
                console.error('Error generating creatives:', error); 
            }
                    
        }
        fetchAccounts();

    }, []);

    // Function to handle account selection
    const handleSelectAccount = async (account) => {
        setSelectedAccount(account);
        const firestore = getFirestore();
        const businessID = localStorage.getItem('bid'); // Assuming you store the user ID in local storage
        const businessRef = doc(firestore, 'businesses', businessID);
        try {
            await setDoc(businessRef, { googleAdAccount: account }, { merge: true });
            console.log('Account saved to Firestore successfully');
            // Optionally redirect the user after selection or handle success
            navigate('/Dashboard');
        } catch (error) {
            console.error('Error saving account to Firestore:', error);
        }
    };

    return (
        loading ? <p>Loading...</p> :
        <div>
            <h1>Select Google Ad Account</h1>
            <ul>
                {accounts.map((account) => (
                    <li key={account}>
                        <button onClick={() => handleSelectAccount(account)}>Select {account.name}</button>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default GoogleAccountsPage;
