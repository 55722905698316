import React, { useState, useEffect } from 'react';
import './ProductSearchModal.css'
import axios
 from 'axios';
import TextInput from './TextInput';
import { CloseRounded, LinkOffRounded, LinkRounded } from '@mui/icons-material';

const ProductSearchModal = ({ showSelectProductModal, setShowSelectProductModal, selectedProduct, setSelectedProduct, searchProductText, setSearchProductText, titleText, submitFunction }) => {
  const [products, setAllProducts] = useState([])
  const [filteredResults, setFilteredResults] = useState([])


  const uid = localStorage.getItem('uid');
  const bid = localStorage.getItem('bid');

  async function loadProducts () {
    try {
      const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/getShopifyProductsWithVariants?omni_business_id=${bid}` ;
      const response = await axios.get(apiUrl);
      console.log(response.data)
      setAllProducts(response.data)
      setFilteredResults(response.data)
    } catch (error) {
      console.log('ERROR loading products Products.js', error)
    }
  }




    useEffect(() => {
      setTimeout(() => {
        loadProducts()
      }, [1000])
    }, [uid]);

    

  function filterResults () {
    let filteredResultsArray = []
    for (let i = 0; i < products.length; i++) {
      if (((products[i].product_title).toLowerCase()).includes(searchProductText.toLowerCase())) {
        filteredResultsArray.push(products[i]);
      }
    }
    setFilteredResults(filteredResultsArray)
  }

  useEffect(() => {
    filterResults();
  }, [searchProductText]); 



  

  return (
    <div className={showSelectProductModal ? "productSearchModalOverlay" : "productSearchModalOverlayHidden"} onClick={() => {setShowSelectProductModal(false)}}>
      <div className='productSearchModalOrdersContainer' onClick={(e) => e.stopPropagation()}>
        <div>

        <div className='ProductSearchModalProductSearchContainer'>
          <div className='ProductSearchModalLeftHeaderRow'>
            <span className='ProductSearchModalCloseIcon' onClick={() => {setShowSelectProductModal(false)}}><CloseRounded fontSize='medium'/></span>
            <span className='ProductSearchModalTitle'>{titleText}</span>
          </div>
          <TextInput placeholder="Search Products" type="text" value={searchProductText} onChange={setSearchProductText} widthPercent={0} widthPixels={300}/>
        </div>

        </div>
          <div  className='productSearchModalOrdersTableContainer'>
            <table className='productSearchModalOrdersTable'>
              <thead>
                <tr>
                  <td><div style={{minHeight: 40}}></div></td>
                  <td>Product</td>
                  <td>Ads</td>
                  <td>Status</td>
                  <td>Variants</td>
                  <td>Price</td>
                  <td>COGS</td>

                </tr>
              </thead>
              <tbody>
                {filteredResults.map((x) => { return ( 
                  <tr className={x.product_id == selectedProduct.product_id ? 'productSearchModalOrderRow psm_selected' : 'productSearchModalOrderRow'} key={x.product_id} onClick={() => {setSelectedProduct(x);}}>
                    <td><img className='productSearchModalOrderRowImage' src={x?.images[0].img_src}/></td>
                    <td>{(x.product_title).slice(0,30)}</td>
                    <td>2</td>
                    <td><span className={x.status == 'ACTIVE' ? 'productSearchModalOrderRowActive': 'productSearchModalOrderRowInactive'}>{x.status}</span></td>
                    <td>{((x.variants).length)}</td>
                    <td>{(x.variants[0].price * 1 || 0)?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                    <td className='productSearchModalOrderRowRightColumn'>
                      {(x.variants.cogs * 1 || 0)?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                    </td>
                  </tr>
                )})}
                  <tr className='productSearchModalOrderRowBottom'> 
                  <td colSpan={2}>
                      <div className='productSearchModalOrderRowBottomLeftButton'>
                        {filteredResults.length} Products
                      </div>
                    </td>
                    <td colSpan={5}>
                      <div className='productSearchModalOrderRowBottomRight'>
                          
                      </div>
                    </td>
                  </tr>
              </tbody>
            </table>
          </div>

          <span className='ProductSearchModalFAB' onClick={() => {submitFunction()}}>
            <LinkRounded/>
          </span>
      </div>
    </div>
  );
};

export default ProductSearchModal;
