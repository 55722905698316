import './CreateAdStep3.css';
import { getAuth, signOut } from "firebase/auth";
import react, { useState, useEffect } from 'react';
import { collection, doc, getDoc } from 'firebase/firestore';
import {db} from '../../FirebaseInit';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import ContentPasteRoundedIcon from '@mui/icons-material/ContentPasteRounded';
import CloseIcon from '@mui/icons-material/Close';
import ProductSearchModal from '../ProductSearchModal'
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { KeyboardArrowDown } from '@mui/icons-material';
import InterestsIcon from '../../assets/Icons/Interests.js'
import LocationIcon from '../../assets/Icons/Locations.js';
import BudgetIcon from '../../assets/Icons/Budget.js';
import AgeIcon from '../../assets/Icons/Age.js';
import GenderIcon from '../../assets/Icons/Gender.js';
import ScheduleIcon from '../../assets/Icons/Schedule.js';
import FacebookIcon from '../../assets/Icons/Facebook.js';
import axios from 'axios';

function CreateAdTargeting({selectedProduct, setSelectedProduct, draftID, fb_InterestSelected, fb_VerifiedInterests, fb_LocationSelected, fb_LocationSuggestions, fb_Budget, fb_Age, fb_Gender, fb_StartAtDate, fb_StartAtTime, 
  fb_AdAccount, fb_Pixel, fb_Page, createFacebookAd, setSelectedInterest, selectedInterest, setSelectedLocation, selectedLocation, setSelectedBudget, selectedBudget, setSelectedMinAge, selectedMinAge, setSelectedMaxAge, 
  selectedMaxAge, setSelectedGender, selectedGender, setSelectedStartAtDate, selectedStartAtDate, setSelectedStartAtTime, selectedStartAtTime, setSelectedFBAdAccount, selectedFBAdAccount, setSelectedFBPixel, selectedFBPixel, 
  setSelectedFBPage, selectedFBPage
}) {
  const navigate = useNavigate()
  const [interestSearch, setInterestSearch] = useState('')

  const [showInterests, setShowInterests] = useState(false)
  const [showLocations, setShowLocations] = useState(false)
  const [showBudget, setShowBudget] = useState(false)
  const [showAge, setShowAge] = useState(false)
  const [showGender, setShowGender] = useState(false)
  const [showSchedule, setShowSchedule] = useState(false)
  const [showFBAdAccount, setShowFBAdAccount] = useState(false)
  const [showFBPixel, setShowFBPixel] = useState(false)
  const [showFBPage, setShowFBPage] = useState(false)





  const [allFacebookAdAccounts, setAllFacebookAdAccounts] = useState([])
  const [allFacebookPixels, setAllFacebookPixels] = useState([])
  const [allFacebookPages, setAllFacebookPages] = useState([])

  
  useEffect(() => {
    setSelectedInterest(fb_InterestSelected);
    setSelectedLocation(fb_LocationSelected);
    setSelectedBudget(fb_Budget);
    setSelectedMinAge(fb_Age[0])
    setSelectedMaxAge(fb_Age[1])
    setSelectedGender(fb_Gender)
    setSelectedStartAtDate(fb_StartAtDate)
    setSelectedStartAtTime(fb_StartAtTime)
    setSelectedFBAdAccount(fb_AdAccount)
    setSelectedFBPixel(fb_Pixel)
    setSelectedFBPage(fb_Page)

  }, [fb_InterestSelected])

  const uid = localStorage.getItem('uid');
  const bid = localStorage.getItem('bid');


  async function getAdAccounts() {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/getAdAccountsByBID?omni_business_id=${bid}`);
    console.log(response.data)
    setAllFacebookAdAccounts(response.data)
  }

  async function getPixels() {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/getPixelsByBID?omni_business_id=${bid}`);
    console.log(response.data)
    setAllFacebookPixels(response.data)
  }

  async function getPages() {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/getPagesByBID?omni_business_id=${bid}`);
    console.log(response.data)
    setAllFacebookPages(response.data)
  }


  useEffect(() => {
    getAdAccounts();
    getPixels();
    getPages();
  }, [])


  function formatDate(dateString) {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  }

  function formatTime(dateString) {
    const options = { hour: 'numeric', minute: '2-digit', hour12: true };
    return new Date(dateString).toLocaleTimeString('en-US', options);
  }


  
  return (
    <div className='createAdStep3Container'>


      <div className='createAdStep3LeftContainer'>
        <span className='createAdStep3LeftHeader'>Advertise to the right people</span>
        <span className='createAdStep3LeftSubheader'>Find your audience...</span>
        
      </div>
        
      <div className='createAdStep3RightContainer'>
        <div className='createAdStep3RightColumnsContainer'>
          <div className='createAdStep3RightContentContainer'>

            {/* INTERESTS */}
            <div className='createAdStep3RightContentStepHeaderContainer' onClick={() => {setShowInterests(!showInterests)}}>
              <div className='createAdStep3RightContentStepHeaderLeft'>
                <InterestsIcon complete={true}/>
                <div className='createAdStep3RightContentStepHeaderLeftColumns'>
                  <span className='createAdStep3RightContentStepHeaderLeftTitle'>Interests</span>
                  <span className='createAdStep3RightContentStepHeaderLeftValue'>{selectedInterest.name}</span>
                </div>
              </div>
              <div className='createAdStep3RightContentStepHeaderRight'>
                <span className='createAdStep3RightContentStepHeaderRightAIBadge'>AI</span>
                <KeyboardArrowDown/>
              </div>
            </div>
            {showInterests ?
              <div className='createAdStep3RightVerifiedInterests'>
                <input className='createAdStep3RightTextInput' type='text' onChange={(e) => {setInterestSearch(e.target.value)}} value={interestSearch}/>
                {fb_VerifiedInterests.map((x, index) => { return (
                  <div key={index} className={selectedInterest.name == x.name ? 'createAdStep3RightVerifiedInterestOptionContainerSelected' : 'createAdStep3RightVerifiedInterestOptionContainer'} onClick={() => {setSelectedInterest(x)}}>
                    <span className='createAdStep3RightVerifiedInterestOptionName'>{(x.name).slice(0,30)}</span>
                    <span className='createAdStep3RightVerifiedInterestOptionSize'>{(x.audience_size).toLocaleString('en-US')} People</span>
                  </div>
                )})}
              </div>
              :<></>}


            
            {/* Location */}
            <div className='createAdStep3RightContentStepHeaderContainer' onClick={() => {setShowLocations(!showLocations)}}>
              <div className='createAdStep3RightContentStepHeaderLeft'>
                <LocationIcon complete={true}/>
                <div className='createAdStep3RightContentStepHeaderLeftColumns'>
                  <span className='createAdStep3RightContentStepHeaderLeftTitle'>Locations</span>
                  <span className='createAdStep3RightContentStepHeaderLeftValue'>{selectedLocation.name}</span>
                </div>
              </div>
              <div className='createAdStep3RightContentStepHeaderRight'>
                <span className='createAdStep3RightContentStepHeaderRightAIBadge'>AI</span>
                <KeyboardArrowDown/>
              </div>
            </div>
            {showLocations ?
              <div className='createAdStep3RightVerifiedInterests'>
                <input className='createAdStep3RightTextInput' type='text' onChange={(e) => {setInterestSearch(e.target.value)}} value={interestSearch}/>
                {fb_LocationSuggestions.map((x) => { return (
                  <div className={selectedLocation.name == x.name ? 'createAdStep3RightVerifiedInterestOptionContainerSelected' : 'createAdStep3RightVerifiedInterestOptionContainer'} onClick={() => {setSelectedLocation(x)}}>
                    <span className='createAdStep3RightVerifiedInterestOptionName'>{(x.name).slice(0,30)}</span>
                    <span className='createAdStep3RightVerifiedInterestOptionSize'>{x.type.charAt(0).toUpperCase() + x.type.slice(1)}</span>
                  </div>
                )})}
              </div>
              :<></>}



            {/* BUDGET */}
            <div className='createAdStep3RightContentStepHeaderContainer' onClick={() => {setShowBudget(!showBudget)}}>
              <div className='createAdStep3RightContentStepHeaderLeft'>
                <BudgetIcon complete={true}/>
                <div className='createAdStep3RightContentStepHeaderLeftColumns'>
                  <span className='createAdStep3RightContentStepHeaderLeftTitle'>Budget</span>
                  <span className='createAdStep3RightContentStepHeaderLeftValue'>{selectedBudget?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} per day</span>
                </div>
              </div>
              <div className='createAdStep3RightContentStepHeaderRight'>
                <span className='createAdStep3RightContentStepHeaderRightAIBadge'>AI</span>
                <KeyboardArrowDown/>
              </div>
            </div>
            {showBudget ?
              <div className='createAdStep3RightVerifiedInterests'>
                <input className='createAdStep3RightTextInput' type='text' onChange={(e) => {setInterestSearch(e.target.value)}} value={interestSearch}/>
                <div className={selectedBudget == 10 ? 'createAdStep3RightVerifiedInterestOptionContainerSelected' : 'createAdStep3RightVerifiedInterestOptionContainer'} onClick={() => {setSelectedBudget(10)}}>
                  <span className='createAdStep3RightVerifiedInterestOptionName'>$10.00</span>
                  <span className='createAdStep3RightVerifiedInterestOptionSize'>Per Day</span>
                </div>
                <div className={selectedBudget == 20 ? 'createAdStep3RightVerifiedInterestOptionContainerSelected' : 'createAdStep3RightVerifiedInterestOptionContainer'} onClick={() => {setSelectedBudget(20)}}>
                  <span className='createAdStep3RightVerifiedInterestOptionName'>$20.00</span>
                  <span className='createAdStep3RightVerifiedInterestOptionSize'>Per Day</span>
                </div>
                <div className={selectedBudget == 50 ? 'createAdStep3RightVerifiedInterestOptionContainerSelected' : 'createAdStep3RightVerifiedInterestOptionContainer'} onClick={() => {setSelectedBudget(50)}}>
                  <span className='createAdStep3RightVerifiedInterestOptionName'>$50.00</span>
                  <span className='createAdStep3RightVerifiedInterestOptionSize'>Per Day</span>
                </div>
                <div className={selectedBudget == 100 ? 'createAdStep3RightVerifiedInterestOptionContainerSelected' : 'createAdStep3RightVerifiedInterestOptionContainer'} onClick={() => {setSelectedBudget(100)}}>
                  <span className='createAdStep3RightVerifiedInterestOptionName'>$100.00</span>
                  <span className='createAdStep3RightVerifiedInterestOptionSize'>Per Day</span>
                </div>
                <div className={selectedBudget == 500 ? 'createAdStep3RightVerifiedInterestOptionContainerSelected' : 'createAdStep3RightVerifiedInterestOptionContainer'} onClick={() => {setSelectedBudget(500)}}>
                  <span className='createAdStep3RightVerifiedInterestOptionName'>$500.00</span>
                  <span className='createAdStep3RightVerifiedInterestOptionSize'>Per Day</span>
                </div>
                <div className={selectedBudget == 1000 ? 'createAdStep3RightVerifiedInterestOptionContainerSelected' : 'createAdStep3RightVerifiedInterestOptionContainer'} onClick={() => {setSelectedBudget(1000)}}>
                  <span className='createAdStep3RightVerifiedInterestOptionName'>$1,000.00</span>
                  <span className='createAdStep3RightVerifiedInterestOptionSize'>Per Day</span>
                </div>


              </div>
              :<></>}


              

              {/* AGE */}
              <div className='createAdStep3RightContentStepHeaderContainer' onClick={() => {setShowAge(!showAge)}}>
                <div className='createAdStep3RightContentStepHeaderLeft'>
                  <AgeIcon complete={true}/>
                  <div className='createAdStep3RightContentStepHeaderLeftColumns'>
                    <span className='createAdStep3RightContentStepHeaderLeftTitle'>Age</span>
                    <span className='createAdStep3RightContentStepHeaderLeftValue'>{selectedMinAge + "-" + selectedMaxAge} years old</span>
                  </div>
                </div>
                <div className='createAdStep3RightContentStepHeaderRight'>
                  <span className='createAdStep3RightContentStepHeaderRightAIBadge'>AI</span>
                  <KeyboardArrowDown/>
                </div>
              </div>
              {showAge ?
                <div className='createAdStep3RightVerifiedInterests'>
                  <input className='createAdStep3RightTextInput' type='text' onChange={(e) => {setInterestSearch(e.target.value)}} value={interestSearch}/>
                  <div className={selectedMinAge == 18 && selectedMaxAge == 65 ? 'createAdStep3RightVerifiedInterestOptionContainerSelected' : 'createAdStep3RightVerifiedInterestOptionContainer'} onClick={() => {setSelectedMinAge(18); setSelectedMaxAge(65)}}>
                    <span className='createAdStep3RightVerifiedInterestOptionName'>18 - 65</span>
                    <span className='createAdStep3RightVerifiedInterestOptionSize'>Years Old</span>
                  </div>
                  <div className={selectedMinAge == 21 && selectedMaxAge == 65 ? 'createAdStep3RightVerifiedInterestOptionContainerSelected' : 'createAdStep3RightVerifiedInterestOptionContainer'} onClick={() => {setSelectedMinAge(21); setSelectedMaxAge(65)}}>
                    <span className='createAdStep3RightVerifiedInterestOptionName'>21 - 65</span>
                    <span className='createAdStep3RightVerifiedInterestOptionSize'>Years Old</span>
                  </div>
                  <div className={selectedMinAge == 21 && selectedMaxAge == 45 ? 'createAdStep3RightVerifiedInterestOptionContainerSelected' : 'createAdStep3RightVerifiedInterestOptionContainer'} onClick={() => {setSelectedMinAge(21); setSelectedMaxAge(45)}}>
                    <span className='createAdStep3RightVerifiedInterestOptionName'>21 - 45</span>
                    <span className='createAdStep3RightVerifiedInterestOptionSize'>Years Old</span>
                  </div>
                  <div className={selectedMinAge == 18 && selectedMaxAge == 30 ? 'createAdStep3RightVerifiedInterestOptionContainerSelected' : 'createAdStep3RightVerifiedInterestOptionContainer'} onClick={() => {setSelectedMinAge(18); setSelectedMaxAge(30)}}>
                    <span className='createAdStep3RightVerifiedInterestOptionName'>18 - 30</span>
                    <span className='createAdStep3RightVerifiedInterestOptionSize'>Years Old</span>
                  </div>
                  <div className={selectedMinAge == 30 && selectedMaxAge == 40 ? 'createAdStep3RightVerifiedInterestOptionContainerSelected' : 'createAdStep3RightVerifiedInterestOptionContainer'} onClick={() => {setSelectedMinAge(30); setSelectedMaxAge(40)}}>
                    <span className='createAdStep3RightVerifiedInterestOptionName'>30 - 40</span>
                    <span className='createAdStep3RightVerifiedInterestOptionSize'>Years Old</span>
                  </div>
                  <div className={selectedMinAge == 40 && selectedMaxAge == 50 ? 'createAdStep3RightVerifiedInterestOptionContainerSelected' : 'createAdStep3RightVerifiedInterestOptionContainer'} onClick={() => {setSelectedMinAge(40); setSelectedMaxAge(50)}}>
                    <span className='createAdStep3RightVerifiedInterestOptionName'>40 - 50</span>
                    <span className='createAdStep3RightVerifiedInterestOptionSize'>Years Old</span>
                  </div>
                  <div className={selectedMinAge == 50 && selectedMaxAge == 60 ? 'createAdStep3RightVerifiedInterestOptionContainerSelected' : 'createAdStep3RightVerifiedInterestOptionContainer'} onClick={() => {setSelectedMinAge(50); setSelectedMaxAge(60)}}>
                    <span className='createAdStep3RightVerifiedInterestOptionName'>50 - 60</span>
                    <span className='createAdStep3RightVerifiedInterestOptionSize'>Years Old</span>
                  </div>
                </div>
                :<></>}





              {/* GENDER */}
              <div className='createAdStep3RightContentStepHeaderContainer' onClick={() => {setShowGender(!showGender)}}>
                <div className='createAdStep3RightContentStepHeaderLeft'>
                  <GenderIcon complete={true}/>
                  <div className='createAdStep3RightContentStepHeaderLeftColumns'>
                    <span className='createAdStep3RightContentStepHeaderLeftTitle'>Gender</span>
                    <span className='createAdStep3RightContentStepHeaderLeftValue'>{selectedGender} </span>
                  </div>
                </div>
                <div className='createAdStep3RightContentStepHeaderRight'>
                  <span className='createAdStep3RightContentStepHeaderRightAIBadge'>AI</span>
                  <KeyboardArrowDown/>
                </div>
              </div>
              {showGender ?
                <div className='createAdStep3RightVerifiedInterests'>
                  <div className={selectedGender == 'All' ? 'createAdStep3RightVerifiedInterestOptionContainerSelected' : 'createAdStep3RightVerifiedInterestOptionContainer'} onClick={() => {setSelectedGender('All')}}>
                    <span className='createAdStep3RightVerifiedInterestOptionName'>All</span>
                    <span className='createAdStep3RightVerifiedInterestOptionSize'></span>
                  </div>
                  <div className={selectedGender == 'Men' ? 'createAdStep3RightVerifiedInterestOptionContainerSelected' : 'createAdStep3RightVerifiedInterestOptionContainer'} onClick={() => {setSelectedGender('Men')}}>
                    <span className='createAdStep3RightVerifiedInterestOptionName'>Men</span>
                    <span className='createAdStep3RightVerifiedInterestOptionSize'></span>
                  </div>
                  <div className={selectedGender == 'Women' ? 'createAdStep3RightVerifiedInterestOptionContainerSelected' : 'createAdStep3RightVerifiedInterestOptionContainer'} onClick={() => {setSelectedGender('Women')}}>
                    <span className='createAdStep3RightVerifiedInterestOptionName'>Women</span>
                    <span className='createAdStep3RightVerifiedInterestOptionSize'></span>
                  </div>
                </div>
                :<></>}




              {/* SCHEDULE AD */}
              <div className='createAdStep3RightContentStepHeaderContainer' onClick={() => {setShowSchedule(!showSchedule)}}>
                <div className='createAdStep3RightContentStepHeaderLeft'>
                  <ScheduleIcon complete={true}/>
                  <div className='createAdStep3RightContentStepHeaderLeftColumns'>
                    <span className='createAdStep3RightContentStepHeaderLeftTitle'>Schedule</span>
                    <span className='createAdStep3RightContentStepHeaderLeftValue'>{formatDate(selectedStartAtDate)} at {selectedStartAtTime} </span>
                  </div>
                </div>
                <div className='createAdStep3RightContentStepHeaderRight'>
                  <KeyboardArrowDown/>
                </div>
              </div>
              {showSchedule ?
                <div className='createAdStep3RightVerifiedInterests'>
                  <div className={selectedBudget == 10 ? 'createAdStep3RightVerifiedInterestOptionContainerSelected' : 'createAdStep3RightVerifiedInterestOptionContainer'} onClick={() => {setSelectedBudget(10)}}>
                    <span className='createAdStep3RightVerifiedInterestOptionName'></span>
                    <span className='createAdStep3RightVerifiedInterestOptionSize'></span>
                  </div>
                </div>
                :<></>}




              {/* FACEBOOK AD ACCOUNT */}
              <div className='createAdStep3RightContentStepHeaderContainer' onClick={() => {setShowFBAdAccount(!showFBAdAccount)}}>
                <div className='createAdStep3RightContentStepHeaderLeft'>
                  <FacebookIcon complete={true}/>
                  <div className='createAdStep3RightContentStepHeaderLeftColumns'>
                    <span className='createAdStep3RightContentStepHeaderLeftTitle'>Facebook Ad Account</span>
                    <span className='createAdStep3RightContentStepHeaderLeftValue'>{selectedFBAdAccount?.name} </span>
                  </div>
                </div>
                <div className='createAdStep3RightContentStepHeaderRight'>
                  <KeyboardArrowDown/>
                </div>
              </div>
              {showFBAdAccount ?
                <div className='createAdStep3RightVerifiedInterests'>
                    {allFacebookAdAccounts.map((x, index) => { return ( 
                      <div key={index} className={selectedFBAdAccount.account_id == x.account_id ? 'createAdStep3RightVerifiedInterestOptionContainerSelected' : 'createAdStep3RightVerifiedInterestOptionContainer'} onClick={() => {setSelectedFBAdAccount(x)}}>
                        <span className='createAdStep3RightVerifiedInterestOptionName'>{x.name}</span>
                        <span className='createAdStep3RightVerifiedInterestOptionSize'></span>
                      </div>
                    )})}
                   
                </div>
                :<></>}




              {/* FACEBOOK PIXEL */}
              <div className='createAdStep3RightContentStepHeaderContainer' onClick={() => {setShowFBPixel(!showFBPixel)}}>
                <div className='createAdStep3RightContentStepHeaderLeft'>
                  <FacebookIcon complete={true}/>
                  <div className='createAdStep3RightContentStepHeaderLeftColumns'>
                    <span className='createAdStep3RightContentStepHeaderLeftTitle'>Facebook Pixel</span>
                    <span className='createAdStep3RightContentStepHeaderLeftValue'>{selectedFBPixel?.pixel_name} </span>
                  </div>
                </div>
                <div className='createAdStep3RightContentStepHeaderRight'>
                  <KeyboardArrowDown/>
                </div>
              </div>
              {showFBPixel ?
                <div className='createAdStep3RightVerifiedInterests'>
                  {allFacebookPixels.map((x, index) => { return ( 
                    <div key={index} className={selectedFBPixel.pixel_id == x.pixel_id ? 'createAdStep3RightVerifiedInterestOptionContainerSelected' : 'createAdStep3RightVerifiedInterestOptionContainer'} onClick={() => {setSelectedFBPixel(x)}}>
                      <span className='createAdStep3RightVerifiedInterestOptionName'>{x.pixel_name}</span>
                      <span className='createAdStep3RightVerifiedInterestOptionSize'></span>
                    </div>
                  )})}
               
                </div>
              :<></>}




              {/* FACEBOOK PAGE */}
              <div className='createAdStep3RightContentStepHeaderContainer' onClick={() => {setShowFBPage(!showFBPage)}}>
                <div className='createAdStep3RightContentStepHeaderLeft'>
                  <FacebookIcon complete={true}/>
                  <div className='createAdStep3RightContentStepHeaderLeftColumns'>
                    <span className='createAdStep3RightContentStepHeaderLeftTitle'>Facebook Page</span>
                    <span className='createAdStep3RightContentStepHeaderLeftValue'>{selectedFBPage?.name} </span>
                  </div>
                </div>
                <div className='createAdStep3RightContentStepHeaderRight'>
                  <KeyboardArrowDown/>
                </div>
              </div>
              {showFBPage ?
                <div className='createAdStep3RightVerifiedInterests'>
                     {allFacebookPages.map((x, index) => { return ( 
                    <div key={index} className={selectedFBPage.id == x.id ? 'createAdStep3RightVerifiedInterestOptionContainerSelected' : 'createAdStep3RightVerifiedInterestOptionContainer'} onClick={() => {setSelectedFBPage(x)}}>
                      <span className='createAdStep3RightVerifiedInterestOptionName'>{x.name}</span>
                      <span className='createAdStep3RightVerifiedInterestOptionSize'></span>
                    </div>
                  )})}
                </div>
                :<></>}






          </div>    
        </div>

        <div className='createAdStep3BottomActionButtonsContainer'>
          <span className='createAdStep3BottomActionButtonSaveDraft'>Save & Exit</span>
          <span className='createAdStep3BottomActionButtonContinue' onClick={() => {createFacebookAd()}}>Continue</span>
        </div>

      </div>
    </div>
  );
}

export default CreateAdTargeting;