import React from 'react'

function FolderIcon({}) {
    return ( 

        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
            <path d="M33.5999 6.99023H4.3999C3.29533 6.99023 2.3999 7.88566 2.3999 8.99023V22.5902C2.3999 23.6948 3.29533 24.5902 4.3999 24.5902H33.5999C34.7045 24.5902 35.5999 23.6948 35.5999 22.5902V8.99023C35.5999 7.88566 34.7045 6.99023 33.5999 6.99023Z" fill="#F9B552"/>
            <path d="M35.6 10.1902H4.4C1.96995 10.1902 0 12.1602 0 14.5902V32.5902C0 35.0203 1.96995 36.9902 4.4 36.9902H35.6C38.0301 36.9902 40 35.0203 40 32.5902V14.5902C40 12.1602 38.0301 10.1902 35.6 10.1902Z" fill="#FFCF5C"/>
            <path d="M15.6 2.99023H4.4C1.96995 2.99023 0 4.96018 0 7.39023V13.7902C0 16.2203 1.96995 18.1902 4.4 18.1902H15.6C18.0301 18.1902 20 16.2203 20 13.7902V7.39023C20 4.96018 18.0301 2.99023 15.6 2.99023Z" fill="#FFCF5C"/>
        </svg>

     );
}

export default FolderIcon;