
import { BrowserRouter, Route, Routes, Navigate, NavLink ,useLocation} from 'react-router-dom';
import SignUp from './pages/SignUp';
import ProcessFacebookRedirect from './pages/ProcessFacebookRedirect';
import LogIn from './pages/LogIn';
import Dashboard from './pages/Dashboard';
import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Settings from './pages/Settings';
import Orders from './pages/Orders';
import Products from './pages/Products';
import Ads from './pages/AdsManager/Ads';
import Analytics from './pages/Analytics';
import Alerts from './pages/Alerts';
import Interests from './pages/Interests';
import CreateAd from './pages/AdsManager/CreateAd';
import Header from './components/Header';
import ProcessGoogleRedirect from './pages/ProcessGoogleRedirect';
import GoogleAccountsPage from './pages/SelectGoogleAdAccount';
import Files from './pages/Files';
import CreateAd2 from './pages/AdsManager/CreateAd2';
import NavbarAndDrawer from './components/NavbarAndDrawer';





function App() {
  const [authenticated, setAuthenticated] = useState(true);
  const [loading, setLoading] = useState(true);
  const [uid, setUid] = useState('')
  const [path, setPath] = useState(window.location.pathname);


  const serverURL = 'https://api.omnimedia.app';
  // const serverURL = 'http://${process.env.REACT_APP_API_BASE_URL}';









  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        setUid(user.uid);
        setAuthenticated(true);
      } else {
        // User is signed out
        setAuthenticated(false);
      }
      setLoading(false);
    });

    // Clean up the subscription
    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Show a loading state while checking authentication
  }




  return (
    
    <BrowserRouter>
      <NavbarAndDrawer collapsed={false}>
        <Routes>
          <Route path="/" element={authenticated ? <Navigate to="/Dashboard" /> : <SignUp />} />
          <Route path="/Login" element={<LoginRoute authenticated={authenticated} />} />
          {/* <Route path="/Login" element={authenticated ? <Navigate to="/Dashboard" /> : <LogIn />} /> */}
          <Route path="/Signup" element={<SignUp />} />
          <Route path="/Dashboard"  element={authenticated ? <Dashboard serverURL={serverURL}/> : <Navigate to="/LogIn" />} />
          <Route path="/Settings"  element={authenticated ? <Settings/> : <Navigate to="/LogIn" />} />
          <Route path="/process-google-redirect" element={<ProcessGoogleRedirect />} />
          <Route path="/process-facebook-redirect" element={<ProcessFacebookRedirect />} />
          <Route path="/google-accounts" element={<GoogleAccountsPage />} />
          <Route path="/Orders"  element={authenticated ? <Orders/> : <Navigate to="/LogIn" />} />
          <Route path="/Products"  element={authenticated ? <Products/> : <Navigate to="/LogIn" />} />
          <Route path="/Ads"  element={authenticated ? <Ads serverURL={serverURL}/> : <Navigate to="/LogIn" />} />
          <Route path="/Create"  element={authenticated ? <CreateAd2/> : <Navigate to="/LogIn" />} />
          <Route path="/CreateAd"  element={authenticated ? <CreateAd/> : <Navigate to="/LogIn" />} />
          <Route path="/Analytics"  element={authenticated ? <Analytics/> : <Navigate to="/LogIn" />} />
          <Route path="/Alerts"  element={authenticated ? <Alerts/> : <Navigate to="/LogIn" />} />
          <Route path="/Interests"  element={authenticated ? <Interests/> : <Navigate to="/LogIn" />} />
          <Route path="/Files"  element={authenticated ? <Files/> : <Navigate to="/LogIn" />} />
        </Routes>
      </NavbarAndDrawer>
    </BrowserRouter>
  )
}

const LoginRoute = ({ authenticated }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const user_token = searchParams.get('firebase_token');
  return authenticated ? <Navigate to="/Dashboard" /> : <LogIn user_token={user_token} />;
};

export default App;
