import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '20px 20px', 
  },
  layoutFlex: {
    flexFlow: 'row',
  },
  layoutGrid: {
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconWrapper: {
    borderRadius: '100px',
    padding: '5px',
    height: '50px',
    width: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const IconBox = ({ icon, heading, value, iconColor, iconBackgroundColor, iconSize, boxBackgroundColor, iconWrapperStyles, layoutClass, iconSpacing, headingColor, headingSize, subTextColor, subTextAlign, contentWrapperStyles }) => {
  const classes = useStyles();

  const layout = {
    layoutFlex: classes.layoutFlex,
    layoutGrid: classes.layoutGrid,
  };

  // Check if value is not null or undefined and not an empty string
  const shouldRender = value !== undefined && value !== null && value !== '';

  return (
    <>
      {shouldRender && (
        <Box className={`${classes.iconContainer} ${layout[layoutClass]}`} style={{ backgroundColor: boxBackgroundColor }}>
          <Box className={classes.iconWrapper} style={{ backgroundColor: iconBackgroundColor, ...iconWrapperStyles }}>
            {React.cloneElement(icon, { className: classes.icon, style: { color: iconColor, fontSize: iconSize, marginRight: iconSpacing } })}
          </Box>
          <Box style={{...contentWrapperStyles }}>
            <Typography sx={{ fontSize: '18px', fontWeight: '600', color: '#6d6d6d' }} style={{ color: headingColor, fontSize: headingSize }}>{value}</Typography>
            <Typography sx={{ fontSize: '12px', color: '#000', fontWeight: '400' }} style={{ color: subTextColor, textAlign: subTextAlign }}>{heading}</Typography>
          </Box>
        </Box>
      )}
    </>
  );
};

export default IconBox;