import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFirestore, doc, updateDoc, setDoc, getDoc } from 'firebase/firestore';

const clientId = '47545590936-vavi3du605spbtdbpjqjvelf4v973jb8.apps.googleusercontent.com';
const redirectUri = `http://${process.env.REACT_APP_API_BASE_URL}/process-google-redirect`;
const firestore = getFirestore();
const businessID = localStorage.getItem('bid');
const userID = localStorage.getItem('uid');




function ProcessGoogleRedirect() {
    const navigate = useNavigate();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        
        if (code) {
            saveCodeToDB(code);
        } else {
            // If no code is present, redirect to Google auth
            redirectToGoogleAuth();
        }
    }, []); 

    function redirectToGoogleAuth() {
        const scope = encodeURIComponent('https://www.googleapis.com/auth/adwords');
        const state = encodeURIComponent(Math.random().toString(36).substring(2, 15));
        const url = `https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?scope=${scope}&access_type=offline&include_granted_scopes=true&response_type=code&state=${state}&redirect_uri=${redirectUri}&client_id=${clientId}&flowName=GeneralOAuthFlow`;
        window.location.href = url;
    }
    

    async function saveCodeToDB(code) {
        
        
        console.log('businessID:', businessID);
        const businessRef = doc(firestore, 'businesses', businessID);

        try {
            const docSnapshot = await getDoc(businessRef);
            if (docSnapshot.exists()) {
                await updateDoc(businessRef, {
                    googleOAuthCode: code,
                    googleOAuthURL: redirectUri,
                    googleOAuthAccessTokenNeverUsed: true
                });
            
                console.log('Code updated in Firestore successfully');
            } else {
                await setDoc(businessRef, {
                    googleOAuthCode: code,
                    googleOAuthURL: redirectUri,
                    googleOAuthAccessTokenNeverUsed: true
                });
                console.log('Code saved to Firestore successfully');
            }
            await fetchGoogleRefreshToken();

            navigate('/google-accounts');
        } catch (error) {
            console.error('Error saving/updating code in Firestore:', error);
        }
    }
    async function fetchGoogleRefreshToken() {
         const businessRef = doc(firestore, 'businesses', businessID);

        try {
            const response = await fetch(`http://${process.env.REACT_APP_API_BASE_URL}/getGoogleRefreshToken`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userID: userID })
            });
            const data = await response.json();
            console.log('Token fetched:', data);
            await setDoc(businessRef, {
                googleOAuthAccessTokenNeverUsed: false,
            });
            // Handle additional logic here if needed (e.g., navigate to another route, store data)
        } catch (error) {
            console.error('Error fetching Google refresh token:', error);
        }
    }
    

    return <div>Loading...</div>;
}

export default ProcessGoogleRedirect;
