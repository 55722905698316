

import React, { useEffect, useState } from 'react';
import './createType.css';
import axios from 'axios';
import TextInput from '../../components/TextInput';



function CreateType({useAI, setUseAI, selectedProduct, setSelectedProduct, createDraft, draftID, saveManualDraft}) {
    const uid = localStorage.getItem('uid');
    const bid = localStorage.getItem('bid');
    const [showSelectProduct, setShowSelectProduct] = useState(false)
    const [productSearchText, setProductSearchText] = useState('')
    const [productList, setProductList] = useState([])
    const [filteredProductList, setFilteredProductList] = useState([])
    const [processing, setProcessing] = useState(false)




    async function loadProducts () {
        try {
          const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/getShopifyProducts?omni_business_id=${bid}` ;
          const response = await axios.get(apiUrl);
          console.log(response.data)
          setProductList(response.data)
          setFilteredProductList(response.data)
        } catch (error) {
          console.log('ERROR loading products Products.js', error)
        }
    }
    useEffect(() => {
        if (bid !== '') {
            loadProducts()
        }
    }, [bid]);



    useEffect(() => {
        if (productSearchText !== '') {
          const filteredProducts = productList.filter(x => x.product_title.toLowerCase().includes(productSearchText.toLowerCase()))
          setFilteredProductList(filteredProducts)
        } else {
          setFilteredProductList(productList)
        }
    }, [productSearchText])








    return (
        <div className={draftID == '' ? 'createTypeContainer' : 'createTypeContainer hidden'}>
           
            {showSelectProduct == false && !processing && (
            <div className='createTypeContentContainer '>
                <div className='createTypeContentLeft'>
                    <span className='createTypeContentLeftStep'>Step 1</span>
                    <span className='createTypeContentLeftTitle'>Select the method of creation</span>
                    <span className='createTypeContentLeftDescription'>Omni AI uses advanced artificial intelligence algorithms to completely develop an ad structure for you. This is the easiest way to create an ad and is proven to perform the best.</span>
                </div>
                <div className='createTypeContentRightTop'>
                    <div className='createTypeContentRightOptionContainer' onClick={() => {setUseAI(true); setShowSelectProduct(true)}}>
                        <div>(OMNI AI GRAPHIC)</div>
                        Omni AI
                    </div>
                    <div className='createTypeContentRightOptionContainer' onClick={() => {setUseAI(false); saveManualDraft()}}>
                        <div>(MANUAL GRAPHIC)</div>
                        Manual Creation
                    </div>
                </div>
            </div>
            )}


            {showSelectProduct == true && !processing && (
            <div className='createTypeContentContainer '>
                <div className='createTypeContentLeft'>
                    <span className='createTypeContentLeftStep'>Step 2</span>
                    <span className='createTypeContentLeftTitle'>Select a Shopify product</span>
                    <span className='createTypeContentLeftDescription'>Select a Shopify product to make a Facebook Ad. This allows Omni AI to get all of the product information and make the best performing ad possible.</span>
                </div>
                <div className='createTypeContentRightBottom'>
                    <TextInput placeholder='Search for a product' type='text' value={productSearchText} onChange={setProductSearchText} widthPercent={100} marginBottom='20px'/>
                    {filteredProductList.map((x, index) => (
                        <div className='createTypeContentRightProductContainer' onClick={() => {setProcessing(true); setSelectedProduct(x); setShowSelectProduct(false); createDraft(x)}}>
                            <img className='createTypeContentRightProductImage' src={x?.img_src}/>
                            <span className='createTypeContentRightProductTitle'>{x.product_title.slice(0,40)}{x.product_title.length > 40 ? '...' : ''}</span>
                            <span className={x.status == 'ACTIVE' ? 'createTypeContentRightProductActive': 'createTypeContentRightProductInactive'}>{x.status}</span>
                        </div>
                    ))}
                </div>
            </div>
            )}


            {processing && (
                <div className='createTypeContentContainer '>
                    <div className='createTypeContentLeft'>
                        <span className='createTypeContentLeftStep'>Processing</span>
                        <span className='createTypeContentLeftTitle'>Creating your ad</span>
                        <span className='createTypeContentLeftDescription'>Omni AI is creating your ad. This may take a moment.</span>
                    </div>
                    <div className='createTypeContentRightBottomProcessing'>
                        <div className='createTypeContentRightProductContainer'>
                            <img className='createTypeContentRightProductImage' src={selectedProduct?.img_src}/>
                            <span className='createTypeContentRightProductTitle'>{selectedProduct.product_title.slice(0,40)}{selectedProduct.product_title.length > 40 ? '...' : ''}</span>
                            <span className={selectedProduct.status == 'ACTIVE' ? 'createTypeContentRightProductActive': 'createTypeContentRightProductInactive'}>{selectedProduct.status}</span>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}





export default CreateType