import '../App.css';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import react, { useState } from 'react';
import { addDoc, collection, doc, setDoc } from 'firebase/firestore';
import {db} from '../FirebaseInit';
import { useNavigate } from 'react-router-dom';
import OmniLoginImage from '../assets/OmniLogin.png';



function SignUp() {
    const navigate = useNavigate()

    const [step, setStep] = useState(0);

    const [email, setEmail] = useState('a@b.com');
    const [password, setPassword] = useState('password');
  
    const [businessName, setBusinessName] = useState('')
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');


    function generateRandomId(type) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      if (type == 'uid') {
        result = "u_";
      } else {
        result = "b_";
      }
      const charactersLength = characters.length;
      for (let i = 0; i < 30; i++) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
  }
  
  
    async function signUp () {
      const auth = getAuth();
      createUserWithEmailAndPassword(auth, email, password)
        .then(async (userCredential) => {
          // Signed in
          setStep(1)
          const user = userCredential.user;
          const userId = "u_" + user.uid;
          console.log("UID: " + userId)
          localStorage.setItem('uid', userId);
          const usersCollection = collection(db, 'users');
          await setDoc(doc(usersCollection, userId), {
            firstName: '',
            lastName: '',
            userID: userId,
            createdAt: new Date(),
            email: email,
            type: 'emailAuth',
            lastUsedFacebookAdAccount: {},
            lastUsedFacebookPixel: {},
            lastUsedFacebookPage: {},
          });


          const businessID = generateRandomId('bid');
          localStorage.setItem('bid', businessID);

          const businessCollection = doc(db, 'businesses', businessID);
          setDoc(businessCollection, {
              businessName: '',
              userIDs: [userId],
              createdAt: new Date(),
              businessEmail: email,
              ownerFirstName: '',
              ownerLastName: '',
              googleAdAccount: [],
              googleOAuthAccessTokenNeverUsed: true,
              googleOAuthCode: '',
              googleOAuthURL: '',
              refreshToken: '',
              facebookOAuthCode: '',
              facebookOAuthURL: '',
              facebookAccessToken: '',
              facebookAccessTokenCreatedAt: '',
              facebookAccessTokenNeverUsed: true,
              shopifyAdminAccessToken: '',
              shopifyAdminAPIKey: '',
              shopifyAdminAPISecretKey: '',
              shopifyDomain: '',
          })
        
          console.log("BID: ", businessID);

          const userDocRef = doc(db, 'users', userId);
          await setDoc(userDocRef, {
            businessID: businessID,
          }, { merge: true });
         
          



    
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorMessage)
          // ..
        });
      }
  
  
    async function updateName () {
      const auth = getAuth();
      const user = auth.currentUser;
  
      if (user) {
        const userId = localStorage.getItem('uid');
        const bid = localStorage.getItem('bid');

        try {

          const userDocRef = doc(db, 'users', userId);
          // Update the user's first name and last name
          await setDoc(userDocRef, {
            firstName: firstName,
            lastName: lastName,
          }, { merge: true }); // Use merge option to update only the specified fields


          const businessDocRef = doc(db, 'businesses', bid);
          // Update the user's first name and last name
          await setDoc(businessDocRef, {
            businessName: businessName,
            ownerFirstName: firstName,
            ownerLastName: lastName,
          }, { merge: true });

          setStep(2)
          console.log('User name updated successfully');
        } catch (error) {
          console.error('Error updating user name:', error);
        }
      } else {
        console.error('User not authenticated'); // Handle the case where the user is not authenticated
      }
    }
  
  
    async function linkFacebook () {
      try {
        const currentUrl = window.location.href;
        const baseUrl = currentUrl.split('/')[0] + '//' + currentUrl.split('/')[1] + currentUrl.split('/')[2] + '/process_facebook_redirect';
        const redirectUri = encodeURIComponent(baseUrl);
        // Redirect the user to the Facebook login page
        const URL = `https://www.facebook.com/v13.0/dialog/oauth?client_id=854522159611705&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fprocess_facebook_redirect&config_id=1103636130676104&response_type=code&scope=ads_read,ads_management,business_management`
        const redirectUrl = `https://www.facebook.com/v13.0/dialog/oauth?client_id=854522159611705&redirect_uri=${redirectUri}&config_id=1103636130676104&response_type=code&scope=ads_read,ads_management,business_management`;
        window.location.href = redirectUrl;
      } catch (error) {
        // Handle any errors
        console.error('Error logging in with Facebook:', error);
      }
    }
  
  
  
    return (
      // <div className="container">
      //   <div className='loginContainer'>
      //     {step == 0 ?
      //       <div className='loginSubContainer'>
      //         <span className='welcomeText'>Welcome to Omni!</span>
      //         <span className='welcomeSubText'>Create an account</span>
      //         <input className='loginInput' type='email' placeholder='Email' value={email} onChange={(event) => setEmail(event.target.value)}/>
      //         <input className='loginInput' type='password' placeholder='Password' value={password} onChange={(event) => setPassword(event.target.value)}/>
      //         <button className='buttonContainer' onClick={() => {signUp()}}>Continue</button>
      //         <span>If you already have an account, <a href='Login'>Log in</a> here</span>

      //       </div>
      //     : 
      //       <div></div>
      //     }
  
      //     {step == 1 ?
      //       <div className='loginSubContainer'>
      //         <span className='welcomeText'>Welcome to Omni!</span>
      //         <span className='welcomeSubText'>Add your account details</span>
      //         <input className='loginInput' type='text' placeholder='Business Name' value={businessName} onChange={(event) => setBusinessName(event.target.value)}/>
      //         <input className='loginInput' type='text' placeholder='First Name' value={firstName} onChange={(event) => setFirstName(event.target.value)}/>
      //         <input className='loginInput' type='text' placeholder='Last Name' value={lastName} onChange={(event) => setLastName(event.target.value)}/>
      //         <button className='buttonContainer' onClick={() => {updateName()}}>Continue</button>
      //       </div>
      //     : 
      //       <div></div>
      //     }
  
      //     {step == 2 ?
      //       <div className='loginSubContainer'>
      //         <span className='welcomeText'>Welcome to Omni!</span>
      //         <span className='welcomeSubText'>Link your Facebook</span>
      //         <button className='facebookButtonContainer' onClick={() => {linkFacebook()}}>Connect Facebook</button>
      //       </div>
      //     : 
      //       <div></div>
      //     }
  
  
      //   </div>
      // </div>





      <div className='loginContainer'>
        <div className='loginLeft'>
          <span className='loginLeftLogo'>Omni AI</span>
          <span className='loginWelcomeText'>Hi, Welcome to Omni AI</span>
          <img src={OmniLoginImage}/>
        </div>
        <div className='loginRight'>

          {step == 0 && (
            <div className='loginSubContainer'>
              <span className='loginRightText'>Create an Omni AI account</span>
              <span className='loginRightCreateText'>Already a user? <span className='loginRightCreateLink' onClick={() => {navigate('/Login')}}>Log in</span></span>
              <input className='loginInput' type='email' placeholder='Email' value={email} onChange={(event) => setEmail(event.target.value)}/>
              <input className='loginInput' type='password' placeholder='Password' value={password} onChange={(event) => setPassword(event.target.value)}/>
              <button className='buttonContainer' onClick={() => {signUp()}}>Sign up</button>
            </div>
          )}


          {step == 1 && (
            <div className='loginSubContainer'>
              <span className='welcomeText'>Welcome to Omni!</span>
              <span className='welcomeSubText'>Add your account details</span>
              <input className='loginInput' type='text' placeholder='Business Name' value={businessName} onChange={(event) => setBusinessName(event.target.value)}/>
              <input className='loginInput' type='text' placeholder='First Name' value={firstName} onChange={(event) => setFirstName(event.target.value)}/>
              <input className='loginInput' type='text' placeholder='Last Name' value={lastName} onChange={(event) => setLastName(event.target.value)}/>
              <button className='buttonContainer' onClick={() => {updateName()}}>Continue</button>
            </div>
          )}


          {step == 2 && (
            <div className='loginSubContainer'>
              <span className='welcomeText'>Welcome to Omni!</span>
              <span className='welcomeSubText'>Link your Facebook</span>
              <button className='facebookButtonContainer' onClick={() => {linkFacebook()}}>Connect Facebook</button>
            </div>
          )}


        </div>
      </div>
    );
}

export default SignUp;














