import './CreateAdStep1.css';
import { getAuth, signOut } from "firebase/auth";
import react, { useState, useEffect } from 'react';
import { collection, doc, getDoc } from 'firebase/firestore';
import {db} from '../../FirebaseInit';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import ContentPasteRoundedIcon from '@mui/icons-material/ContentPasteRounded';
import CloseIcon from '@mui/icons-material/Close';
import ProductSearchModal from '../ProductSearchModal'
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import axios from 'axios';
import { BoltRounded, SearchRounded } from '@mui/icons-material';
import ReactSwitch from 'react-switch';

function CreateAdSelectProduct({selectedProduct, setSelectedProduct, setDraftID, advantagePlusCreative, setAdvantagePlusCreative}) {

  const [searchProductText, setSearchProductText] = useState('')
  const [products, setProducts] = useState([])
  const [showSelectProductModal, setShowSelectProductModal] = useState(false)
  

  let fetchProductsCount = 0;

  const uid = localStorage.getItem('uid');

  useEffect(() => {
    if (selectedProduct != '') {
      //setProductImages(selectedProduct.node.images.nodes)
    }
  }, [selectedProduct])

  useEffect(() => {
    if (fetchProductsCount < 1) {
      loadProducts();
      fetchProductsCount++;
    }
  }, [])


  async function loadProducts () {
    try {
      const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/getShopifyProducts?uid=${uid}&limit=${250}` ;
      const response = await axios.get(apiUrl);
      const products = response.data
      console.log(products.products)
      setProducts(products.products)
    } catch (error) {
      console.log('ERROR loading products Products.js', error)
    }
  }


return (
  <div className='createAdStep1Container'>


    <div className='createAdStep1LeftContainer'>
      <span className='createAdStep1LeftHeader'>Shopify Product</span>
      <span className='createAdStep1LeftSubheader'>Type your product name...</span>
      
    </div>
    
 
  

    <div className='createAdStep1RightContainer'>
      
      <div className='createAdStep1RightAdvantagePlusRow'>
        {/* <ReactSwitch 
          onChange={(checked) => setAdvantagePlusCreative(checked)}
          checked={advantagePlusCreative}
          offColor="#d9d9d9" onColor="#26cf5c" uncheckedIcon={false} checkedIcon={false}
          size='small'/> */}

        <span className='createAdStep1RightAdvantagePlusCreativeText'><BoltRounded fontSize='small'/> Advantage+ Campaign</span>
        <span className='createAdStep1RightDynamicCreativeText'>Dynamic Creative</span>
        <span className='createAdStep1RightBudgetTypeText'>Campaign Budget Optimization</span>


      </div>

      {selectedProduct == '' ?
        <div className='createAdStep1RightContentContainer'>
          <div className='createAdStep1RightSearchProductsButton' onClick={() => {setShowSelectProductModal(true)}}>
            <SearchRounded className='createAdStep1RightSearchProductsButtonIcon'/>
            <span className='createAdStep1RightSearchProductsButtonText'>Select a product</span>
          </div>
        </div>
      :
        <div className='createAdStep1SelectedProductContainer'>
          <CloseIcon className='createAdStep1SelectedProductDeleteIcon' onClick={() => {setSelectedProduct(''); setDraftID('')}}/>
          <img className='createAdStep1SelectedProductImage' src={selectedProduct?.images[0]?.img_src}/>
          <div className='createAdStep1SelectedProductRightColumnsContainer'>
              <span className=''>{selectedProduct.product_title} <span className='createAdStep1SelectedProductActive'>{selectedProduct.status}</span></span>
              {/* {true == false && selectedProduct.node.priceRange.minVariantPrice.amount != selectedProduct.node.priceRange.maxVariantPrice.amount ?
                <span className=''>{((selectedProduct.node.priceRange.minVariantPrice.amount) / 100)?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} - {((selectedProduct.node.priceRange.maxVariantPrice.amount) / 100)?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>
              :
                <span className=''>{((selectedProduct.node.priceRange.minVariantPrice.amount) / 100)?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>
              } */}
              <span className=''>{(selectedProduct.variants).length} {(selectedProduct.variants).length == 1 ? 'Variant' : 'Variants'}</span>
            </div>
        </div>
      }
    </div>


    <ProductSearchModal 
      showSelectProductModal={showSelectProductModal} setShowSelectProductModal={setShowSelectProductModal} 
      products={products} selectedProduct={selectedProduct} setSelectedProduct={setSelectedProduct} 
      searchProductText={searchProductText} setSearchProductText={setSearchProductText}>
      <h2>Search Shopify Products</h2>
    </ProductSearchModal>




  {/*
  <div>
    <div className=''>
      {selectedProduct == '' ?
        <span className='' onClick={() => {setShowSelectProduct(true); loadProducts()}}/>
      :
        <>
          <span className=''></span>
          <div className=''>
            <CloseIcon className='' onClick={() => {setSelectedProduct('')}}/>
            <img className='' src={selectedProduct?.node.images?.nodes[0]?.src} />
            <div className=''>
              <span className=''>{selectedProduct.node.title} <span className='adsCreatingAdSelectedProductStatus'>{selectedProduct.node.status}</span></span>
              {selectedProduct.node.priceRange.minVariantPrice.amount != selectedProduct.node.priceRange.maxVariantPrice.amount ?
                <span className=''>{((selectedProduct.node.priceRange.minVariantPrice.amount) / 100)?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} - {((selectedProduct.node.priceRange.maxVariantPrice.amount) / 100)?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>
              :
                <span className=''>{((selectedProduct.node.priceRange.minVariantPrice.amount) / 100)?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>
              }
              <span className=''>{(selectedProduct.node.variants.edges).length} {(selectedProduct.node.variants.edges).length == 1 ? 'Variant' : 'Variants'}</span>
            </div>
          </div>
        </>
      }  
    </div>
  

    </div>
    */}
    </div>
  );
}

export default CreateAdSelectProduct;