import React, { useState, useRef, useEffect } from 'react';
import Switch from "react-switch";
import './CampaignGrid.css';
import { CheckRounded, KeyboardArrowDownRounded, KeyboardArrowUpRounded } from '@mui/icons-material';
import {dummyTesting} from '../../../AdsManager/Ads' 





function CampaignGrid({ analyticDropdown, selectedCampaigns, adjustSelection, campaigns, setShowDrawer, setSelectedCampaign, selectedCampaign, selectedColumns, filterStatus }) {
  
  const [columnWidths, setColumnWidths] = useState([150, 300, ...Array(31).fill(150)]); // Set widths for each column
  const [switchStates, setSwitchStates] = useState({});
  const [filteredCampaigns, setFiltedCampaigns] = useState(campaigns);
  const [sortedCampaigns, setSortedCampaigns] = useState(campaigns);
  const [showDelivery, setShowDelivery] = useState(false);
  const [showBudget, setShowBudget] = useState(false);
  const [showAmountSpent, setShowAmountSpent] = useState(false);
  const [showPurchases, setShowPurchases] = useState(false);
  const [showCPC, setShowCPC] = useState(false);
  const [showCPM, setShowCPM] = useState(false);
  const [showCPP, setShowCPP] = useState(false);
  const [showFrequency, setShowFrequency] = useState(false);
  const [showBudgetRemaining, setShowBudgetRemaining] = useState(false);
  const [showImpressions, setShowImpressions] = useState(false);
  const [showStart, setShowStart] = useState(false);
  const [showEnd, setShowEnd] = useState(false);
  const [showClicks, setShowClicks] = useState(false);
  const [showAddToCarts, setShowAddToCarts] = useState(false);
  const [showBuyingType, setShowBuyingType] = useState(false);
  const [showCtr, setShowCtr] = useState(false);
  const [showInitiateCheckout, setShowInitiateCheckout] = useState(false);
  const [showLandingPageViews, setShowLandingPageViews] = useState(false);
  const [showLinkClicks, setShowLinkClicks] = useState(false);
  const [showObjective, setShowObjective] = useState(false);
  const [showPageEngagement, setShowPageEngagement] = useState(false);
  const [showPost, setShowPost] = useState(false);
  const [showPostEngagement, setShowPostEngagement] = useState(false);
  const [showPostReaction, setShowPostReaction] = useState(false);
  const [showAddToCartValue, setShowAddToCartValue] = useState(false);
  const [showInitiateCheckoutValue, setShowInitiateCheckoutValue] = useState(false);
  const [showPurchaseValue, setShowPurchaseValue] = useState(false);
  const [showVideoView, setShowVideoView] = useState(false);
  const [showViewContent, setShowViewContent] = useState(false);
  const [showCOGS, setShowCOGS] = useState(false);
  const [showROAS, setShowROAS] = useState(false);
  const [showProfit, setShowProfit] = useState(false);
  const [sortColumn, setSortColumn] = useState('name');
  const [sortOrder, setSortOrder] = useState('asc');

  const [ShowTotalOrder, setTotalOrder] = useState(false);
  const [ShowTotalRevenue, setTotalRevenue] = useState(false);
  const [ShowTotalAdSpend, setTotalAdSpend] = useState(false);
  
  const startResize = (index) => (e) => {
  const startX = e.clientX;
  const startWidth = columnWidths[index];
  const onMouseMove = (e) => {
  const newWidth = startWidth + (e.clientX - startX);
  const minWidth = 50; // Minimum width
  const maxWidth = 1000; // Maximum width (adjust as needed)
  
      // Clamp the new width between min and max values
      const clampedWidth = Math.min(maxWidth, Math.max(minWidth, newWidth));
  
      setColumnWidths((prevWidths) => {
        const newWidths = [...prevWidths];
        newWidths[index] = clampedWidth;
        return newWidths;
      });
    };
  
    const onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };
  
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = date.toLocaleString('default', { month: 'short' });
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    return `${month} ${day} at ${formattedHours}:${minutes.toString().padStart(2, '0')} ${ampm}`;
  };

  const handleToggleChange = (campaignId) => (checked) => {
    if (checked) {
      console.log(`Campaign ID ${campaignId} notifications enabled:`, checked);
    }
    setSwitchStates({
      ...switchStates,
      [campaignId]: checked,
    });
  };



  useEffect(() => {
    if (selectedColumns == 'performance') {
      setShowDelivery(true);
      setShowBudget(true);
      setTotalOrder(true);
      setTotalRevenue(true);
      setTotalAdSpend(true);
      setShowAmountSpent(true);
      setShowPurchases(true);
      setShowCPC(true);
      setShowCPM(true);
      setShowCPP(true);
      setShowFrequency(true);
      setShowBudgetRemaining(true);
      setShowImpressions(true);
      setShowStart(true);
      setShowEnd(true);
      setShowClicks(false);
      setShowAddToCarts(false);
      setShowBuyingType(false);
      setShowCtr(false);
      setShowInitiateCheckout(false);
      setShowLandingPageViews(false);
      setShowLinkClicks(false);
      setShowObjective(false);
      setShowPageEngagement(false);
      setShowPost(false);
      setShowPostEngagement(false);
      setShowPostReaction(false);
      setShowAddToCartValue(false);
      setShowInitiateCheckoutValue(false);
      setShowPurchaseValue(false);
      setShowVideoView(false);
      setShowViewContent(false);
      setShowCOGS(false);
      setShowROAS(false);
      setShowProfit(false);
    } else if (selectedColumns == 'delivery') {
      setShowDelivery(true);
      setShowBudget(false);
      setTotalOrder(true);
      setTotalRevenue(true);
      setTotalAdSpend(true);
      setShowAmountSpent(false);
      setShowPurchases(false);
      setShowCPC(false);
      setShowCPM(false);
      setShowCPP(false);
      setShowFrequency(false);
      setShowBudgetRemaining(false);
      setShowImpressions(false);
      setShowStart(false);
      setShowEnd(false);
      setShowClicks(false);
      setShowAddToCarts(false);
      setShowBuyingType(false);
      setShowCtr(false);
      setShowInitiateCheckout(false);
      setShowLandingPageViews(false);
      setShowLinkClicks(true);
      setShowObjective(true);
      setShowPageEngagement(true);
      setShowPost(true);
      setShowPostEngagement(true);
      setShowPostReaction(true);
      setShowAddToCartValue(true);
      setShowInitiateCheckoutValue(true);
      setShowPurchaseValue(true);
      setShowVideoView(true);
      setShowViewContent(false);
      setShowCOGS(false);
      setShowROAS(false);
      setShowProfit(false);
    }

  }, [selectedColumns])




  function handleSort(column) {
    if (sortColumn === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortOrder('asc');
    }
  
    const sorted = [...sortedCampaigns].sort((a, b) => {
      const aValue = isNaN(Number(a[column])) ? a[column] : Number(a[column]);
      const bValue = isNaN(Number(b[column])) ? b[column] : Number(b[column]);
  
      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return sortOrder === 'asc' ? aValue - bValue : bValue - aValue;
      } else {
        if (sortOrder === 'asc') {
          return aValue > bValue ? 1 : -1;
        } else {
          return aValue < bValue ? 1 : -1;
        }
      }
    });
  
    setSortedCampaigns(sorted);
  }



  async function filterCampaigns(campaigns, filterStatus) {
    if (filterStatus === 'ANY') {
      return campaigns;
    } else {
      return campaigns.filter((campaign) => campaign.status === filterStatus);
    }
  }


  useEffect(() => {
    async function fetchData() {
      console.log(campaigns)
      setFiltedCampaigns( await filterCampaigns(campaigns, filterStatus));
    }
    fetchData();
  }, [campaigns, filterStatus])
  

  useEffect(() => {
    setSortedCampaigns(filteredCampaigns);
  }, [filteredCampaigns])





  return (
    <div className='CampaignGridContainer'>
      <table className='CampaignGridTable'>
        <thead>
        <tr>
          <th style={{ left: 0, width: columnWidths[0] }} onClick={() => handleSort('status')}>
            <div className="header-container">
              On/Off
              {sortColumn === 'status' && sortOrder === 'asc' && <KeyboardArrowDownRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/>}
              {sortColumn === 'status' && sortOrder === 'desc' && <KeyboardArrowUpRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/> } 
              <div className="resizer" onMouseDown={startResize(0)}></div>
            </div>
          </th>
          <th style={{ left: columnWidths[0], width: columnWidths[1] }} onClick={() => handleSort('name')}>
            <div className="header-container">
              Campaign Name 
              {sortColumn === 'name' && sortOrder === 'asc' && <KeyboardArrowDownRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/>}
              {sortColumn === 'name' && sortOrder === 'desc' && <KeyboardArrowUpRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/> }           
              <div className="resizer" onMouseDown={startResize(1)}></div>
            </div>
          </th>
          {showDelivery && (
            <th style={{ width: columnWidths[2] }} onClick={() => handleSort('status')}>
              <div className="header-container">
                Delivery
                {sortColumn === 'status' && sortOrder === 'asc' && <KeyboardArrowDownRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/>}
                {sortColumn === 'status' && sortOrder === 'desc' && <KeyboardArrowUpRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/> } 
                <div className="resizer" onMouseDown={startResize(2)}></div>
              </div>
            </th>
          )}
          {ShowTotalOrder && analyticDropdown.includes('omni') && (
            <th style={{ width: columnWidths[2] }} onClick={() => handleSort('total_orders')}>
              <div className="header-container">
                Total Orders
                {sortColumn === 'total_orders' && sortOrder === 'asc' && <KeyboardArrowDownRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/>}
                {sortColumn === 'total_orders' && sortOrder === 'desc' && <KeyboardArrowUpRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/> } 
                <div className="resizer" onMouseDown={startResize(2)}></div>
              </div>
            </th>
          )}
          {ShowTotalRevenue && analyticDropdown.includes('omni') && (
            <th style={{ width: columnWidths[2] }} onClick={() => handleSort('total_revenue')}>
              <div className="header-container">
                Total Revenue
                {sortColumn === 'total_revenue' && sortOrder === 'asc' && <KeyboardArrowDownRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/>}
                {sortColumn === 'total_revenue' && sortOrder === 'desc' && <KeyboardArrowUpRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/> } 
                <div className="resizer" onMouseDown={startResize(2)}></div>
              </div>
            </th>
          )}
          {ShowTotalAdSpend && analyticDropdown.includes('omni') && (
            <th style={{ width: columnWidths[2] }} onClick={() => handleSort('total_ad_spend')}>
              <div className="header-container">
                Total Ads Spend
                {sortColumn === 'total_ad_spend' && sortOrder === 'asc' && <KeyboardArrowDownRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/>}
                {sortColumn === 'total_ad_spend' && sortOrder === 'desc' && <KeyboardArrowUpRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/> } 
                <div className="resizer" onMouseDown={startResize(2)}></div>
              </div>
            </th>
          )}
          {showBudget && (
            <th style={{ width: columnWidths[3] }} onClick={() => handleSort('daily_budget')}>
              <div className="header-container">
                Budget
                {sortColumn === 'daily_budget' && sortOrder === 'asc' && <KeyboardArrowDownRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/>}
                {sortColumn === 'daily_budget' && sortOrder === 'desc' && <KeyboardArrowUpRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/> } 
                <div className="resizer" onMouseDown={startResize(3)}></div>
              </div>
            </th>
          )}
          {showAmountSpent && (
            <th style={{ width: columnWidths[4] }} onClick={() => handleSort('spend')}>
              <div className="header-container">
                Amount Spent
                {sortColumn === 'spend' && sortOrder === 'asc' && <KeyboardArrowDownRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/>}
                {sortColumn === 'spend' && sortOrder === 'desc' && <KeyboardArrowUpRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/> } 
                <div className="resizer" onMouseDown={startResize(4)}></div>
              </div>
            </th>
          )}
          {showPurchases && (
            <th style={{ width: columnWidths[5] }} onClick={() => handleSort('purchase')}>
              <div className="header-container">
                Purchases
                {sortColumn === 'purchase' && sortOrder === 'asc' && <KeyboardArrowDownRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/>}
                {sortColumn === 'purchase' && sortOrder === 'desc' && <KeyboardArrowUpRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/> } 
                <div className="resizer" onMouseDown={startResize(5)}></div>
              </div>
            </th>
          )}
          {showCPC && (
            <th style={{ width: columnWidths[6] }} onClick={() => handleSort('cpc')}>
              <div className="header-container">
                CPC
                {sortColumn === 'cpc' && sortOrder === 'asc' && <KeyboardArrowDownRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/>}
                {sortColumn === 'cpc' && sortOrder === 'desc' && <KeyboardArrowUpRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/> } 
                <div className="resizer" onMouseDown={startResize(6)}></div>
              </div>
            </th>
          )}
          {showCPM && (
            <th style={{ width: columnWidths[7] }} onClick={() => handleSort('cpm')}>
              <div className="header-container">
                CPM
                {sortColumn === 'cpm' && sortOrder === 'asc' && <KeyboardArrowDownRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/>}
                {sortColumn === 'cpm' && sortOrder === 'desc' && <KeyboardArrowUpRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/> } 
                <div className="resizer" onMouseDown={startResize(7)}></div>
              </div>
            </th>
          )}
          {showCPP && (
            <th style={{ width: columnWidths[8] }} onClick={() => handleSort('cpp')}>
              <div className="header-container">
                CPP
                {sortColumn === 'cpp' && sortOrder === 'asc' && <KeyboardArrowDownRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/>}
                {sortColumn === 'cpp' && sortOrder === 'desc' && <KeyboardArrowUpRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/> } 
                <div className="resizer" onMouseDown={startResize(8)}></div>
              </div>
            </th>
          )}
          {showFrequency && (
            <th style={{ width: columnWidths[9] }} onClick={() => handleSort('frequency')}>
              <div className="header-container">
                Frequency
                {sortColumn === 'frequency' && sortOrder === 'asc' && <KeyboardArrowDownRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/>}
                {sortColumn === 'frequency' && sortOrder === 'desc' && <KeyboardArrowUpRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/> } 
                <div className="resizer" onMouseDown={startResize(9)}></div>
              </div>
            </th>
          )}
          {showBudgetRemaining && (
            <th style={{ width: columnWidths[10] }} onClick={() => handleSort('budget_remaining')}>
              <div className="header-container">
                Budget Remaining
                {sortColumn === 'budget_remaining' && sortOrder === 'asc' && <KeyboardArrowDownRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/>}
                {sortColumn === 'budget_remaining' && sortOrder === 'desc' && <KeyboardArrowUpRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/> } 
                <div className="resizer" onMouseDown={startResize(10)}></div>
              </div>
            </th>
          )}
          {showImpressions && (
            <th style={{ width: columnWidths[11] }} onClick={() => handleSort('impressions')}>
              <div className="header-container">
                Impressions
                {sortColumn === 'impressions' && sortOrder === 'asc' && <KeyboardArrowDownRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/>}
                {sortColumn === 'impressions' && sortOrder === 'desc' && <KeyboardArrowUpRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/> } 
                <div className="resizer" onMouseDown={startResize(11)}></div>
              </div>
            </th>
          )}
          {showStart && (
            <th style={{ width: columnWidths[12] }} onClick={() => handleSort('start_time')}>
              <div className="header-container">
                Start Time
                {sortColumn === 'start_time' && sortOrder === 'asc' && <KeyboardArrowDownRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/>}
                {sortColumn === 'start_time' && sortOrder === 'desc' && <KeyboardArrowUpRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/> } 
                <div className="resizer" onMouseDown={startResize(12)}></div>
              </div>
            </th>
          )}
          {showEnd && (
            <th style={{ width: columnWidths[13] }} onClick={() => handleSort('stop_time')}>
              <div className="header-container">
                End Time
                {sortColumn === 'stop_time' && sortOrder === 'asc' && <KeyboardArrowDownRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/>}
                {sortColumn === 'stop_time' && sortOrder === 'desc' && <KeyboardArrowUpRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/> } 
                <div className="resizer" onMouseDown={startResize(13)}></div>
              </div>
            </th>
          )}
          {showClicks && (
            <th style={{ width: columnWidths[14] }} onClick={() => handleSort('clicks')}>
              <div className="header-container">
                Clicks
                {sortColumn === 'clicks' && sortOrder === 'asc' && <KeyboardArrowDownRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/>}
                {sortColumn === 'clicks' && sortOrder === 'desc' && <KeyboardArrowUpRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/> } 
                <div className="resizer" onMouseDown={startResize(14)}></div>
              </div>
            </th>
          )}
          {showAddToCarts && (
            <th style={{ width: columnWidths[15] }} onClick={() => handleSort('add_to_cart')}>
              <div className="header-container">
                Add To Carts
                {sortColumn === 'add_to_cart' && sortOrder === 'asc' && <KeyboardArrowDownRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/>}
                {sortColumn === 'add_to_cart' && sortOrder === 'desc' && <KeyboardArrowUpRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/> } 
                <div className="resizer" onMouseDown={startResize(15)}></div>
              </div>
            </th>
          )}
          {showBuyingType && (
            <th style={{ width: columnWidths[16] }} onClick={() => handleSort('buying_type')}>
              <div className="header-container">
                Buying Type
                {sortColumn === 'buying_type' && sortOrder === 'asc' && <KeyboardArrowDownRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/>}
                {sortColumn === 'buying_type' && sortOrder === 'desc' && <KeyboardArrowUpRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/> } 
                <div className="resizer" onMouseDown={startResize(16)}></div>
              </div>
            </th>
          )}
          {showCtr && (
            <th style={{ width: columnWidths[17] }} onClick={() => handleSort('ctr')}>
              <div className="header-container">
                CTR
                {sortColumn === 'ctr' && sortOrder === 'asc' && <KeyboardArrowDownRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/>}
                {sortColumn === 'ctr' && sortOrder === 'desc' && <KeyboardArrowUpRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/> } 
                <div className="resizer" onMouseDown={startResize(17)}></div>
              </div>
            </th>
          )}
          {showInitiateCheckout && (
            <th style={{ width: columnWidths[18] }} onClick={() => handleSort('initiate_checkout')}>
              <div className="header-container">
                Initiate Checkout
                {sortColumn === 'initiate_checkout' && sortOrder === 'asc' && <KeyboardArrowDownRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/>}
                {sortColumn === 'initiate_checkout' && sortOrder === 'desc' && <KeyboardArrowUpRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/> } 
                <div className="resizer" onMouseDown={startResize(18)}></div>
              </div>
            </th>
          )}
          {showLandingPageViews && (
            <th style={{ width: columnWidths[19] }} onClick={() => handleSort('landing_page_views')}>
              <div className="header-container">
                Landing Page Views
                {sortColumn === 'landing_page_views' && sortOrder === 'asc' && <KeyboardArrowDownRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/>}
                {sortColumn === 'landing_page_views' && sortOrder === 'desc' && <KeyboardArrowUpRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/> } 
                <div className="resizer" onMouseDown={startResize(19)}></div>
              </div>
            </th>
          )}
          {showLinkClicks && (
            <th style={{ width: columnWidths[20] }} onClick={() => handleSort('link_click')}>
              <div className="header-container">
                Link Clicks
                {sortColumn === 'link_click' && sortOrder === 'asc' && <KeyboardArrowDownRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/>}
                {sortColumn === 'link_click' && sortOrder === 'desc' && <KeyboardArrowUpRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/> } 
                <div className="resizer" onMouseDown={startResize(20)}></div>
              </div>
            </th>
          )}
          {showObjective && (
            <th style={{ width: columnWidths[21] }} onClick={() => handleSort('objective')}>
              <div className="header-container">
                Objective
                {sortColumn === 'objective' && sortOrder === 'asc' && <KeyboardArrowDownRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/>}
                {sortColumn === 'objective' && sortOrder === 'desc' && <KeyboardArrowUpRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/> } 
                <div className="resizer" onMouseDown={startResize(21)}></div>
              </div>
            </th>
          )}
          {showPageEngagement && (
            <th style={{ width: columnWidths[22] }} onClick={() => handleSort('page_engagement')}>
              <div className="header-container">
                Page Engagement
                {sortColumn === 'page_engagement' && sortOrder === 'asc' && <KeyboardArrowDownRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/>}
                {sortColumn === 'page_engagement' && sortOrder === 'desc' && <KeyboardArrowUpRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/> } 
                <div className="resizer" onMouseDown={startResize(22)}></div>
              </div>
            </th>
          )}
          {showPost && (
            <th style={{ width: columnWidths[23] }} onClick={() => handleSort('post')}>
              <div className="header-container">
                Post
                {sortColumn === 'post' && sortOrder === 'asc' && <KeyboardArrowDownRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/>}
                {sortColumn === 'post' && sortOrder === 'desc' && <KeyboardArrowUpRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/> } 
                <div className="resizer" onMouseDown={startResize(23)}></div>
              </div>
            </th>
          )}
          {showPostEngagement && (
            <th style={{ width: columnWidths[24] }} onClick={() => handleSort('post_engagement')}>
              <div className="header-container">
                Post Engagement
                {sortColumn === 'post_engagement' && sortOrder === 'asc' && <KeyboardArrowDownRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/>}
                {sortColumn === 'post_engagement' && sortOrder === 'desc' && <KeyboardArrowUpRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/> } 
                <div className="resizer" onMouseDown={startResize(24)}></div>
              </div>
            </th>
          )}
          {showPostReaction && (
            <th style={{ width: columnWidths[25] }} onClick={() => handleSort('post_reaction')}>
              <div className="header-container">
                Post Reactions
                {sortColumn === 'post_reaction' && sortOrder === 'asc' && <KeyboardArrowDownRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/>}
                {sortColumn === 'post_reaction' && sortOrder === 'desc' && <KeyboardArrowUpRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/> } 
                <div className="resizer" onMouseDown={startResize(25)}></div>
              </div>
            </th>
          )}
          {showAddToCartValue && (
            <th style={{ width: columnWidths[26] }} onClick={() => handleSort('value_add_to_cart')}>
              <div className="header-container">
                Add to Cart Value
                {sortColumn === 'value_add_to_cart' && sortOrder === 'asc' && <KeyboardArrowDownRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/>}
                {sortColumn === 'value_add_to_cart' && sortOrder === 'desc' && <KeyboardArrowUpRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/> } 
                <div className="resizer" onMouseDown={startResize(26)}></div>
              </div>
            </th>
          )}
          {showInitiateCheckoutValue && (
            <th style={{ width: columnWidths[27] }} onClick={() => handleSort('value_initiate_checkout')}>
              <div className="header-container">
                Initiate Checkout Value
                {sortColumn === 'value_initiate_checkout' && sortOrder === 'asc' && <KeyboardArrowDownRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/>}
                {sortColumn === 'value_initiate_checkout' && sortOrder === 'desc' && <KeyboardArrowUpRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/> } 
                <div className="resizer" onMouseDown={startResize(27)}></div>
              </div>
            </th>
          )}
          {showPurchaseValue && (
            <th style={{ width: columnWidths[28] }} onClick={() => handleSort('value_purchase')}>
              <div className="header-container">
                Purchase Value
                {sortColumn === 'value_purchase' && sortOrder === 'asc' && <KeyboardArrowDownRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/>}
                {sortColumn === 'value_purchase' && sortOrder === 'desc' && <KeyboardArrowUpRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/> } 
                <div className="resizer" onMouseDown={startResize(28)}></div>
              </div>
            </th>
          )}
          {showVideoView && (
            <th style={{ width: columnWidths[29] }} onClick={() => handleSort('video_view')}>
              <div className="header-container">
                Video View
                {sortColumn === 'video_view' && sortOrder === 'asc' && <KeyboardArrowDownRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/>}
                {sortColumn === 'video_view' && sortOrder === 'desc' && <KeyboardArrowUpRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/> } 
                <div className="resizer" onMouseDown={startResize(29)}></div>
              </div>
            </th>
          )}
          {showViewContent && (
            <th style={{ width: columnWidths[30] }} onClick={() => handleSort('view_content')}>
              <div className="header-container">
                View Content
                {sortColumn === 'view_content' && sortOrder === 'asc' && <KeyboardArrowDownRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/>}
                {sortColumn === 'view_content' && sortOrder === 'desc' && <KeyboardArrowUpRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/> } 
                <div className="resizer" onMouseDown={startResize(30)}></div>
              </div>
            </th>
          )}
          {showCOGS && (
            <th style={{ width: columnWidths[31] }} onClick={() => handleSort('cogs')}>
              <div className="header-container">
                COGS
                {sortColumn === 'cogs' && sortOrder === 'asc' && <KeyboardArrowDownRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/>}
                {sortColumn === 'cogs' && sortOrder === 'desc' && <KeyboardArrowUpRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/> } 
                <div className="resizer" onMouseDown={startResize(31)}></div>
              </div>
            </th>
          )}
          {showROAS && (
            <th style={{ width: columnWidths[32] }} onClick={() => handleSort('roas')}>
              <div className="header-container">
                ROAS
                {sortColumn === 'roas' && sortOrder === 'asc' && <KeyboardArrowDownRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/>}
                {sortColumn === 'roas' && sortOrder === 'desc' && <KeyboardArrowUpRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/> } 
                <div className="resizer" onMouseDown={startResize(32)}></div>
              </div>
            </th>
          )}
          {showProfit && (
            <th style={{ width: columnWidths[33] }} onClick={() => handleSort('profit')}>
              <div className="header-container">
                Profit
                {sortColumn === 'profit' && sortOrder === 'asc' && <KeyboardArrowDownRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/>}
                {sortColumn === 'profit' && sortOrder === 'desc' && <KeyboardArrowUpRounded fontSize='small' style={{color: '#888', transform: 'rotate(180deg)'}}/> } 
                <div className="resizer" onMouseDown={startResize(33)}></div>
              </div>
            </th>
          )}
        </tr>

        </thead>
        <tbody>
          {sortedCampaigns.map((x, index) => { return (
            <tr key={index}>
            
              <td style={{ width: columnWidths[0] }}>
                <div className='CampaignGridSelectedAdCheckboxColumnRow'>
                  {selectedCampaigns.includes(x.campaign_id) ?
                    <div className='AdsSelectedAdCheckboxContainer' onClick={() => { adjustSelection(x.campaign_id) }}>
                      <div className='AdsSelectedAdCheckboxChecked'>
                        <CheckRounded style={{ width: 15 }} />
                      </div>
                    </div>
                    :
                    <div className='AdsSelectedAdCheckboxContainer' onClick={() => { adjustSelection(x.campaign_id) }}>
                      <div className='AdsSelectedAdCheckbox'>
                        <CheckRounded style={{ width: 15 }} />
                      </div>
                    </div>
                  }

                  <Switch
                    onChange={handleToggleChange(x.campaign_id)}
                    checked={x.status == 'ACTIVE' ? true : false}
                    offColor="#d9d9d9"
                    onColor="#0874fc"
                    uncheckedIcon={false}
                    checkedIcon={false}
                    width={45}
                    height={25}
                    handleDiameter={20}
                  />
                </div>
              </td>
              <td style={{ width: columnWidths[1], left: columnWidths[0], paddingLeft: 10}} onClick={() => {setSelectedCampaign(x); setShowDrawer(true)}}>{x.name}</td>
              {showDelivery && (
                <td>
                  {x.status == 'ACTIVE' && ( 
                    <div className='CampaignGridDeliveryStatusRow'>
                      <div className='CampaignGridDeliveryStatusIcon' style={{ backgroundColor: '#4CAF50' }}></div>
                      <span className='CampaignGridDeliveryStatusText'>Active</span>
                    </div>
                  )}
                  {x.status == 'PAUSED' && ( 
                    <div className='CampaignGridDeliveryStatusRow'>
                      <div className='CampaignGridDeliveryStatusIcon' style={{ backgroundColor: '#e7e7e7' }}></div>
                      <span className='CampaignGridDeliveryStatusText'>Paused</span>
                    </div>
                  )}
                </td>
              )}
              {ShowTotalOrder && analyticDropdown.includes('omni') && (
                <td>{x.total_orders}</td>
              )}
              {ShowTotalRevenue && analyticDropdown.includes('omni') && (
                <td>{x.total_revenue}</td>
              )}
              {ShowTotalAdSpend && analyticDropdown.includes('omni') && (
                <td>{x.total_ad_spend}</td>
              )}
              {showBudget && (
                <td>
                  <div className='CampaignGridBudgetRow'>
                    <span className='CampaignGridBudgetRowValue'>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(x.daily_budget)}</span>
                    <span className='CampaignGridBudgetRowDescription'>(daily)</span>
                  </div>
                </td>
              )}
              {showAmountSpent && (
                <td>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(x.spend)}</td>
              )}
              {showPurchases && (
                <td>{x.omni_purchase}</td>
              )}
              {showCPC && (
                <td>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(x.cpc)}</td>
              )}
              {showCPM && (
                <td>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(x.cpm)}</td>
              )}
              {showCPP && (
                <td>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(x.cpp)}</td>
              )}
              {showFrequency && (
                <td>{new Intl.NumberFormat('en-US').format(x.frequency)}</td>
              )}
              {showBudgetRemaining && (
                <td>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(x.budget_remaining)}</td>
              )}
              {showImpressions && (
                <td>{new Intl.NumberFormat('en-US').format(x.impressions)}</td>
              )}
              {showStart && (
                <td>{formatDate(x.start_time)}</td>
              )}
              {showEnd && (
                <td>{formatDate(x.stop_time)}</td>
              )}
              {showClicks && (
                <td>{new Intl.NumberFormat('en-US').format(x.clicks)}</td>
              )}
              {showAddToCarts && (
                <td>{new Intl.NumberFormat('en-US').format(x.add_to_cart)}</td>
              )}
              {showBuyingType && (
                <td>{x.buying_type}</td>
              )}
              {showCtr && (
                <td>{x.ctr}</td>
              )}
              {showInitiateCheckout && (
                <td>{new Intl.NumberFormat('en-US').format(x.initiate_checkout)}</td>
              )}
              {showLandingPageViews && (
                <td>{new Intl.NumberFormat('en-US').format(x.landing_page_views)}</td>
              )}
              {showLinkClicks && (
                <td>{new Intl.NumberFormat('en-US').format(x.link_click)}</td>
              )}
              {showObjective && (
                <td>{x.objective}</td>
              )}
              {showPageEngagement && (
                <td>{new Intl.NumberFormat('en-US').format(x.page_engagement)}</td>
              )}
              {showPost && (
                <td>{new Intl.NumberFormat('en-US').format(x.post)}</td>
              )}
              {showPostEngagement && (
                <td>{new Intl.NumberFormat('en-US').format(x.post_engagement)}</td>
              )}
              {showPostReaction && (
                <td>{new Intl.NumberFormat('en-US').format(x.post_reaction)}</td>
              )}
              {showAddToCartValue && (
                <td>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(x.value_add_to_cart)}</td>
              )}
              {showInitiateCheckoutValue && (
                <td>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(x.value_initiate_checkout)}</td>
              )}
              {showPurchaseValue && (
                <td>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(x.value_purchase)}</td>
              )}
              {showVideoView && (
                <td>{new Intl.NumberFormat('en-US').format(x.video_view)}</td>
              )}
              {showViewContent && (
                <td>{new Intl.NumberFormat('en-US').format(x.view_content)}</td>
              )}
              {showCOGS && (
                <td>{x.cogs}</td>
              )}
              {showROAS && (
                <td>{new Intl.NumberFormat('en-US').format(x.roas)}</td>
              )}
              {showProfit && (
                <td>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(x.profit)}</td>
              )}

            </tr>
          )})}

          <tr>
              <td style={{ width: columnWidths[0] }}>
                
              </td>
              <td style={{ width: columnWidths[1], left: columnWidths[0], paddingLeft: 10}}> </td>
              {showDelivery && (
                <td>
                  
                </td>
              )}
              {showBudget && (
                <td>
                  <div className='CampaignGridBudgetRow'>
                    <span className='CampaignGridBudgetRowValue'>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(sortedCampaigns.reduce((accumulator, campaign) => { return accumulator + Number(campaign.daily_budget) }, 0))}</span>
                    <span className='CampaignGridBudgetRowDescription'>(daily)</span>
                  </div>
                </td>
              )}
              {showAmountSpent && (
                <td>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(sortedCampaigns.reduce((accumulator, campaign) => { return accumulator + Number(campaign.spend) }, 0))}</td>
              )}
              {showPurchases && (
                <td>{sortedCampaigns.reduce((accumulator, campaign) => { return accumulator + Number(campaign.omni_purchase) }, 0)}</td>
              )}
              {showCPC && (
                <td>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                  sortedCampaigns.reduce((accumulator, campaign) => { return accumulator + Number(campaign.spend) }, 0) / 
                  sortedCampaigns.reduce((accumulator, campaign) => { return accumulator + Number(campaign.clicks) }, 0)
                )}</td>
              )}
              {showCPM && (
                <td>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                  sortedCampaigns.reduce((accumulator, campaign) => { return accumulator + Number(campaign.spend) }, 0) /
                  sortedCampaigns.reduce((accumulator, campaign) => { return accumulator + Number(campaign.impressions) }, 0) * 1000
                )}</td>
              )}
              {showCPP && (
                <td>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                  sortedCampaigns.reduce((accumulator, campaign) => { return accumulator + Number(campaign.spend) }, 0) /
                  sortedCampaigns.reduce((accumulator, campaign) => { return accumulator + Number(campaign.purchase) }, 0)
                )}</td>
              )}
              {showFrequency && (
                <td>{new Intl.NumberFormat('en-US').format(
                  sortedCampaigns.reduce((accumulator, campaign) => { return accumulator + Number(campaign.impressions) }, 0) / 
                  sortedCampaigns.reduce((accumulator, campaign) => { return accumulator + Number(campaign.reach) }, 0)
                )}</td>
              )}
              {showBudgetRemaining && (
                <td>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(sortedCampaigns.reduce((accumulator, campaign) => { return accumulator + Number(campaign.budget_remaining) }, 0))}</td>
              )}
              {showImpressions && (
                <td>{new Intl.NumberFormat('en-US').format(sortedCampaigns.reduce((accumulator, campaign) => { return accumulator + Number(campaign.impressions) }, 0))}</td>
              )}
              {showStart && (
                <td></td>
              )}
              {showEnd && (
                <td></td>
              )}
              {showClicks && (
                <td>{new Intl.NumberFormat('en-US').format(sortedCampaigns.reduce((accumulator, campaign) => { return accumulator + Number(campaign.clicks) }, 0))}</td>
              )}
              {showAddToCarts && (
                <td>{new Intl.NumberFormat('en-US').format(sortedCampaigns.reduce((accumulator, campaign) => { return accumulator + Number(campaign.add_to_cart) }, 0))}</td>
              )}
              {showBuyingType && (
                <td></td>
              )}
              {showCtr && (
                <td>
                  {sortedCampaigns.reduce((accumulator, campaign) => { return accumulator + Number(campaign.clicks) }, 0) /
                  sortedCampaigns.reduce((accumulator, campaign) => { return accumulator + Number(campaign.impressions) }, 0) * 100}
                </td>
              )}
              {showInitiateCheckout && (
                <td>{new Intl.NumberFormat('en-US').format(sortedCampaigns.reduce((accumulator, campaign) => { return accumulator + Number(campaign.initiateCheckout) }, 0))}</td>
              )}
              {showLandingPageViews && (
                <td>{new Intl.NumberFormat('en-US').format(sortedCampaigns.reduce((accumulator, campaign) => { return accumulator + Number(campaign.landing_page_views) }, 0))}</td>
              )}
              {showLinkClicks && (
                <td>{new Intl.NumberFormat('en-US').format(sortedCampaigns.reduce((accumulator, campaign) => { return accumulator + Number(campaign.link_click) }, 0))}</td>
              )}
              {showObjective && (
                <td></td>
              )}
              {showPageEngagement && (
                <td>{new Intl.NumberFormat('en-US').format(sortedCampaigns.reduce((accumulator, campaign) => { return accumulator + Number(campaign.page_engagement) }, 0))}</td>
              )}
              {showPost && (
                <td>{new Intl.NumberFormat('en-US').format(sortedCampaigns.reduce((accumulator, campaign) => { return accumulator + Number(campaign.post) }, 0))}</td>
              )}
              {showPostEngagement && (
                <td>{new Intl.NumberFormat('en-US').format(sortedCampaigns.reduce((accumulator, campaign) => { return accumulator + Number(campaign.post_engagement) }, 0))}</td>
              )}
              {showPostReaction && (
                <td>{new Intl.NumberFormat('en-US').format(sortedCampaigns.reduce((accumulator, campaign) => { return accumulator + Number(campaign.post_reaction) }, 0))}</td>
              )}
              {showAddToCartValue && (
                <td>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(sortedCampaigns.reduce((accumulator, campaign) => { return accumulator + Number(campaign.value_add_to_cart) }, 0))}</td>
              )}
              {showInitiateCheckoutValue && (
                <td>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(sortedCampaigns.reduce((accumulator, campaign) => { return accumulator + Number(campaign.value_initiate_checkout) }, 0))}</td>
              )}
              {showPurchaseValue && (
                <td>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(sortedCampaigns.reduce((accumulator, campaign) => { return accumulator + Number(campaign.value_purchase) }, 0))}</td>
              )}
              {showVideoView && (
                <td>{new Intl.NumberFormat('en-US').format(sortedCampaigns.reduce((accumulator, campaign) => { return accumulator + Number(campaign.video_view) }, 0))}</td>
              )}
              {showViewContent && (
                <td>{new Intl.NumberFormat('en-US').format(sortedCampaigns.reduce((accumulator, campaign) => { return accumulator + Number(campaign.view_content) }, 0))}</td>
              )}
              {showCOGS && (
                <td>{sortedCampaigns.reduce((accumulator, campaign) => { return accumulator + Number(campaign.cogs) }, 0)}</td>
              )}
              {showROAS && (
                <td>{new Intl.NumberFormat('en-US').format(
                  sortedCampaigns.reduce((accumulator, campaign) => { return accumulator + Number(campaign.value_purchase) }, 0) / 
                  sortedCampaigns.reduce((accumulator, campaign) => { return accumulator + Number(campaign.spend) }, 0)
                )}</td>
              )}
              {showProfit && (
                <td>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(sortedCampaigns.reduce((accumulator, campaign) => { return accumulator + Number(campaign.profit) }, 0))}</td>
              )}
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default CampaignGrid;