import './Ads.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import FolderIcon from '../../assets/Icons/Folder';
import CampaignIcon from '../../assets/Icons/Campaign';
import { AppsRounded, CheckRounded, ChevronRightRounded, AddRounded, DeleteRounded, GridOnRounded, KeyboardArrowDownRounded, OpenInNewRounded, PlayArrowRounded, SearchRounded, ShareRounded, StopRounded, TableRowsRounded, Close, CloseRounded } from '@mui/icons-material';
import VideoIcon from '../../assets/Icons/VideoIcon';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import CampaignGrid from './Tabs/Campaings/CampaignGrid';
import AdSetsGrid from './Tabs/AdSets/AdsSetsGrid';
import AdsGrid from './Tabs/Ads/AdsGrid';
import { Button, ClickAwayListener } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Avatar from '@mui/material/Avatar';
import MultipleSelectCheckmarks from '../../components/MultipleSelectCheckmarks'
import { Grid, Drawer } from '@mui/material';
import CustomDrawer from '../AdsManager/Tabs/Campaings/CampaignDrawer';
import DateSelector from '../../components/DateSelector';
import dayjs from 'dayjs';




export const dummyTesting = [
  {
      "campaign_id": "120211200873330346",
      "total_orders": "2",
      "total_revenue": "143.00",
      "total_ad_spend": 0,
      "roas": null
  },
  {
      "campaign_id": "120210612099570346",
      "total_orders": "8",
      "total_revenue": "1464.98",
      "total_ad_spend": 0,
      "roas": null
  },
  {
      "campaign_id": "120210585997310346",
      "total_orders": "1",
      "total_revenue": "81.80",
      "total_ad_spend": 0,
      "roas": null
  },
];

const adSetCols = [
  { field: 'clicks', headerName: 'Clicks', width: 150 },
  { field: 'impressions', headerName: 'Impressions', width: 150 },
  { field: 'spend', headerName: 'Spend', width: 150 },
  { field: 'frequency', headerName: 'Frequency', width: 150 },
  { field: 'created_time', headerName: 'Created Time', width: 150 },
  { field: 'updated_time', headerName: 'Updated Time', width: 150 },
];

const adsCols = [
  { field: 'effective_status', headerName: 'Effective Status', width: 150 },
  { field: 'created_time', headerName: 'Created Time', width: 150 },
];


function Ads({serverURL}) {
  const navigate = useNavigate()
  const [selectedProduct, setSelectedProduct] = useState('');
  const [draftID, setDraftID] = useState('')
  const [allAdAccounts, setAllAdAccounts] = useState([])
  const [allAds, setAllAds] = useState([])
  const [allAdsFiltered, setAllAdsFiltered] = useState([])
  const [selectedCampaigns, setSelectedCampaigns] = useState([])
  const [selectedAdForDetails, setSelectedAdForDetails] = useState({})
  const [selectedAdTypeForDetails, setSelectedAdTypeForDetails] = useState({})
  const [showAdAccountDropdown, setShowAdAccountDropdown] = useState(false)
  const [showDataDropdown, setshowDataDropdown] = useState(false)
  const [selectedAdAccount, setSelectedAdAccount] = useState(false)
  const [rows, setRows] = useState([]);
  const [showStatusDropdown, setShowStatusDropdown] = useState(false)
  const [filterStatus, setFilterStatus] = useState('ANY')
  const [showAdDetailsPane, setShowAdDetailsPane] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [selectedView, setSelectedView] = useState('rows')
  const [expandedIdList, setExpandedIdList] = useState([])
  const [selectedAdSetId, setSelectedAdsetId] = useState([]);
  const [adsRows, setAdsRows] = useState([]);
  const [underAds, setUnderAds] = useState([]);
  const uid = localStorage.getItem('uid');
  const bid = localStorage.getItem('bid');
  const [showDrawer, setShowDrawer] = useState(false)
  const [selectedCampaign, setSelectedCampaign] = useState(null);

  //states for columns option dropdown for each tab:-
  const [showSelectColumnsDropdown, setShowSelectColumnsDropdown] = useState(false)
  const [selectedColumns, setSelectedColumns] = useState('performance');
  const [adSetsColOptions, setAdSetsColOptions] = useState(adSetCols);
  const [adsColOptions, setAdsColOptions] = useState(adsCols);
  const [selectedAdLevel, setSelectedAdLevel] = useState(0);
  const [analyticsMode, setAnalyticsMode] = useState('omni');
  const [attributionsWindow, setAttributionsWindow] = useState('7days');
  const [showAttributionsWindowDropdown, setShowAttributionsWindowDropdown] = useState(false);
  const [showDateSelectors, setShowDateSelectors] = useState(false);

  // for fetching business Id 
  const [omniBusinessId, setOmniBusinessIS] = useState(null);
  const [adAccountId, setAccountId] = useState(null);
  const [dateStart, setDateStart] = useState(dayjs().subtract(1, 'day').startOf('day'));
  const [dateEnd, setDateEnd] = useState(dayjs().subtract(1, 'day').endOf('day'));






  // Fetch campaigns data from the API
  useEffect(() => {
    async function fetchData() {
      // setLoading(true);
      try {
        let fetchedData = [];
        if (selectedAdSetId && selectedAdSetId.length > 0) {
          // Prioritize fetching data with adSetId if present
          fetchedData = await getAdsDataWithAdSetID(selectedAdSetId);
        } else if (selectedCampaigns && selectedCampaigns.length > 0) {
          // Fetch data with campaignId only if adSetId is not present
          fetchedData = await getAdsDataWithCampaignID(selectedCampaigns);
          console.log(fetchedData.map(item => item.campaign_id));
        } else {
          // Fetch all data if neither adSetId nor campaignId is present
          fetchedData = await getAdsData(adAccountId);
        }
        const modifiedData = fetchedData.map((row, index) => ({ id: index, ...row, isSelect: false }));
        // setRows(modifiedData);
        setUnderAds(modifiedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchData();
  }, [selectedCampaigns, selectedAdSetId,adAccountId]);

  // for setting BusinessId
  const handleAdAccountSelect = (adAccount) => {
    setSelectedAdAccount(adAccount);
    setOmniBusinessIS(adAccount.omni_business_id);
    setAccountId(adAccount.account_id);
    setShowAdAccountDropdown(false);
  };

  async function getAdSetsDataWithCampaignID(campaignId) {
    try {
      const response = await axios.get(`${serverURL}/getAdSetsDataWithCampaignId?campaign_ids=${JSON.stringify(campaignId)}`);
      console.log('Fetched Ad Sets Data:', response.data);
      return response.data;
    } catch (error) {
      console.error('ERROR loading campaigns data', error);
      return [];
    }
  }

  async function getAdSetsData(adAccountId) {
    try {
      const response = await axios.get(`${serverURL}/getAdSetsData`, {
        params: {
          accountId: adAccountId,
        },
      });

      console.log('Fetched Ad Sets Data:', response.data);
      return response.data;
    } catch (error) {
      console.error('ERROR loading campaigns data', error);
      return [];
    }
  }



  useEffect(() => {
    async function fetchData() {
      //setLoading(true);
      try {
        let fetchedData = [];
        if (selectedCampaigns && selectedCampaigns.length > 0) {
          fetchedData = await getAdSetsDataWithCampaignID(selectedCampaigns);
        } else {
          fetchedData = await getAdSetsData(adAccountId);
        }
        const modifiedData = fetchedData.map(row => ({ ...row, isSelect: false }));
        setAdsRows(modifiedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        // setLoading(false);
      }
    }
    fetchData();
  }, [selectedCampaigns, adAccountId]);
  

  useEffect(() => {
    if (analyticsMode === "omni" && rows.length > 0) {
      console.log(`khela howe`, rows.length);

      const updatedData = rows.map((item) => {
        // Find the matching object in dummyTesting
        let matchingObject = dummyTesting.find(dummy => dummy.campaign_id === item.campaign_id);
        if (matchingObject) {
          // Merge the data
          return { ...item, ...matchingObject };
        } else {
          // If no match, return the item as is
          return item;
        }
      });

      console.log('Updated Data:', updatedData);
      setRows(updatedData);
    }
  }, [analyticsMode, rows]);




  async function getCampaigns(adAccountId) {
    try {
      const response = await axios.get(`${serverURL}/getCampaignsData`, {
        params: {
          accountId: adAccountId,
        },
      });
      console.log('Fetched Campaigns Data:', response.data);
      setRows(response.data)
      return true;
    } catch (error) {
      console.error('ERROR loading campaigns data', error);
      return [];
    }
  }



  useEffect(() => {
    if (adAccountId) {
      const data = getCampaignMetrices();
      console.log('datadatadatadatadatadatadata______________', data);
      
      getCampaigns(adAccountId)
    } else {
      setRows([]);
    }
  }, [adAccountId]);




  async function getAdAccountsByBID() {
    try {
      const apiUrl = `${serverURL}/getAdAccountsByBID?omni_business_id=${bid}`;
      const response = await axios.get(apiUrl)
      setAllAdAccounts(response.data)
      response.data && handleAdAccountSelect(response.data[0])
      // setSelectedAdAccount(response.data[0])
    } catch (error) {
    }
  }

  async function getAds(account_id, filterStatus) {
    try {
      const apiUrl = `${serverURL}/getAds?adAccountID=${account_id}&filterStatus=${filterStatus}`;
      const response = await axios.get(apiUrl)
      setAllAds(response.data)
      setAllAdsFiltered(response.data)
    } catch (error) {
      console.log('ERROR getting ads on ads.js', error)
    }
  }

  /* api's for third tab */
  async function getAdsDataWithCampaignID(campaignId) {
    try {
      const response = await axios.get(`${serverURL}/getAdsDataWithCampaignId?campaign_ids=${JSON.stringify(campaignId)}`);
      console.log('Fetched Ad Sets Data:', response.data);
      return response.data;
    } catch (error) {
      console.error('ERROR loading campaigns data', error);
      return [];
    }
  }

  async function getAdsDataWithAdSetID(adSetId) {
    try {
      const response = await axios.get(`${serverURL}/getAdsDataWithAdSetId?adset_ids=${JSON.stringify(adSetId)}`);
      console.log('Fetched Ad Sets Data:', response.data);
      return response.data;
    } catch (error) {
      console.error('ERROR loading campaigns data', error);
      return [];
    }
  }

  async function getAdsData(adAccountId) {
    try {
      const response = await axios.get(`${serverURL}/getAdsData`, {
        params: {
          accountId: adAccountId,
        },
      });
      console.log('Fetched Ad Sets Data:', response.data);
      return response.data;
    } catch (error) {
      console.error('ERROR loading campaigns data', error);
      return [];
    }
  }

  // for campaign metrices 

  async function getCampaignMetrices() {
    try {
      const response = await axios.get(`${serverURL}/getCampaignMetrices`);
      console.log('Fetched getCampaignMetrices Data:', response.data);
      return response.data;
    } catch (error) {
      console.error('ERROR loading campaigns data', error);
      return [];
    }
  }


  async function getAdSetsDataWithCampaignID(campaignId) {
    try {
      const response = await axios.get(`${serverURL}/getAdSetsDataWithCampaignId?campaign_ids=${JSON.stringify(campaignId)}`);
      console.log('Fetched Ad Sets Data:', response.data);
      return response.data;
    } catch (error) {
      console.error('ERROR loading campaigns data', error);
      return [];
    }
  }

  useEffect(() => {
    getAdAccountsByBID()
  }, [])

  useEffect(() => {
    if (selectedAdAccount) {
      getAds(selectedAdAccount.account_id, filterStatus);
      //getAds("act_331027669725413", filterStatus)
    }
  }, [selectedAdAccount])

  function adjustSelection(adID) {
    let updatedAdsSelection;

    if (selectedCampaigns.includes(adID)) {
      updatedAdsSelection = selectedCampaigns.filter(item => item !== adID.toString());

    } else {
      updatedAdsSelection = [...selectedCampaigns, adID];
    }
    setSelectedCampaigns(updatedAdsSelection);
  }

  function formatStatus(status) {
    // Convert the status string to lowercase
    const lowerCaseStatus = status?.toLowerCase();
    // Capitalize the first letter
    return lowerCaseStatus?.charAt(0)?.toUpperCase() + lowerCaseStatus?.slice(1);
  }

  // Example usage
  const originalStatus = "ACTIVE";
  const formattedStatus = formatStatus(originalStatus);
  async function expandAd(id) {
    if (expandedIdList.includes(id)) {
      setExpandedIdList(expandedIdList.filter(item => item !== id));
    } else {
      setExpandedIdList([...expandedIdList, id]);
    }
  }

  const handleSelectedAdSetIdChange = (adset_id) => {
    setSelectedAdsetId(prevState => [...prevState, adset_id]);
  };

  const onRemoveAdSetIdHandler = (adset_id) => {
    setSelectedAdsetId((prevData) => prevData.filter((id) => id !== adset_id));
  }

  const handleAdSetsCheckboxChangeHandler = (adset_id) => (event) => {
    const isChecked = event.target.checked;

    // Update the state of the checkbox directly based on isChecked
    const updatedRows = adsRows.map((item) =>
      item.adset_id === adset_id ? { ...item, isSelect: isChecked } : item
    );

    // Update the state with the new rows data
    setAdsRows(updatedRows);

    // Notify the parent component about the change if needed
    if (isChecked) {
      handleSelectedAdSetIdChange(adset_id);
    } else {
      onRemoveAdSetIdHandler(adset_id);
    }

    console.log(`AdSet ID ${adset_id} selected:`, isChecked);
  };

  //opens drawer and sets the selected campaign
  const openDrawer = (row) => {
    setSelectedCampaign(row);
    setShowDrawer(true);
  }

  function formatDate(date) {
    const formattedDate = dayjs(date).format('MMM D, YYYY');
    return formattedDate;
}


  return (
    <>
      <CustomDrawer
        showDrawer={showDrawer}
        setShowDrawer={setShowDrawer}
        selectedCampaign={selectedCampaign}
        setSelectedCampaign={setSelectedCampaign}
        omniBusinessId={omniBusinessId}
        serverURL={serverURL}
        selectedAdLevel={selectedAdLevel}
      />
      <div>
        <div className='adsContentContainer'>
          <div className='ads_top_head'>
            {/* user name and campaign */}


            {/* <div className='adsTopRow'>
              <div className='AdsAccountName'>
                  <span style={{ fontSize: '26px' }}>{selectedAdAccount.name}</span>
                  <span className='AdsAccountCampaignsCount'>{allAds.length} Active Campaigns</span>
              </div>
            </div> */}


            <div className='adsContentFilterRow'>
              <div className='adsContentFilterRowRight'>

              <ClickAwayListener onClickAway={() => setShowAttributionsWindowDropdown(false)}>
                <div className='adsContentFilterRowDropdownButton' style={{width: 200}} onClick={() => { setShowAttributionsWindowDropdown(!showAttributionsWindowDropdown) }}>
                  <div className='adsContentFilterRowDropdownButtonText'>
                    <span>Window: {attributionsWindow}</span>
                  </div>
                  <KeyboardArrowDownRounded style={{paddingLeft: 10}}/>
                  {showAttributionsWindowDropdown && (
                    <div className='adsContentFilterRowDropdownContainer'>
                      <span className={attributionsWindow == 'lifetime' ? 'adsContentFilterRowDropdownRowSelected' : 'adsContentFilterRowDropdownRow'} onClick={() => { setShowAttributionsWindowDropdown(false); setAttributionsWindow('lifetime') }}>Lifetime</span>
                      <span className={attributionsWindow == '28days' ? 'adsContentFilterRowDropdownRowSelected' : 'adsContentFilterRowDropdownRow'} onClick={() => { setShowAttributionsWindowDropdown(false); setAttributionsWindow('28days') }}>28 Days</span>
                      <span className={attributionsWindow == '14days' ? 'adsContentFilterRowDropdownRowSelected' : 'adsContentFilterRowDropdownRow'} onClick={() => { setShowAttributionsWindowDropdown(false); setAttributionsWindow('14days') }}>14 Days</span>
                      <span className={attributionsWindow == '7days' ? 'adsContentFilterRowDropdownRowSelected' : 'adsContentFilterRowDropdownRow'} onClick={() => { setShowAttributionsWindowDropdown(false); setAttributionsWindow('7days') }}>7 Days</span>
                      <span className={attributionsWindow == '1day' ? 'adsContentFilterRowDropdownRowSelected' : 'adsContentFilterRowDropdownRow'} onClick={() => { setShowAttributionsWindowDropdown(false); setAttributionsWindow('1day') }}>1 Day</span>
                    </div>
                  )}
                </div>
              </ClickAwayListener>

              <ClickAwayListener onClickAway={() => setShowSelectColumnsDropdown(false)}>
                  <div className='adsContentFilterRowDropdownButton' style={{width: 200}} onClick={() => { setShowSelectColumnsDropdown(!showSelectColumnsDropdown) }}>
                    <div className='adsContentFilterRowDropdownButtonText'>
                      <span>Columns: {selectedColumns}</span>
                    </div>
                    <KeyboardArrowDownRounded style={{paddingLeft: 10}}/>
                    {showSelectColumnsDropdown && (
                      <div className='adsContentFilterRowDropdownContainer'>
                        <span className={selectedColumns == 'performance' ? 'adsContentFilterRowDropdownRowSelected' : 'adsContentFilterRowDropdownRow'} onClick={() => { setShowSelectColumnsDropdown(false); setSelectedColumns('performance') }}>Performance</span>
                        <span className={selectedColumns == 'delivery' ? 'adsContentFilterRowDropdownRowSelected' : 'adsContentFilterRowDropdownRow'} onClick={() => { setShowSelectColumnsDropdown(false); setSelectedColumns('delivery') }}>Delivery</span>
                      </div>
                    )}
                  </div>
                </ClickAwayListener>

                <ClickAwayListener onClickAway={() => setShowAdAccountDropdown(false)}>
                  <div className='adsContentFilterRowDropdownButton' style={{width: 200}} onClick={() => { setShowAdAccountDropdown(!showAdAccountDropdown) }}>
                    <div className='adsContentFilterRowDropdownButtonText'>
                      <img src='https://upload.wikimedia.org/wikipedia/commons/6/6c/Facebook_Logo_2023.png' style={{width: 25, paddingRight: 10}}/>
                      <span>{selectedAdAccount.name}</span>
                    </div>
                    <KeyboardArrowDownRounded style={{paddingLeft: 10}}/>
                    {showAdAccountDropdown && (
                      <div className='adsContentFilterRowDropdownContainer'>
                        {allAdAccounts.map((x) => {
                          return (
                            <div style={{display: 'flex', alignItems: 'center'}}>
                              <div className={x.account_id == selectedAdAccount.account_id ? 'adsContentFilterRowDropdownRowSelected' : 'adsContentFilterRowDropdownRow'} key={x.business_id} onClick={() => { setShowAdAccountDropdown(false); handleAdAccountSelect(x) }}>
                                <img src='https://upload.wikimedia.org/wikipedia/commons/6/6c/Facebook_Logo_2023.png' style={{width: 25, paddingRight: 10}}/>
                                {x.name}
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    )}
                  </div>
                </ClickAwayListener>

                {/* Facebook and Realtime data dropdown */}
                <ClickAwayListener onClickAway={() => setshowDataDropdown(false)}>
                  <div className='adsContentFilterRowDropdownButton'>
                    <div className='adsContentFilterRowDropdownButtonText' onClick={() => { setshowDataDropdown(!showDataDropdown) }}>
                      <span>Analytics: {analyticsMode}</span>
                    </div>
                    <KeyboardArrowDownRounded style={{paddingLeft: 10}} />
                    {showDataDropdown && (
                      <div className='adsContentFilterRowDropdownContainer'>
                        <span className={analyticsMode == 'facebook' ? 'adsContentFilterRowDropdownRowSelected' : 'adsContentFilterRowDropdownRow'} onClick={() => { setAnalyticsMode("facebook"); setshowDataDropdown(false) }}>Facebook</span>
                        <span className={analyticsMode == 'omni' ? 'adsContentFilterRowDropdownRowSelected' : 'adsContentFilterRowDropdownRow'} onClick={() => { setAnalyticsMode("omni"); setshowDataDropdown(false) }}>Omni Realtime</span>
                      </div>
                    )}
                  </div>
                </ClickAwayListener>

                <ClickAwayListener onClickAway={() => setShowStatusDropdown(false)}>
                  <div className='adsContentFilterRowDropdownButton'>
                    <div className='adsContentFilterRowDropdownButtonText' onClick={() => { setShowStatusDropdown(!showStatusDropdown) }}>
                      <span>Status: {filterStatus}</span>
                    </div>
                    <KeyboardArrowDownRounded style={{paddingLeft: 10}} />
                    {showStatusDropdown && (
                      <div className='adsContentFilterRowDropdownContainer'>
                        <span className={filterStatus == 'ANY' ? 'adsContentFilterRowDropdownRowSelected' : 'adsContentFilterRowDropdownRow'} onClick={() => { setFilterStatus("ANY"); setShowStatusDropdown(false) }}>Any</span>
                        <span className={filterStatus == 'ACTIVE' ? 'adsContentFilterRowDropdownRowSelected' : 'adsContentFilterRowDropdownRow'} onClick={() => { setFilterStatus("ACTIVE"); setShowStatusDropdown(false) }}>Active</span>
                        <span className={filterStatus == 'PAUSED' ? 'adsContentFilterRowDropdownRowSelected' : 'adsContentFilterRowDropdownRow'} onClick={() => { setFilterStatus("PAUSED"); setShowStatusDropdown(false) }}>Paused</span>
                        <span className={filterStatus == 'REVIEW' ? 'adsContentFilterRowDropdownRowSelected' : 'adsContentFilterRowDropdownRow'} onClick={() => { setFilterStatus("REVIEW"); setShowStatusDropdown(false) }}>In Review</span>
                      </div>
                    )}
                  </div>
                </ClickAwayListener>


                <ClickAwayListener onClickAway={() => setShowDateSelectors(false)}>
                  <div className='adsContentFilterRowDropdownButton' style={{width: 250}} onClick={() => { !showDateSelectors && setShowDateSelectors(true) }}>
                    <div className='adsContentFilterRowDropdownButtonText'>
                      <span>{formatDate(dateStart)} - {formatDate(dateEnd)}</span>
                    </div>
                    <KeyboardArrowDownRounded style={{paddingLeft: 10}}/>
                    {showDateSelectors && (
                      <div className='adsContentFilterRowDropdownContainer' style={{width: 'fit-content', left: -200, right: 0}}>
                        <DateSelector setShowDateSelectors={setShowDateSelectors} showDateSelectors={showDateSelectors} 
                          dateStart={dateStart} setDateStart={setDateStart} dateEnd={dateEnd} setDateEnd={setDateEnd}/>
                      </div>
                    )}
                  </div>
                </ClickAwayListener>



                <div className='adsTopRowRight'>
                  <div className='adsTopRowRightCreateButton' onClick={() => {navigate('/Create')}}>
                    <AddRounded />
                    <span className='adsTopRowRightCreateButtonText'>Create</span>
                  </div>
                </div>
                <div className='adsViewSelectionContainer'>
                  <div className={selectedView == 'grid' ? 'adsViewSelectionOptionSelected' : 'adsViewSelectionOption'} onClick={() => { setSelectedView('grid') }}><AppsRounded /></div>
                  <div className={selectedView == 'rows' ? 'adsViewSelectionOptionSelected' : 'adsViewSelectionOption'} onClick={() => { setSelectedView('rows') }}><TableRowsRounded /></div>
                  <div className={selectedView == 'table' ? 'adsViewSelectionOptionSelected' : 'adsViewSelectionOption'} onClick={() => { setSelectedView('table') }}><GridOnRounded /></div>
                </div>
              </div>
            </div>
          </div>
          {/* <Box sx={{ flexGrow: 1 }}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid div md={6} >
                <div className='multiDropCheck'>
                  {value === 0 && <MultipleSelectCheckmarks options={columnOptions} value={colOptionsCampaign} onChange={(e, val) => setColOptionsCampaign(val)} />}

                  {value === 1 && <MultipleSelectCheckmarks options={adSetCols} value={adSetsColOptions} onChange={(e, val) => setAdSetsColOptions(val)} />}

                  {value === 2 && <MultipleSelectCheckmarks options={adsCols} value={adsColOptions} onChange={(e, val) => setAdsColOptions(val)} />}

                </div>
              </Grid>
              <Grid div md={6}>
                
              </Grid>
            </Grid>
          </Box> */}
          {selectedCampaigns.length < 0 ?
            <div className='adsContentHeaderRowSelected'>
              <div className='adsContentHeaderRowSelectedLeft'>
                <div className='AdsSelectedAdCheckboxContainer' onClick={() => { setSelectedCampaigns([]) }}>
                  <div className='AdsSelectedAdCheckboxChecked'>
                    <CheckRounded style={{ width: 15 }} />
                  </div>
                </div>
                <span className='adsContentHeaderRowSelectedTextCount'>
                  {selectedCampaigns.length + " selected"}
                </span>
              </div>
              <div className='adsContentHeaderRowSelectedRight'>
                <div className='adsContentHeaderRowSelectedRightIcon'><StopRounded /></div>
                <div className='adsContentHeaderRowSelectedRightIcon'><PlayArrowRounded /></div>
                <div className='adsContentHeaderRowSelectedRightIcon'><ShareRounded /></div>
                <div className='adsContentHeaderRowSelectedRightIcon'><DeleteRounded /></div>
              </div>
            </div>
            :
            selectedView === 'rows' && (
              <React.Fragment>

                <div className='adsContentTableLevelSelectorContainer'>
                  <div className={selectedAdLevel == 0 ? 'adsContentTableLevelButtonSelected' : 'adsContentTableLevelButton'} onClick={() => {setSelectedAdLevel(0)}}>
                    Campaigns
                    {selectedCampaigns.length > 0 && selectedAdLevel == 0 && (
                      <div className='adsContentTableLevelButtonSelectedContainer' onClick={() => { setSelectedCampaigns([]) }} >
                        {selectedCampaigns.length} selected
                        <CloseRounded fontSize='small'/>
                      </div>
                    )}
                  </div>
                  <div className={selectedAdLevel == 1 ? 'adsContentTableLevelButtonSelected' : 'adsContentTableLevelButton'} onClick={() => {setSelectedAdLevel(1)}}>
                    Adsets
                  </div>
                  <div className={selectedAdLevel == 2 ? 'adsContentTableLevelButtonSelected' : 'adsContentTableLevelButton'} onClick={() => {setSelectedAdLevel(2)}}>
                    Ads
                  </div>
                  <div className='adsContentFilterRowSearchContainer'>
                    <SearchRounded />
                    <input className='adsContentFilterRowSearchInput' onChange={(e) => { setSearchText(e.target.value) }} value={searchText} placeholder='Search...' />
                  </div>
                </div>

                {selectedAdLevel == 0 && (
                  <CampaignGrid 
                    analyticDropdown={analyticsMode}
                    selectedCampaigns={selectedCampaigns}
                    adjustSelection={adjustSelection}
                    campaigns={rows} 
                    setShowDrawer={setShowDrawer}
                    setSelectedCampaign={setSelectedCampaign}
                    selectedCampaign={selectedCampaign}
                    selectedColumns={selectedColumns}
                    filterStatus={filterStatus}
                  />
                )}

                {selectedAdLevel == 1 && (
                  <AdSetsGrid
                    filterStatus={filterStatus}
                    items={adsRows}
                    visibleColumns={adSetsColOptions}
                    campaignId={selectedCampaigns}
                    handleAdSetsCheckboxChange={handleAdSetsCheckboxChangeHandler}
                    openDrawer={openDrawer}
                  />
                )}

                {selectedAdLevel == 2 && (
                  <AdsGrid
                    filterStatus={filterStatus}
                    items={underAds}
                    campaignId={selectedCampaigns}
                    visibleColumns={adsColOptions}
                    adSetId={selectedAdSetId}
                    openDrawer={openDrawer}
                  />
                )}

              </React.Fragment>
            )
          }



          {allAdsFiltered.map((x) => {
            return (
              <div>
                <div className='adsContentRow' style={{ boxShadow: selectedCampaigns.includes(x.campaign_id) ? 'rgba(145, 158, 171, 0.16) 0px 20px 40px -4px' : '' }}>
                  <div className='adsContentExpandRowArrowIcon' onClick={() => { expandAd(x.campaign_id) }} style={{ transform: expandedIdList.includes(x.campaign_id) ? 'rotate(180deg)' : '' }}><KeyboardArrowDownRounded /></div>
                  {selectedCampaigns.includes(x.campaign_id) ?
                    <div className='AdsSelectedAdCheckboxContainer' onClick={() => { adjustSelection(x.campaign_id) }}>
                      <div className='AdsSelectedAdCheckboxChecked'>
                        <CheckRounded style={{ width: 15 }} />
                      </div>
                    </div>
                    :
                    <div className='AdsSelectedAdCheckboxContainer' onClick={() => { adjustSelection(x.campaign_id) }}>
                      <div className='AdsSelectedAdCheckbox'>
                        <CheckRounded style={{ width: 15 }} />
                      </div>
                    </div>
                  }
                  {x.type == 'campaign' ? <FolderIcon /> : <CampaignIcon />}
                  {/* <img className='adsContentThumbnailImg' src={x.creative_thumbnail_url}/> */}
                  {/* <img className='adsContentPlatformImg' src='https://upload.wikimedia.org/wikipedia/commons/thumb/6/6c/Facebook_Logo_2023.png/1200px-Facebook_Logo_2023.png'/> */}
                  <span className='adsContentName' onClick={() => { setSelectedAdForDetails(x); setSelectedAdTypeForDetails(x.type); setShowAdDetailsPane(true) }}>{x.campaign_name}</span>
                  <div className='adsContentRowStatusContainer'><span className={x.campaign_status == 'ACTIVE' ? 'adsContentRowStatusActive' : 'adsContentRowStatusInactive'}>{formatStatus(x.campaign_status)}</span></div>
                  <div className='adsContentBudgetColumn'>
                    <span className='adsContentBudget'>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(x.campaign_daily_budget)}</span>
                    <span className='adsContentBudgetDescription'>Budget</span>
                  </div>
                  {/* <span className='adsContentName'>{x.ad_link}</span>
              <span className='adsContentName'>{x.creative_instagram_link}</span> */}
                </div>



                {expandedIdList.includes(x.campaign_id) && <>
                  {x?.adsets?.map((y) => {
                    return (
                      <div>
                        <div className='adsContentAdsetRow'>
                          <div className='adsContentExpandRowArrowIcon' onClick={() => { expandAd(y.adset_id) }} style={{ transform: expandedIdList.includes(y.adset_id) ? 'rotate(180deg)' : '' }}><KeyboardArrowDownRounded /></div>
                          {selectedCampaigns.includes(x.campaign_id) ?
                            <div className='AdsSelectedAdCheckboxContainer' onClick={() => { adjustSelection(x.campaign_id) }}>
                              <div className='AdsSelectedAdCheckboxChecked'>
                                <CheckRounded style={{ width: 15 }} />
                              </div>
                            </div>
                            :
                            <div className='AdsSelectedAdCheckboxContainer' onClick={() => { adjustSelection(x.campaign_id) }}>
                              <div className='AdsSelectedAdCheckbox'>
                                <CheckRounded style={{ width: 15 }} />
                              </div>
                            </div>
                          }
                          {x.type == 'adset' ? <FolderIcon /> : <CampaignIcon />}
                          <span className='adsContentName'>{y.adset_name}</span>
                          <div className='adsContentRowStatusContainer'><span className={y.adset_status == 'ACTIVE' ? 'adsContentRowStatusActive' : 'adsContentRowStatusInactive'}>{formatStatus(y.adset_status)}</span></div>
                          <div className='adsContentBudgetColumn'>
                            <span className='adsContentBudget'>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(y.adset_daily_budget)}</span>
                            <span className='adsContentBudgetDescription'>Budget</span>
                          </div>
                        </div>



                        {expandedIdList.includes(y.adset_id) && <>
                          {y?.ads.map((z) => {
                            return (
                              <div>
                                <div className='adsContentAdsRow'>
                                  <div className='adsContentExpandRowArrowIcon' onClick={() => { expandAd(z.ad_id) }} style={{ transform: expandedIdList.includes(z.ad_id) ? 'rotate(180deg)' : '' }}><KeyboardArrowDownRounded /></div>
                                  {selectedCampaigns.includes(x.campaign_id) ?
                                    <div className='AdsSelectedAdCheckboxContainer' onClick={() => { adjustSelection(x.campaign_id) }}>
                                      <div className='AdsSelectedAdCheckboxChecked'>
                                        <CheckRounded style={{ width: 15 }} />
                                      </div>
                                    </div>
                                    :
                                    <div className='AdsSelectedAdCheckboxContainer' onClick={() => { adjustSelection(x.campaign_id) }}>
                                      <div className='AdsSelectedAdCheckbox'>
                                        <CheckRounded style={{ width: 15 }} />
                                      </div>
                                    </div>
                                  }
                                  {z.type == 'ad' ? <VideoIcon /> : <CampaignIcon />}
                                  <span className='adsContentName'>{z.ad_name}</span>
                                  <div className='adsContentRowStatusContainer'><span className={z.ad_configured_status == 'ACTIVE' ? 'adsContentRowStatusActive' : 'adsContentRowStatusInactive'}>{formatStatus(z.ad_configured_status)}</span></div>
                                  <div className='adsContentOpenAdLinkContainer'><a className='adsContentOpenAdLink' href={z.ad_link} target='_blank'><OpenInNewRounded fontSize='medium' /></a></div>
                                </div>



                                {expandedIdList.includes(z.ad_id) && <>
                                  {z?.creatives.map((a) => {
                                    return (
                                      <div>
                                        <div className='adsContentCreativeRow'>
                                          {selectedCampaigns.includes(x.campaign_id) ?
                                            <div className='AdsSelectedAdCheckboxContainer' onClick={() => { adjustSelection(x.campaign_id) }}>
                                              <div className='AdsSelectedAdCheckboxChecked'>
                                                <CheckRounded style={{ width: 15 }} />
                                              </div>
                                            </div>
                                            :
                                            <div className='AdsSelectedAdCheckboxContainer' onClick={() => { adjustSelection(x.campaign_id) }}>
                                              <div className='AdsSelectedAdCheckbox'>
                                                <CheckRounded style={{ width: 15 }} />
                                              </div>
                                            </div>
                                          }
                                          <div>{a.creative_name}</div>
                                          <div>{a.creative_status}</div>
                                          <div>{a.creative_thumbnail_url?.slice(0, 50)}</div>
                                        </div>
                                      </div>
                                    )
                                  })}
                                </>}
                              </div>
                            )
                          })}
                        </>}
                      </div>
                    )
                  })}
                </>}
              </div>
            )
          })}


        </div>




        <div className={`adsDetailsRightPane ${showAdDetailsPane ? 'open' : ''}`}>
          <div className='adsDetailsRightPaneHeaderContainer'>
            <span className='adsDetailsRightPaneHeaderCollapseIcon' onClick={() => { setShowAdDetailsPane(false) }}>
              <ChevronRightRounded />
            </span>
            <span className='adsDetailsRightPaneHeaderTitle'>Details</span>
            <span className='adsDetailsRightPaneHeaderCollapseIcon' style={{ opacity: 0 }}>
              <ChevronRightRounded />
            </span>
          </div>
          <div className='adsDetailsRightPaneContent'>
            <div className='adsDetailsRightPaneTopSection'>
              <div className='adsDetailsRightPaneTopSectionImage'>{selectedAdForDetails.type == 'campaign' ? <FolderIcon /> : <CampaignIcon />}</div>
              <span className='adsDetailsRightPaneTopSectionName'>{selectedAdForDetails.campaign_name}</span>
              <span className='adsDetailsRightPaneTopSectionStatus'>{selectedAdForDetails.campaign_status}</span>
              <span className='adsDetailsRightPaneTopSectionBudget'>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(selectedAdForDetails.campaign_daily_budget)}</span>
            </div>
          </div>
        </div>




      </div>
    </>
  );
}

export default Ads;