import './CreateAdStep2.css';
import { getAuth, signOut } from "firebase/auth";
import react, { useState, useEffect } from 'react';
import { arrayUnion, collection, doc, getDoc, updateDoc } from 'firebase/firestore';
import {db} from '../../FirebaseInit';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import ContentPasteRoundedIcon from '@mui/icons-material/ContentPasteRounded';
import CloseIcon from '@mui/icons-material/Close';
import ProductSearchModal from '../ProductSearchModal'
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import fileUploadSVG from '../../assets/uploadFile.svg'
import { CircularProgress } from '@mui/material';
import Add from '@mui/icons-material/Add';
import { CheckBoxRounded, CheckRounded, EditRounded, KeyboardArrowDownRounded, Upload, UploadFile } from '@mui/icons-material';
import axios from 'axios';
import TextInput from '../TextInput';

function CreateAdMakeCreative({
  selectedProduct, setSelectedProduct, shopifyMedia, setShopifyMedia, omniCloudMedia, setOmniCloudMedia, 
  facebookMedia, setFacebookMedia, draftID, 
  fb_PrimaryTextOptions, fb_HeadlineTextOptions, fb_DescriptionTextOptions, 
  fb_PrimaryTextSelected, setfb_PrimaryTextSelected,
  fb_HeadlineTextSelected, setfb_HeadlineTextSelected,
  fb_DescriptionTextSelected, setfb_DescriptionTextSelected,
  fb_CTA, generateMoreTextOptions, generateMoreTextOptionsLoading, setGenerateMoreTextOptionsLoading,
  selectedMedia, setSelectedMedia }) {



  const [CTA, setCTA] = useState(fb_CTA)
  const [showMediaSelectionModal, setShowMediaSelectionModal] = useState(false)
  const [mediaSelectionSelectedTab, setMediaSelectionSelectedTab] = useState('fileManager')
  const [showPrimaryTextDropdown, setShowPrimaryTextDropdown] = useState(false)
  const [tempEditPrimaryText, setTempEditPrimaryText] = useState('')
  const [editPrimaryText1, setEditPrimaryText1] = useState(false)
  const [editPrimaryText2, setEditPrimaryText2] = useState(false)
  const [editPrimaryText3, setEditPrimaryText3] = useState(false)
  const [editPrimaryText4, setEditPrimaryText4] = useState(false)
  const [editPrimaryText5, setEditPrimaryText5] = useState(false)  
  
  const [showHeadlineTextDropdown, setShowHeadlineTextDropdown] = useState(false)
  const [tempEditHeadlineText, setTempEditHeadlineText] = useState('')
  const [editHeadlineText1, setEditHeadlineText1] = useState(false)
  const [editHeadlineText2, setEditHeadlineText2] = useState(false)
  const [editHeadlineText3, setEditHeadlineText3] = useState(false)
  const [editHeadlineText4, setEditHeadlineText4] = useState(false)
  const [editHeadlineText5, setEditHeadlineText5] = useState(false)

  const [showDescriptionTextDropdown, setShowDescriptionTextDropdown] = useState(false)
  const [tempEditDescriptionText, setTempEditDescriptionText] = useState('')
  const [editDescriptionText1, setEditDescriptionText1] = useState(false)
  const [editDescriptionText2, setEditDescriptionText2] = useState(false)
  const [editDescriptionText3, setEditDescriptionText3] = useState(false)
  const [editDescriptionText4, setEditDescriptionText4] = useState(false)
  const [editDescriptionText5, setEditDescriptionText5] = useState(false)

  useEffect(() => {
  
    setCTA(fb_CTA)
    

  }, [fb_PrimaryTextOptions])

  const uid = localStorage.getItem('uid');
  const bid = localStorage.getItem('bid');

  
  function adjustPrimaryTextSelectedArray(primaryText) {
    let updatedPrimaryTextSelection;
    console.log(fb_PrimaryTextSelected)
    console.log(primaryText)

    if (fb_PrimaryTextSelected.includes(primaryText)) {
      console.log('includes')
      updatedPrimaryTextSelection = fb_PrimaryTextSelected.filter(item => item !== primaryText.toString());
      
    } else { 
      if (fb_PrimaryTextSelected.length < 5) {
      console.log('does not include')
      updatedPrimaryTextSelection = [...fb_PrimaryTextSelected, primaryText];
      
      } else {
        updatedPrimaryTextSelection = fb_PrimaryTextSelected;
      }
    }
    console.log(updatedPrimaryTextSelection)
    setfb_PrimaryTextSelected(updatedPrimaryTextSelection);
  }


  function adjustHeadlineTextSelectedArray(headlineText) {
    let updatedHeadlineTextSelection;
    console.log(fb_HeadlineTextSelected)
    console.log(headlineText)

    if (fb_HeadlineTextSelected.includes(headlineText)) {
      console.log('includes')
      updatedHeadlineTextSelection = fb_HeadlineTextSelected.filter(item => item !== headlineText.toString());
      
    } else { 
      if (fb_HeadlineTextSelected.length < 5) {
      console.log('does not include')
      updatedHeadlineTextSelection = [...fb_HeadlineTextSelected, headlineText];
      
      } else {
        updatedHeadlineTextSelection = fb_HeadlineTextSelected;
      }
    }
    console.log(updatedHeadlineTextSelection)
    setfb_HeadlineTextSelected(updatedHeadlineTextSelection);
  }


  function adjustDescriptionTextSelectedArray(descriptionText) {
    let updatedDescriptionTextSelection;
    console.log(fb_DescriptionTextSelected)
    console.log(descriptionText)

    if (fb_DescriptionTextSelected.includes(descriptionText)) {
      console.log('includes')
      updatedDescriptionTextSelection = fb_DescriptionTextSelected.filter(item => item !== descriptionText.toString());
      
    } else { 
      if (fb_DescriptionTextSelected.length < 5) {
      console.log('does not include')
      updatedDescriptionTextSelection = [...fb_DescriptionTextSelected, descriptionText];
      
      } else {
        updatedDescriptionTextSelection = fb_DescriptionTextSelected;
      }
    }
    console.log(updatedDescriptionTextSelection)
    setfb_DescriptionTextSelected(updatedDescriptionTextSelection);
  }










  async function updateOptionsWithEditsPrimary(index, newText) {
    const updatedPrimaryTextOptions = [...fb_PrimaryTextOptions];
    updatedPrimaryTextOptions[index] = newText;
    setfb_PrimaryTextSelected(updatedPrimaryTextOptions)
  
    const draftRef = doc(db, 'drafts', draftID);
    await updateDoc(draftRef, {
      fb_PrimaryTextOptions: updatedPrimaryTextOptions
    });
  
    if (index == 0) setEditPrimaryText1(false);
    if (index == 1) setEditPrimaryText2(false);
    if (index == 2) setEditPrimaryText3(false);
    if (index == 3) setEditPrimaryText4(false);
    if (index == 4) setEditPrimaryText5(false);
  }


  async function updateOptionsWithEditsHeadline(index, newText) {
    const updatedHeadlineTextOptions = [...fb_HeadlineTextOptions];
    updatedHeadlineTextOptions[index] = newText;
    setfb_HeadlineTextSelected(updatedHeadlineTextOptions)
  
    const draftRef = doc(db, 'drafts', draftID);
    await updateDoc(draftRef, {
      fb_HeadlineTextOptions: updatedHeadlineTextOptions
    });
  
    if (index == 0) setEditHeadlineText1(false);
    if (index == 1) setEditHeadlineText2(false);
    if (index == 2) setEditHeadlineText3(false);
    if (index == 3) setEditHeadlineText4(false);
    if (index == 4) setEditHeadlineText5(false);
  }


  async function updateOptionsWithEditsDescription(index, newText) {
    const updatedDescriptionTextOptions = [...fb_DescriptionTextOptions];
    updatedDescriptionTextOptions[index] = newText;
    setfb_DescriptionTextSelected(updatedDescriptionTextOptions)
  
    const draftRef = doc(db, 'drafts', draftID);
    await updateDoc(draftRef, {
      fb_DescriptionTextOptions: updatedDescriptionTextOptions
    });
  
    if (index == 0) setEditDescriptionText1(false);
    if (index == 1) setEditDescriptionText2(false);
    if (index == 2) setEditDescriptionText3(false);
    if (index == 3) setEditDescriptionText4(false);
    if (index == 4) setEditDescriptionText5(false);
  }




function adjustSelectedMedia(src) {
    let updatedMediaSelection = [];
  
      if (selectedMedia.includes(src)) {
        updatedMediaSelection = selectedMedia.filter(item => item !== src);
      } else {
        updatedMediaSelection = [...selectedMedia, src];
      }
    
      setSelectedMedia(updatedMediaSelection);
  }
  



  
  
return (
  <div className='createAdStep2Container'>


    <div className='createAdStep2LeftContainer'>
      <span className='createAdStep2LeftHeader'>Make your Facebook Ad</span>
      <span className='createAdStep2LeftSubheader'>Edit your creative...</span>
      
    </div>
    
 
    <div className='createAdStep2RightContainer'>
      <div className='createAdStep2RightContentContainer'>




      {selectedMedia.length > 1 && (<span className='createAdStep2RightCreativeContentLabel'>Dynamic Creative Content</span>)}
      {selectedMedia.length == 1 && (<span className='createAdStep2RightCreativeContentLabel'>Creative Content</span>)}

        <div className='createAdStep2RightImagesRow'>
          {/* {draftID == '' && selectedProduct != '' ? 
            <>
              <div className='createAdStep2RightImagesLoadingContainer'>
                <CircularProgress size={20} className='createAdStep2RightImagesLoading'/>
              </div>
              <div className='createAdStep2RightImagesLoadingContainer'>
                <CircularProgress size={20} className='createAdStep2RightImagesLoading'/>
              </div>
              <div className='createAdStep2RightImagesLoadingContainer'>
                <CircularProgress size={20} className='createAdStep2RightImagesLoading'/>
              </div>
              <div className='createAdStep2RightImagesLoadingContainer'>
                <CircularProgress size={20} className='createAdStep2RightImagesLoading'/>
              </div>
              <div className='createAdStep2RightImagesLoadingContainer'>
                <CircularProgress size={20} className='createAdStep2RightImagesLoading'/>
              </div>
            </>
            :<></>
          } */}
          {selectedMedia.map((x, index) => { return ( 
            <>
              <div className='createAdStep2RightImageContainer'>
                <CloseIcon fontSize='small' className='createAdStep2RightImageDeleteIcon' onClick={() => {adjustSelectedMedia(x)}}/>
                <img className='createAdStep2RightImage' src={x} />
              </div>
            </>
          )})}
          {/* <div className='createAdStep2RightImagesUploadIconContainer'>
            <label htmlFor="fileInput">
              <Add size={20} className='createAdStep2RightImagesUploadIcon'/>
            </label>
          </div> */}
        </div> 








          <span className='createAdStep2SelectMediaButton' onClick={() => {setShowMediaSelectionModal(true);}}>Select Media</span>






{/* 
        <TextInput placeholder="Primary Text" type="text" value={fb_PrimaryTextSelected} onChange={setfb_PrimaryTextSelected} widthPercent={90} widthPixels={0} multiline={true} marginBottom={20}/>
        <TextInput placeholder="Headline" type="text" value={fb_HeadlineTextSelected} onChange={setfb_HeadlineTextSelected} widthPercent={90} widthPixels={0} multiline={false} marginBottom={20}/>
        <TextInput placeholder="Description" type="text" value={fb_DescriptionTextSelected} onChange={setfb_DescriptionTextSelected} widthPercent={90} widthPixels={0} multiline={true} marginBottom={20}/>
 */}

        <span className='createAdStep2RightDropdownSectionTitle'>Primary Text</span>
  
          <div className='createAdStep2RightDisplayOptions'>
          {fb_PrimaryTextSelected[0] && 
              <div className='createAdStep2RightDisplayRow'>
                <span className='createAdStep2RightDropdownRowIndex'>1</span>
                {editPrimaryText1 ? 
                  <TextInput placeholder="Primary Text 1" type="text" widthPercent={80} value={tempEditPrimaryText} onChange={setTempEditPrimaryText}/>
                :
                  fb_PrimaryTextSelected[0]}   
                {editPrimaryText1 ?                    
                  <span className='createAdStep2RightDropdownRowEditIcon' onClick={() => {updateOptionsWithEditsPrimary(0, tempEditPrimaryText)}}><CheckRounded/></span>
                :
                  <span className='createAdStep2RightDropdownRowEditIcon' onClick={() => {setEditPrimaryText1(true); setTempEditPrimaryText(fb_PrimaryTextSelected[0])}}><EditRounded/></span>
                }
                </div>
            }
            {fb_PrimaryTextSelected[1] && 
              <div className='createAdStep2RightDisplayRow'>
                <span className='createAdStep2RightDropdownRowIndex'>2</span>
                {editPrimaryText2 ? 
                  <TextInput placeholder="Primary Text 2" type="text" widthPercent={80} value={tempEditPrimaryText} onChange={setTempEditPrimaryText}/>
                :
                  fb_PrimaryTextSelected[1]}   
                {editPrimaryText2 ?                    
                  <span className='createAdStep2RightDropdownRowEditIcon' onClick={() => {updateOptionsWithEditsPrimary(1, tempEditPrimaryText)}}><CheckRounded/></span>
                :
                  <span className='createAdStep2RightDropdownRowEditIcon' onClick={() => {setEditPrimaryText2(true); setTempEditPrimaryText(fb_PrimaryTextSelected[1])}}><EditRounded/></span>
                }
                </div>
            }
            {fb_PrimaryTextSelected[2] && 
              <div className='createAdStep2RightDisplayRow'>
                <span className='createAdStep2RightDropdownRowIndex'>3</span>
                {editPrimaryText3 ? 
                  <TextInput placeholder="Primary Text 3" type="text" widthPercent={80} value={tempEditPrimaryText} onChange={setTempEditPrimaryText}/>
                :
                  fb_PrimaryTextSelected[2]}   
                {editPrimaryText3 ?                    
                  <span className='createAdStep2RightDropdownRowEditIcon' onClick={() => {updateOptionsWithEditsPrimary(2, tempEditPrimaryText)}}><CheckRounded/></span>
                :
                  <span className='createAdStep2RightDropdownRowEditIcon' onClick={() => {setEditPrimaryText3(true); setTempEditPrimaryText(fb_PrimaryTextSelected[2])}}><EditRounded/></span>
                }
                </div>
            }
            {fb_PrimaryTextSelected[3] && 
              <div className='createAdStep2RightDisplayRow'>
                <span className='createAdStep2RightDropdownRowIndex'>4</span>
                {editPrimaryText4 ? 
                  <TextInput placeholder="Primary Text 4" type="text" widthPercent={80} value={tempEditPrimaryText} onChange={setTempEditPrimaryText}/>
                :
                  fb_PrimaryTextSelected[3]}   
                {editPrimaryText4 ?                    
                  <span className='createAdStep2RightDropdownRowEditIcon' onClick={() => {updateOptionsWithEditsPrimary(3, tempEditPrimaryText)}}><CheckRounded/></span>
                :
                  <span className='createAdStep2RightDropdownRowEditIcon' onClick={() => {setEditPrimaryText4(true); setTempEditPrimaryText(fb_PrimaryTextSelected[3])}}><EditRounded/></span>
                }
                </div>
            }
            {fb_PrimaryTextSelected[4] && 
              <div className='createAdStep2RightDisplayRow'>
                <span className='createAdStep2RightDropdownRowIndex'>5</span>
                {editPrimaryText5 ? 
                  <TextInput placeholder="Primary Text 5" type="text" widthPercent={80} value={tempEditPrimaryText} onChange={setTempEditPrimaryText}/>
                :
                  fb_PrimaryTextSelected[4]}   
                {editPrimaryText5 ?                    
                  <span className='createAdStep2RightDropdownRowEditIcon' onClick={() => {updateOptionsWithEditsPrimary(4, tempEditPrimaryText)}}><CheckRounded/></span>
                :
                  <span className='createAdStep2RightDropdownRowEditIcon' onClick={() => {setEditPrimaryText5(true); setTempEditPrimaryText(fb_PrimaryTextSelected[4])}}><EditRounded/></span>
                }
                </div>
            }
            
            

          </div>

          <div className='createAdStep2RightDropdownButton'>
            <div className='createAdStep2RightDropdownButtonText' onClick={() => {setShowPrimaryTextDropdown(!showPrimaryTextDropdown)}}>
              <span>More Primary Text Options</span>
              <KeyboardArrowDownRounded/>
            </div>
            {showPrimaryTextDropdown && (
              <div className='createAdStep2RightDropdownContainer'>
                {fb_PrimaryTextOptions.map((option, optionIndex) => {
                  // Check if the current option is selected
                  const isSelected = fb_PrimaryTextSelected.includes(option);

                  // If selected, find its index in the fb_PrimaryTextSelected array
                  const selectedIndex = isSelected ? fb_PrimaryTextSelected.indexOf(option) + 1 : null;

                  // Render the index only if the item is selected
                  return (
                    <div className='createAdStep2RightDropdownRow' onClick={() => {adjustPrimaryTextSelectedArray(option)}}> 
                      {isSelected ?
                        <span className='createAdStep2RightDropdownRowIndex'>{selectedIndex}</span>
                      :
                        <span className='createAdStep2RightDropdownRowIndex' style={{opacity: 0}}>i</span>
                      }
                      <span className='createAdStep2RightDropdownRowText'>{option}</span>
                    </div>
                  );
                })}
                <span className='createAdStep2RightDropdownRowGenerateMore' onClick={() => {generateMoreTextOptions('primary')}}>{generateMoreTextOptionsLoading ? "Generating more..." : "Generate more"}</span>
              </div>
            )}
          </div>
       
      

















          <span className='createAdStep2RightDropdownSectionTitle'>Headlines</span>
          
          <div className='createAdStep2RightDisplayOptions'>
            {fb_HeadlineTextSelected[0] && 
              <div className='createAdStep2RightDisplayRow'>
                <span className='createAdStep2RightDropdownRowIndex'>1</span>
                {editHeadlineText1 ? 
                  <TextInput placeholder="Headline Text 1" type="text" widthPercent={80} value={tempEditHeadlineText} onChange={setTempEditHeadlineText}/>
                :
                fb_HeadlineTextSelected[0]}   
                {editHeadlineText1 ?                    
                  <span className='createAdStep2RightDropdownRowEditIcon' onClick={() => {updateOptionsWithEditsHeadline(0, tempEditHeadlineText)}}><CheckRounded/></span>
                :
                  <span className='createAdStep2RightDropdownRowEditIcon' onClick={() => {setEditHeadlineText1(true); setTempEditHeadlineText(fb_HeadlineTextSelected[0])}}><EditRounded/></span>
                }
                </div>
            }
            {fb_HeadlineTextSelected[1] && 
              <div className='createAdStep2RightDisplayRow'>
                <span className='createAdStep2RightDropdownRowIndex'>2</span>
                {editHeadlineText2 ? 
                  <TextInput placeholder="Headline Text 2" type="text" widthPercent={80} value={tempEditHeadlineText} onChange={setTempEditHeadlineText}/>
                :
                fb_HeadlineTextSelected[1]}   
                {editHeadlineText2 ?                    
                  <span className='createAdStep2RightDropdownRowEditIcon' onClick={() => {updateOptionsWithEditsHeadline(1, tempEditHeadlineText)}}><CheckRounded/></span>
                :
                  <span className='createAdStep2RightDropdownRowEditIcon' onClick={() => {setEditHeadlineText2(true); setTempEditHeadlineText(fb_HeadlineTextSelected[1])}}><EditRounded/></span>
                }
                </div>
            }
            {fb_HeadlineTextSelected[2] && 
              <div className='createAdStep2RightDisplayRow'>
                <span className='createAdStep2RightDropdownRowIndex'>3</span>
                {editHeadlineText3 ? 
                  <TextInput placeholder="Headline Text 3" type="text" widthPercent={80} value={tempEditHeadlineText} onChange={setTempEditHeadlineText}/>
                :
                fb_HeadlineTextSelected[2]}   
                {editHeadlineText3 ?                    
                  <span className='createAdStep2RightDropdownRowEditIcon' onClick={() => {updateOptionsWithEditsHeadline(2, tempEditHeadlineText)}}><CheckRounded/></span>
                :
                  <span className='createAdStep2RightDropdownRowEditIcon' onClick={() => {setEditHeadlineText3(true); setTempEditHeadlineText(fb_HeadlineTextSelected[2])}}><EditRounded/></span>
                }
                </div>
            }
            {fb_HeadlineTextSelected[3] && 
              <div className='createAdStep2RightDisplayRow'>
                <span className='createAdStep2RightDropdownRowIndex'>4</span>
                {editHeadlineText4 ? 
                  <TextInput placeholder="Headline Text 4" type="text" widthPercent={80} value={tempEditHeadlineText} onChange={setTempEditHeadlineText}/>
                :
                fb_HeadlineTextSelected[3]}   
                {editHeadlineText4 ?                    
                  <span className='createAdStep2RightDropdownRowEditIcon' onClick={() => {updateOptionsWithEditsHeadline(3, tempEditHeadlineText)}}><CheckRounded/></span>
                :
                  <span className='createAdStep2RightDropdownRowEditIcon' onClick={() => {setEditHeadlineText4(true); setTempEditHeadlineText(fb_HeadlineTextSelected[3])}}><EditRounded/></span>
                }
                </div>
            }
            {fb_HeadlineTextSelected[4] && 
              <div className='createAdStep2RightDisplayRow'>
                <span className='createAdStep2RightDropdownRowIndex'>5</span>
                {editHeadlineText5 ? 
                  <TextInput placeholder="Headline Text 5" type="text" widthPercent={80} value={tempEditHeadlineText} onChange={setTempEditHeadlineText}/>
                :
                fb_HeadlineTextSelected[4]}   
                {editHeadlineText5 ?                    
                  <span className='createAdStep2RightDropdownRowEditIcon' onClick={() => {updateOptionsWithEditsHeadline(4, tempEditHeadlineText)}}><CheckRounded/></span>
                :
                  <span className='createAdStep2RightDropdownRowEditIcon' onClick={() => {setEditHeadlineText5(true); setTempEditHeadlineText(fb_HeadlineTextSelected[4])}}><EditRounded/></span>
                }
                </div>
            }
            
            

          </div>

          <div className='createAdStep2RightDropdownButton'>
            <div className='createAdStep2RightDropdownButtonText' onClick={() => {setShowHeadlineTextDropdown(!showHeadlineTextDropdown)}}>
              <span>More Headline Options</span>
              <KeyboardArrowDownRounded/>
            </div>
            {showHeadlineTextDropdown && (
              <div className='createAdStep2RightDropdownContainer'>
                {fb_HeadlineTextOptions.map((option, optionIndex) => {
                  // Check if the current option is selected
                  const isSelected = fb_HeadlineTextSelected.includes(option);

                  // If selected, find its index in the fb_HeadlineTextSelected array
                  const selectedIndex = isSelected ? fb_HeadlineTextSelected.indexOf(option) + 1 : null;

                  // Render the index only if the item is selected
                  return (
                    <div className='createAdStep2RightDropdownRow' onClick={() => {adjustHeadlineTextSelectedArray(option)}}> 
                      {isSelected ?
                        <span className='createAdStep2RightDropdownRowIndex'>{selectedIndex}</span>
                      :
                        <span className='createAdStep2RightDropdownRowIndex' style={{opacity: 0}}>i</span>
                      }
                      <span className='createAdStep2RightDropdownRowText'>{option}</span>
                    </div>
                  );
                })}
                <span className='createAdStep2RightDropdownRowGenerateMore' onClick={() => {generateMoreTextOptions('headline')}}>{generateMoreTextOptionsLoading ? "Generating more..." : "Generate more"}</span>
              </div>
            )}
          </div>

























          <span className='createAdStep2RightDropdownSectionTitle'>Descriptions</span>
  
          <div className='createAdStep2RightDisplayOptions'>
          {fb_DescriptionTextSelected[0] && 
              <div className='createAdStep2RightDisplayRow'>
                <span className='createAdStep2RightDropdownRowIndex'>1</span>
                {editDescriptionText1 ? 
                  <TextInput placeholder="Description Text 1" type="text" widthPercent={80} value={tempEditDescriptionText} onChange={setTempEditDescriptionText}/>
                :
                  fb_DescriptionTextSelected[0]}   
                {editDescriptionText1 ?                    
                  <span className='createAdStep2RightDropdownRowEditIcon' onClick={() => {updateOptionsWithEditsDescription(0, tempEditDescriptionText)}}><CheckRounded/></span>
                :
                  <span className='createAdStep2RightDropdownRowEditIcon' onClick={() => {setEditDescriptionText1(true); setTempEditDescriptionText(fb_DescriptionTextSelected[0])}}><EditRounded/></span>
                }
                </div>
            }
            {fb_DescriptionTextSelected[1] && 
              <div className='createAdStep2RightDisplayRow'>
                <span className='createAdStep2RightDropdownRowIndex'>2</span>
                {editDescriptionText2 ? 
                  <TextInput placeholder="Description Text 2" type="text" widthPercent={80} value={tempEditDescriptionText} onChange={setTempEditDescriptionText}/>
                :
                  fb_DescriptionTextSelected[1]}   
                {editDescriptionText2 ?                    
                  <span className='createAdStep2RightDropdownRowEditIcon' onClick={() => {updateOptionsWithEditsDescription(1, tempEditDescriptionText)}}><CheckRounded/></span>
                :
                  <span className='createAdStep2RightDropdownRowEditIcon' onClick={() => {setEditDescriptionText2(true); setTempEditDescriptionText(fb_DescriptionTextSelected[1])}}><EditRounded/></span>
                }
                </div>
            }
            {fb_DescriptionTextSelected[2] && 
              <div className='createAdStep2RightDisplayRow'>
                <span className='createAdStep2RightDropdownRowIndex'>3</span>
                {editDescriptionText3 ? 
                  <TextInput placeholder="Description Text 3" type="text" widthPercent={80} value={tempEditDescriptionText} onChange={setTempEditDescriptionText}/>
                :
                  fb_DescriptionTextSelected[2]}   
                {editDescriptionText3 ?                    
                  <span className='createAdStep2RightDropdownRowEditIcon' onClick={() => {updateOptionsWithEditsDescription(2, tempEditDescriptionText)}}><CheckRounded/></span>
                :
                  <span className='createAdStep2RightDropdownRowEditIcon' onClick={() => {setEditDescriptionText3(true); setTempEditDescriptionText(fb_DescriptionTextSelected[2])}}><EditRounded/></span>
                }
                </div>
            }
            {fb_DescriptionTextSelected[3] && 
              <div className='createAdStep2RightDisplayRow'>
                <span className='createAdStep2RightDropdownRowIndex'>4</span>
                {editDescriptionText4 ? 
                  <TextInput placeholder="Description Text 4" type="text" widthPercent={80} value={tempEditDescriptionText} onChange={setTempEditDescriptionText}/>
                :
                  fb_DescriptionTextSelected[3]}   
                {editDescriptionText4 ?                    
                  <span className='createAdStep2RightDropdownRowEditIcon' onClick={() => {updateOptionsWithEditsDescription(3, tempEditDescriptionText)}}><CheckRounded/></span>
                :
                  <span className='createAdStep2RightDropdownRowEditIcon' onClick={() => {setEditDescriptionText4(true); setTempEditDescriptionText(fb_DescriptionTextSelected[3])}}><EditRounded/></span>
                }
                </div>
            }
            {fb_DescriptionTextSelected[4] && 
              <div className='createAdStep2RightDisplayRow'>
                <span className='createAdStep2RightDropdownRowIndex'>5</span>
                {editDescriptionText5 ? 
                  <TextInput placeholder="Description Text 5" type="text" widthPercent={80} value={tempEditDescriptionText} onChange={setTempEditDescriptionText}/>
                :
                  fb_DescriptionTextSelected[4]}   
                {editDescriptionText5 ?                    
                  <span className='createAdStep2RightDropdownRowEditIcon' onClick={() => {updateOptionsWithEditsDescription(4, tempEditDescriptionText)}}><CheckRounded/></span>
                :
                  <span className='createAdStep2RightDropdownRowEditIcon' onClick={() => {setEditDescriptionText5(true); setTempEditDescriptionText(fb_DescriptionTextSelected[4])}}><EditRounded/></span>
                }
                </div>
            }
            
            

          </div>

          <div className='createAdStep2RightDropdownButton'>
            <div className='createAdStep2RightDropdownButtonText' onClick={() => {setShowDescriptionTextDropdown(!showDescriptionTextDropdown)}}>
              <span>More Description Options</span>
              <KeyboardArrowDownRounded/>
            </div>
            {showDescriptionTextDropdown && (
              <div className='createAdStep2RightDropdownContainer'>
                {fb_DescriptionTextOptions.map((option, optionIndex) => {
                  // Check if the current option is selected
                  const isSelected = fb_DescriptionTextSelected.includes(option);

                  // If selected, find its index in the fb_DescriptionTextSelected array
                  const selectedIndex = isSelected ? fb_DescriptionTextSelected.indexOf(option) + 1 : null;

                  // Render the index only if the item is selected
                  return (
                    <div className='createAdStep2RightDropdownRow' onClick={() => {adjustDescriptionTextSelectedArray(option)}}> 
                      {isSelected ?
                        <span className='createAdStep2RightDropdownRowIndex'>{selectedIndex}</span>
                      :
                        <span className='createAdStep2RightDropdownRowIndex' style={{opacity: 0}}>i</span>
                      }
                      <span className='createAdStep2RightDropdownRowText'>{option}</span>
                    </div>
                  );
                })}
                <span className='createAdStep2RightDropdownRowGenerateMore' onClick={() => {generateMoreTextOptions('description')}}>{generateMoreTextOptionsLoading ? "Generating more..." : "Generate more"}</span>
              </div>
            )}
          </div>






{/* 
        <div className='createAdStep2RightCTAContainer'>
          <span className={CTA == 'SHOP_NOW' ? 'createAdStep2RightCTAButtonSelected' : 'createAdStep2RightCTAButton'} onClick={() => {setCTA('SHOP_NOW')}}>Shop Now</span>
          <span className={CTA == 'LEARN_MORE' ? 'createAdStep2RightCTAButtonSelected' : 'createAdStep2RightCTAButton'} onClick={() => {setCTA('LEARN_MORE')}}>Learn More</span>
          <span className={CTA == 'SIGN_UP' ? 'createAdStep2RightCTAButtonSelected' : 'createAdStep2RightCTAButton'} onClick={() => {setCTA('SIGN_UP')}}>Sign Up</span>
        </div>
 */}


      </div>

    </div>








    
    <div className={showMediaSelectionModal ? 'createAdStep2MediaSelectionModalBackground' : 'createAdStep2MediaSelectionModalBackgroundHidden'} onClick={() => {setShowMediaSelectionModal(false)}}>
        <div className='createAdStep2MediaSelectionModalCard' onClick={(e) => e.stopPropagation()}>
          <div className='createAdStep2MediaSelectionModalCloseIcon' onClick={() => {setShowMediaSelectionModal(false)}}><CloseIcon/></div>
          <span className='createAdStep2MediaSelectionModalSelectMediaFAB'>Select Media</span>
          <div className='createAdStep2MediaSelectionModalSource'>
            <div>
              <span className='createAdStep2MediaSelectionModalSourceTitle'>Sources</span>
              <span className={mediaSelectionSelectedTab == 'all' ? 'createAdStep2MediaSelectionModalSourceTabSelected' : 'createAdStep2MediaSelectionModalSourceTab'} onClick={() => {setMediaSelectionSelectedTab('all')}}>All</span>
              <span className={mediaSelectionSelectedTab == 'fileManager' ? 'createAdStep2MediaSelectionModalSourceTabSelected' : 'createAdStep2MediaSelectionModalSourceTab'} onClick={() => {setMediaSelectionSelectedTab('fileManager')}}>Omni Cloud</span>
              <span className={mediaSelectionSelectedTab == 'shopify' ? 'createAdStep2MediaSelectionModalSourceTabSelected' : 'createAdStep2MediaSelectionModalSourceTab'} onClick={() => {setMediaSelectionSelectedTab('shopify')}}>Shopify</span>
              <span className={mediaSelectionSelectedTab == 'facebook' ? 'createAdStep2MediaSelectionModalSourceTabSelected' : 'createAdStep2MediaSelectionModalSourceTab'} onClick={() => {setMediaSelectionSelectedTab('facebook')}}>Facebook</span>
              <span className={mediaSelectionSelectedTab == 'sora' ? 'createAdStep2MediaSelectionModalSourceTabSelected' : 'createAdStep2MediaSelectionModalSourceTab'} onClick={() => {setMediaSelectionSelectedTab('sora')}}>Sora (coming soon)</span>
            </div>
            <span className={mediaSelectionSelectedTab == 'upload' ? 'createAdStep2MediaSelectionModalSourceTabSelected' : 'createAdStep2MediaSelectionModalSourceTab'} onClick={() => {setMediaSelectionSelectedTab('upload')}}> Upload</span>
          </div>
          <div>


            {mediaSelectionSelectedTab == 'fileManager' &&
              <div className='createAdStep2MediaSelectionModalMediaContainer'>
                {omniCloudMedia.map((x) => { return (
                  <>
                    {x.type == 'image' ?
                      <div className={selectedMedia.includes(x.s3_link) ? 'createAdStep2MediaSelectionModalImageContainerSelected' : 'createAdStep2MediaSelectionModalImageContainer'} onClick={() => {adjustSelectedMedia(x.s3_link)}}>
                        <img className='createAdStep2MediaSelectionModalImage' src={x.s3_link}/>
                      </div>
                      :
                      <div className={selectedMedia.includes(x.s3_link) ? 'createAdStep2MediaSelectionModalImageContainerSelected' : 'createAdStep2MediaSelectionModalImageContainer'} onClick={() => {adjustSelectedMedia(x.s3_link)}}>
                        <video className='createAdStep2MediaSelectionModalImage' controls>
                          <source src={x.s3_link} type={x.file_type} />
                        </video>
                      </div>
                    }
                  </>
                )})}
              </div>
            }

            {mediaSelectionSelectedTab == 'shopify' &&
              <div className='createAdStep2MediaSelectionModalMediaContainer'>
                {shopifyMedia.map((x) => { return (
                  <div className={selectedMedia.includes(x.src) ? 'createAdStep2MediaSelectionModalImageContainerSelected' : 'createAdStep2MediaSelectionModalImageContainer'} onClick={() => {adjustSelectedMedia(x.src)}}>
                    <img className='createAdStep2MediaSelectionModalImage' src={x.src}/>
                  </div> 
                )})}
              </div>
            }

            {mediaSelectionSelectedTab == 'facebook' &&
              <div className='createAdStep2MediaSelectionModalMediaContainer'>
                {facebookMedia.map((x) => { return (
                  <>
                    {x.type == 'image' ?
                      <div className='createAdStep2MediaSelectionModalImageContainer' onClick={() => {adjustSelectedMedia(x.s3_link)}}>
                        <img className='createAdStep2MediaSelectionModalImage' src={x.s3_link}/>
                      </div>
                      :
                      <div className='createAdStep2MediaSelectionModalImageContainer' onClick={() => {adjustSelectedMedia(x.s3_link)}}>
                        <video className='createAdStep2MediaSelectionModalImage' controls>
                          <source src={x.s3_link} type={x.file_type} />
                        </video>
                      </div>
                    }
                  </>
                )})}
              </div>
            }

            {mediaSelectionSelectedTab == 'upload' &&
              <div className='createAdStep2MediaSelectionModalMediaContainer'>
                <label htmlFor="fileInput">
                  <img className='createAdStep2MediaSelectionModalUploadFileIcon' src={fileUploadSVG}/>
                </label>
              </div>
            }


          </div>
        </div>
    </div>







  </div>
 

  );
}

export default CreateAdMakeCreative;