import './Orders.css';
import { getAuth, signOut } from "firebase/auth";
import react, { useState, useEffect } from 'react';
import { collection, doc, getDoc } from 'firebase/firestore';
import {db} from '../FirebaseInit';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import NavbarAndDrawer from '../components/NavbarAndDrawer';
import StoreOverview from '../components/StoreOverview';
import { CircularProgress, Tooltip } from '@mui/material';
import { ArrowBackIosRounded, ArrowForwardIosRounded, KeyboardArrowLeftRounded, KeyboardArrowRightRounded } from '@mui/icons-material';


function Orders() {
  const navigate = useNavigate()

  const uid = localStorage.getItem('uid');
  const bid = localStorage.getItem('bid');

 
  const [allOrders, setAllOrders] = useState([])
  const [offset, setOffset] = useState(0)
  const [loadingOrders, setLoadingOrders] = useState(false)


  async function loadOrders () {
    try {
      setLoadingOrders(true)
      const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/getOrdersByBID?omni_business_id=${bid}&offset=${offset}` ;
      const response = await axios.get(apiUrl);
      const orders = response.data
      console.log(orders)
      setAllOrders(orders)
      setLoadingOrders(false)
    } catch (error) {
      setLoadingOrders(false)
      console.log('ERROR loading orders Orders.js', error)
    }
  }


  function formatDate(date) {
    const now = new Date();
    const isToday = now.toDateString() === date.toDateString();

    now.setDate(now.getDate() - 1);
    const isYesterday = now.toDateString() === date.toDateString();

    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    let hours = date.getHours();
    const minutes = date.getMinutes();
    let meridiem = 'AM';
    if (hours === 0) {
        hours = 12; // Convert 0 to 12 for 12 AM
    } else if (hours === 12) {
        meridiem = 'PM'; // Set meridiem to 'PM' for 12 PM
    } else if (hours > 12) {
        hours -= 12; // Convert 24-hour time to 12-hour time for PM
        meridiem = 'PM';
    }
    const paddedMinutes = minutes < 10 ? '0' + minutes : minutes;


    if (isToday) {
        return `Today`;
    } else if (isYesterday) {
        return `Yesterday`;
    } else {
        const dayOfWeek = daysOfWeek[date.getDay()];
        const month = months[date.getMonth()];
        const day = date.getDate();
        return `${dayOfWeek} ${month} ${day}`;
    }
}


  function formatTime (date) {
    let hours = date.getHours(); 
    const minutes = date.getMinutes();
    let meridiem = 'AM';
    if (hours === 0) {
      hours = 12; // Convert 0 to 12 for 12 AM
    } else if (hours === 12) {
      meridiem = 'PM'; // Set meridiem to 'PM' for 12 PM
    } else if (hours > 12) {
      hours -= 12; // Convert 24-hour time to 12-hour time for PM
      meridiem = 'PM';
    }
    const paddedMinutes = minutes < 10 ? '0' + minutes : minutes;
    return `${hours}:${paddedMinutes} ${meridiem}`
  }



  useEffect(() => {
    async function start () {
      console.log('running')
      if (uid !== '') {
        loadOrders()
      }
    }
      start()
    const intervalId = setInterval(start, 60000);
    return () => clearInterval(intervalId);
  }, [uid, offset]);

    




  
  
  
    return (
        <div className="ordersContainer">
            
          <StoreOverview long={true} widthPercent={80}/>





            <div className='ordersTableContainer'>
              <table className='ordersTable'>
                <thead>
                  <tr>
                    <td>Order</td>
                    <td>Date</td>
                    <td>Customer</td>
                    <td>Items</td>
                    <td><div className='ordersFinancialStatusColumn'>Payment Status</div></td>
                    <td>Total</td>
                    <td>Source</td>
                  </tr>
                </thead>
                <tbody>
                  {allOrders.map((x) => { return (
                    <tr>
                      <td><span className='ordersOrderNumber'>#{x.order_number}</span></td>
                      <td>
                        <span className='ordersRowTop'>{formatDate(new Date(x.created_at))}</span>
                        <span className='ordersRowBottom'>{formatTime(new Date(x.created_at))}</span>
                      </td>
                      <td>
                        <span className='ordersRowTop'>{x?.first_name} {x?.last_name}</span>
                        <span className='ordersRowBottom'>{x?.contact_email}</span>
                      </td>
                      <td>{x.line_items.length}</td>
                      <td>
                        <div className='ordersFinancialStatusColumn'>
                          {x.financial_status == 'paid' ? <span className='ordersRowPaid'>Paid</span> : null}
                          {x.financial_status == 'pending' ? <span className='ordersRowPending'>Pending</span> : null}
                          {x.financial_status == 'refunded' ? <span className='ordersRowRefunded'>Refunded</span> : null}
                        </div>
                      </td>
                      <td className='ordersRowPrice'>{(x.current_subtotal_price).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                      <td>
                        <Tooltip title={x.referring_site}>
                          {x.referring_site?.includes('facebook') ? <img src='https://cdn-icons-png.flaticon.com/512/145/145802.png' className='ordersRowReferrerIcon'/> : null}
                          {x.referring_site?.includes('google') ? <img src='https://cdn-icons-png.flaticon.com/512/145/145804.png' className='ordersRowReferrerIcon'/> : null}
                          {x.referring_site?.includes('instagram') ? <img src='https://cdn-icons-png.flaticon.com/512/145/145805.png' className='ordersRowReferrerIcon'/> : null}
                          {x.referring_site?.includes('twitter') ? <img src='https://cdn-icons-png.flaticon.com/512/145/145812.png' className='ordersRowReferrerIcon'/> : null}
                          {x.referring_site?.includes('pinterest') ? <img src='https://cdn-icons-png.flaticon.com/512/145/145810.png' className='ordersRowReferrerIcon'/> : null}
                          {x.referring_site?.includes('tiktok') ? <img src='https://cdn-icons-png.flaticon.com/512/145/145811.png' className='ordersRowReferrerIcon'/> : null}
                          {x.referring_site?.includes('linkedin') ? <img src='https://cdn-icons-png.flaticon.com/512/145/145809.png' className='ordersRowReferrerIcon'/> : null}
                          {x.referring_site?.includes('youtube') ? <img src='https://cdn-icons-png.flaticon.com/512/145/145808.png' className='ordersRowReferrerIcon'/> : null}
                          {x.referring_site?.includes('snapchat') ? <img src='https://images.rawpixel.com/image_png_800/czNmcy1wcml2YXRlL3Jhd3BpeGVsX2ltYWdlcy93ZWJzaXRlX2NvbnRlbnQvbHIvdjk4Mi1kMy0xNC5wbmc.png' className='ordersRowReferrerIcon'/> : null}
                          {x.referring_site?.includes('tumblr') ? <img src='https://cdn-icons-png.flaticon.com/512/145/145806.png' className='ordersRowReferrerIcon'/> : null}
                          {x.referring_site?.includes('email') ? <img src='https://cdn-icons-png.flaticon.com/512/145/145803.png' className='ordersRowReferrerIcon'/> : null}
                        </Tooltip>
                      </td>
                    </tr>
                  )})}
                    <tr> 
                    <td colSpan={3}>
                        <div className='ordersRowBottomLeftButton'>
                          {allOrders.length} Orders
                        </div>
                      </td>
                      <td></td>
                      <td colSpan={3}>
                        <div className='ordersPaginationArrowsContainer'>
                          {loadingOrders && (<CircularProgress size={20} style={{color: '#666', marginRight: 10}}/>)}
                          <div className={offset != 0 ? 'ordersPaginationArrow' : 'ordersPaginationArrowDisabled'} onClick={() => {offset >= 100 ? setOffset(offset - 100) : setOffset(0)}}>
                            <KeyboardArrowLeftRounded/>
                          </div>
                          <div className='ordersPaginationArrow' onClick={() => {setOffset(offset + 100)}}>
                            <KeyboardArrowRightRounded/>
                          </div>
                        </div>
                      </td>
                    </tr>
                </tbody>
              </table>
            </div>





          
        </div>
    );
}

export default Orders;